import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/Style.scss";
import Loader from "./Component/Loader/Index";
import DefaultLayout from "./Container/DefaultLayout";
import AuthLayout from "./Views/Authentication/AuthLayout";
import Imports from "./Views/Import/Imports";
import PropertiesList from "./Views/Properties/PropertiesList";
import SubscriptionPackage from "./Views/SetupAndConfiguration/SubscriptionPackage";
import { GetPageRight } from "./Service/CommonService";
import Unauthorized from "./Views/Authentication/Unauthorized";
import HotelDetails from "./Views/Properties/HotelDetails/HotelDetails";
const ComponentsPage = React.lazy(() => import("./Views/ComponentsPage"));
const UsersRole = React.lazy(
  () => import("./Views/SetupAndConfiguration/UserManagement/UsersRole")
);
const ClintList = React.lazy(() => import("./Views/ClintList/ClintList"));
const Subscription = React.lazy(() => import("./Views/Subscription/Subscription"));
const User = React.lazy(() => import("./Views/UserSetup/User"));
const UserRole = React.lazy(() => import("./Views/UserSetup/UserRole"));
const Accounts = React.lazy(() => import("./Views/Import/Accounts"));
const Activities = React.lazy(() => import("./Views/Import/Activities"));
const Contacts = React.lazy(() => import("./Views/Import/Contacts"));
const Suspects = React.lazy(() => import("./Views/Import/Suspects"));

const Dashboard = React.lazy(() => import("./Views/Dashboard/Dashboard"));
const ClintDetail = React.lazy(() => import("./Views/ClintList/ClientDetail"));
const Portfolio = React.lazy(() => import("./Views/Portfolio/Portfolios"));
const AdditionalSetting = React.lazy(() => import("./Views/AdditionalSettings/AdditionalSetting"));
const MarketSegment = React.lazy(() => import("./Views/AdditionalSettings/MarketSegment"));
const AccountRules = React.lazy(() => import("./Views/AdditionalSettings/AccountRules"));
const GeneralSettings = React.lazy(() => import("./Views/AdditionalSettings/GeneralSettings"));
const MailSetting = React.lazy(() => import("./Views/AdditionalSettings/MailSetting"));
const Search = React.lazy(() => import("./Views/Search/SearchList"));
const HelpDeskPage = React.lazy(() => import("./Views/HelpDesk/HelpDeskPage"));


function App() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const is_logged = dataSelector.is_logged;
  const AuthRoutes = () => {
    return (
      <>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<AuthLayout />}></Route>
        <Route path="/forgotPassword" element={<AuthLayout />} />
      </>
    );
  };


  const RenderComponent = ({ module, Component }) => {
    if (GetPageRight(module).is_view) {
      return <Component />
    } else {
      return <Unauthorized />
    }
  }

  const AllRoutes = () => {
    return (
      <Route path="/" element={<DefaultLayout />}>
        <Route path="*" element={<Navigate to="/" replace />} />
        {/* <Route path="" element={<Navigate to="/" replace />} />{" "} */}
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Dashboard'} Component={Dashboard} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/componentPage"
          element={
            <React.Suspense fallback={<Loader />}>
              <ComponentsPage />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/SetupAndConfigurations/userRole"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'User Role'} Component={UsersRole} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/client-list"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Client List'} Component={ClintList} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/subscription-list"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Subscription Package'} Component={Subscription} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/user-setup/user"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'User'} Component={User} />

            </React.Suspense>
          }
        ></Route>
        <Route
          path="/user-setup/user-role"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'User Role'} Component={UserRole} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/import/accounts"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Imports'} Component={Accounts} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/import/activities"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Imports'} Component={Activities} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/import/contacts"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Imports'} Component={Contacts} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/import/suspects"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Imports'} Component={Suspects} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/client-detail"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Client List'} Component={ClintDetail} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/portfolio"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Portfolio'} Component={Portfolio} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/properties-list"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Hotels'} Component={PropertiesList} />
            </React.Suspense>
          }
        ></Route>

        <Route
          // path="/hotelDetails/:property_id"
          path="/hotelDetails"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Hotels'} Component={HotelDetails} />
            </React.Suspense>
          }
        ></Route>

        <Route
          path="/additional-setting"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Additional Setting'} Component={AdditionalSetting} />

            </React.Suspense>
          }
        ></Route>
        <Route
          path="/subscription-package"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Subscription Package'} Component={SubscriptionPackage} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/market-segment"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Market Segment'} Component={MarketSegment} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/accountrules"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Account Rules'} Component={AccountRules} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/general-settings"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'General Setting'} Component={GeneralSettings} />

            </React.Suspense>
          }
        ></Route>
        <Route
          path="/mail-setting"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Mail Configuration'} Component={MailSetting} />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/help-desk"
          element={
            <React.Suspense fallback={<Loader />}>
              <HelpDeskPage />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/Imports"
          element={
            <React.Suspense fallback={<Loader />}>
              <RenderComponent module={'Imports'} Component={Imports} />

            </React.Suspense>
          }
        ></Route>
        <Route
          path="/search"
          element={
            <React.Suspense fallback={<Loader />}>
              <Search />
            </React.Suspense>
          }
        ></Route>
      </Route>
    );
  };
  return (
    <div>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>{is_logged ? AllRoutes() : AuthRoutes()}</Routes>

      </Router>
    </div>
  );
}

export default App;
