import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import GreenLabel from "../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../Component/Labels/RedLabel/Index";
import TaxViewModal from "./TaxViewModal";

export default function PropertyInformation({ hotelDetails }) {

  let [TaxRoomTypeRowNo, setTaxRoomTypeRowNo]: any = React.useState(1);
  let [RoomNamePass, setRoomNamePass]: any = React.useState("");
  const [StoreEditRoomTypeTaxdata, setStoreEditRoomTypeTaxdata] = useState([]);
  const [modalShowRoomTypeTaxInformation, setmodalShowRoomTypeTaxInformation] =
    React.useState(false);

  let MeetingRoom: any = () => {
    if (hotelDetails.roomMeeting) {
      let tableData = {};
      let cols: any = {};
      hotelDetails.roomMeeting.forEach((room_mt) => {
        room_mt.forEach((rm_cols: any) => {
          cols = {
            ...cols,
            [rm_cols.columnNo]: {
              roomSetupTitle: rm_cols.roomSetupTitle ? rm_cols.roomSetupTitle
                : rm_cols.columnNo in cols
                  ? cols[rm_cols.columnNo].roomSetupTitle
                    ? cols[rm_cols.columnNo].roomSetupTitle
                    : "-"
                  : "-",
            },
          };
        });
        cols = Object.keys(cols)
          .sort()
          .reduce((obj, key) => {
            obj[key] = cols[key];
            return obj;
          }, {});
        let colsValues = {};
        Object.keys(cols).map((cl_key) => {
          let obj_for_value = room_mt.find((obj) => obj.columnNo == cl_key);
          colsValues = {
            ...colsValues,
            [cl_key]: {
              ...cols[cl_key],
              value:
                typeof obj_for_value !== "undefined" && obj_for_value
                  ? obj_for_value.roomSetup
                  : "-",
            },
          };
        });
        tableData = {
          ...tableData,
          [room_mt[0].rowNo]: {
            meeting_rm: room_mt[0].roomName,
            associated_rooms: 'parentmeetingroom' in room_mt[0] ? room_mt[0].parentmeetingroom ? room_mt[0].parentmeetingroom : "-" : "-",
            square_feet: room_mt[0].squareFeet,
            tax_details: room_mt[0].tax_details,
            col_data: {
              ...colsValues,
            },
          },
        };
      });

      return (
        <>
          <thead>
            <tr className="h-40">
              <th className="settitle text-start">Meeting Rooms</th>
              <th className="settitle text-start">Associated Rooms</th>
              <th className="settitle text-center">Square Feet</th>
              {/* <th className="settitle text-center">Tax</th> */}
              {Object.keys(cols).map((col_key: any) => {
                return (
                  <>
                    <th className="settitle text-center">
                      {cols[col_key].roomSetupTitle ? cols[col_key].roomSetupTitle : ""}
                    </th>
                  </>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(tableData).length ?
                Object.keys(tableData).map((table_key, index) => {

                  return (
                    <tr key={index} className="h-40">
                      <td className="w-25">
                        {tableData[table_key].meeting_rm ? tableData[table_key].meeting_rm : "-"}
                      </td>
                      <td className="">
                        {tableData[table_key].associated_rooms
                          ? tableData[table_key].associated_rooms
                          : "-"}
                      </td>
                      <td className="text-center">
                        {tableData[table_key].square_feet ? tableData[table_key].square_feet : "-"}
                      </td>
                      {/* <td className="settitle text-center">
                    <div role="button"
                      tabIndex={-1}
                      onClick={() => OpenRoomMeetingTax(index, tableData[table_key].meeting_rm, hotelDetails.roomMeetingTaxes ? hotelDetails.roomMeetingTaxes : [])}
                      onKeyDown={() => OpenRoomMeetingTax(index, tableData[table_key].meeting_rm, hotelDetails.roomMeetingTaxes ? hotelDetails.roomMeetingTaxes : [])}
                    >
                      Tax Details
                    </div>
                  </td> */}
                      {Object.keys(tableData[table_key].col_data).map((col_key) => {
                        let col_sep_data = tableData[table_key].col_data[col_key];
                        return (<>
                          <td className="text-center">
                            {col_sep_data.value ? col_sep_data.value : "-"}
                          </td>
                        </>);

                      })}
                    </tr>
                  );
                })
                : <tr>
                  <td className="text-center" colSpan={6}>
                    No data found
                  </td>
                </tr>
            }
          </tbody>
        </>
      );
      // for (let hotelDetails.roomMeeting = 0; index < array.length; index++) {
      //     const element = hotelDetails.roomMeeting[index];
      // }
      // ElementRoomMeetingTable =
    }
    else {
      // ReactfindDomNode = React.findDOMNode(MeetingRoom);
      // document.getElementById('werwer');
      return (
        <>
          <thead>
            <tr className="h-40">
              <th colSpan={2} className="bluetext w-25">
                Meeting Rooms
              </th>
              <th colSpan={2} className="settitle text-center">
                Associated Rooms
              </th>
              <th colSpan={2} className="settitle text-center">
                Square Feet
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center" colSpan={6}>
                No data found
              </td>
            </tr>
          </tbody>
        </>
      );
    }


  };


  function OpenRoomTypeTax(rowId, Name: any, data: any) {


    let NewData: any = [];
    NewData.push(data && data.filter((a) => a.rowNo === rowId));
    setTaxRoomTypeRowNo(rowId);
    setRoomNamePass(Name);
    setStoreEditRoomTypeTaxdata(NewData[0]);
    setmodalShowRoomTypeTaxInformation(true);


  }

  function OpenRoomMeetingTax(rowId, Name: any, data: any) {


    let NewData: any = [];
    NewData.push(data && data.filter((a) => a.rowNo === rowId + 1));
    setTaxRoomTypeRowNo(rowId);
    setRoomNamePass(Name);
    setStoreEditRoomTypeTaxdata(NewData[0]);
    setmodalShowRoomTypeTaxInformation(true);


  }
  return (
    <>
      <Row>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">

              {hotelDetails.properties !== undefined &&
                hotelDetails.properties[0].PropertyStatus_Term === "Inactive" ? (
                <RedLabel
                  dangerLabel={hotelDetails.properties[0] &&
                    hotelDetails.properties[0].PropertyStatus_Term}
                />
              ) : (
                <GreenLabel
                  successLabel={hotelDetails.properties[0] &&
                    hotelDetails.properties[0].PropertyStatus_Term}
                />
              )}

              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Chain</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].BrandName
                        ? hotelDetails.properties[0].BrandName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={8} className="mb-3">
                  <span className="box-label">Property Code</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].PropertyCode
                        ? hotelDetails.properties[0].PropertyCode
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label"> Hotel Code</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelCode
                        ? hotelDetails.properties[0].HotelCode
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Portfolio</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].Portfolio
                        ? hotelDetails.properties[0].Portfolio
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Total Rooms</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].TotalRooms
                        ? hotelDetails.properties[0].TotalRooms
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Sales Level</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SalesLevel
                        ? hotelDetails.properties[0].SalesLevel
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Street</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].PropertyAddress
                        ? hotelDetails.properties[0].PropertyAddress
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">City</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].City
                        ? hotelDetails.properties[0].City
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">State</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].State
                        ? hotelDetails.properties[0].State
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Country</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].Country
                        ? hotelDetails.properties[0].Country
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Hotel Phone </span>
                  <p className="box-detials">
                    {" "}
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelPhone
                        ? hotelDetails.properties[0].HotelPhone
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelExt
                        ? hotelDetails.properties[0].HotelExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Zip Code</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].ZipCode
                        ? hotelDetails.properties[0].ZipCode
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Brand Portal Access Url</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].BrandPortalAccessUrl
                        ? hotelDetails.properties[0].BrandPortalAccessUrl
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Brand Portal Access UserName</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].BrandPortalAccessUserName
                        ? hotelDetails.properties[0].BrandPortalAccessUserName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Brand Portal Access Password</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].BrandPortalAccessPassword
                        ? hotelDetails.properties[0].BrandPortalAccessPassword
                        : "-"
                      : "-"}
                  </p>
                </Col>
                {/* <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Login Website</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelLoginUrl
                        ? hotelDetails.properties[0].HotelLoginUrl
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Login Website UserName</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelLoginUserName
                        ? hotelDetails.properties[0].HotelLoginUserName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Login Website Password</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].HotelLoginPassword
                        ? hotelDetails.properties[0].HotelLoginPassword
                        : "-"
                      : "-"}
                  </p>
                </Col> */}
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">DUNS Number</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].DUNSNumber
                        ? hotelDetails.properties[0].DUNSNumber
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">FEMA Number</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].FEMANumber
                        ? hotelDetails.properties[0].FEMANumber
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Target LNR & RFP Rate</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].TARGETLNRRFPRATE
                        ? hotelDetails.properties[0].TARGETLNRRFPRATE
                        : "-"
                      : "-"}
                  </p>
                </Col>
                {/* <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">CVENT UserName</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CVENTUserName
                        ? hotelDetails.properties[0].CVENTUserName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">CVENT Password</span>
                  <p className="box-detials">
                    {" "}
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CVENTPassword
                        ? hotelDetails.properties[0].CVENTPassword
                        : "-"
                      : "-"}
                  </p>
                </Col> */}
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Target BAR Weekday Rate</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].TargetBARWeekdayRate
                        ? hotelDetails.properties[0].TargetBARWeekdayRate
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Owner Name</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].OwnerName
                        ? hotelDetails.properties[0].OwnerName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Owner Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].PrimaryContactNo
                        ? hotelDetails.properties[0].PrimaryContactNo
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].OwnerExt
                        ? hotelDetails.properties[0].OwnerExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Owner Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].PrimaryEmailID
                        ? hotelDetails.properties[0].PrimaryEmailID
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">GM Name</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].GmName
                        ? hotelDetails.properties[0].GmName
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">GM Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SecondaryContactNo
                        ? hotelDetails.properties[0].SecondaryContactNo
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].GmExt
                        ? hotelDetails.properties[0].GmExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">GM Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SecondaryEmailID
                        ? hotelDetails.properties[0].SecondaryEmailID
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Director of Sales</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].DirectorOfSales
                        ? hotelDetails.properties[0].DirectorOfSales
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Director Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].DirectorContact
                        ? hotelDetails.properties[0].DirectorContact
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].DirectorExt
                        ? hotelDetails.properties[0].DirectorExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Director Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].DirectorEmail
                        ? hotelDetails.properties[0].DirectorEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Catering Manger</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CateringManger
                        ? hotelDetails.properties[0].CateringManger
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Catering Manager Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CateringMangerContact
                        ? hotelDetails.properties[0].CateringMangerContact
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CateringMangerExt
                        ? hotelDetails.properties[0].CateringMangerExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Catering Manager Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].CateringMangerEmail
                        ? hotelDetails.properties[0].CateringMangerEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Sales Manager</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SalesaManager
                        ? hotelDetails.properties[0].SalesaManager
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Sales Manager Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SalesaManagerContact
                        ? hotelDetails.properties[0].SalesaManagerContact
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SalesaManagerExt
                        ? hotelDetails.properties[0].SalesaManagerExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Sales Manager Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].SalesaManagerEmail
                        ? hotelDetails.properties[0].SalesaManagerEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Revenue Manger</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RevenueManager
                        ? hotelDetails.properties[0].RevenueManager
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Revenue Manger Contact</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RevenueManagerContact
                        ? hotelDetails.properties[0].RevenueManagerContact
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RevenueManagerExt
                        ? hotelDetails.properties[0].RevenueManagerExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Revenue Manager Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RevenueManagerEmail
                        ? hotelDetails.properties[0].RevenueManagerEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Operations</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalOperations
                        ? hotelDetails.properties[0].RegionalOperations
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Operations Phone</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalOperationsPhone
                        ? hotelDetails.properties[0].RegionalOperationsPhone
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegOpeExt
                        ? hotelDetails.properties[0].RegOpeExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Operations Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalOperationsEmail
                        ? hotelDetails.properties[0].RegionalOperationsEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Sales</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalSales
                        ? hotelDetails.properties[0].RegionalSales
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Sales Phone</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalSalesPhone
                        ? hotelDetails.properties[0].RegionalSalesPhone
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Ext</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegSlsExt
                        ? hotelDetails.properties[0].RegSlsExt
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Regional Sales Email</span>
                  <p className="box-detials ">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].RegionalSalesEmail
                        ? hotelDetails.properties[0].RegionalSalesEmail
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6} className="mb-3">
          <div className="information-division">
            <div className="tab-inner-box position-relative">
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Report Email to</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].ReportEmailTo
                        ? hotelDetails.properties[0].ReportEmailTo
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0">Meeting Rooms</h3>
            <div className="tab-inner-box position-relative">
              {/* <Row>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Service Charge(%)</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].ServiceCharge
                        ? hotelDetails.properties[0].ServiceCharge
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Tax Rate(%)</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].TaxRate
                        ? hotelDetails.properties[0].TaxRate
                        : "-"
                      : "-"}
                  </p>
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-3">
                  <span className="box-label">Gratuity(%)</span>
                  <p className="box-detials">
                    {hotelDetails.properties
                      ? hotelDetails.properties[0].Gratuity
                        ? hotelDetails.properties[0].Gratuity
                        : "-"
                      : "-"}
                  </p>
                </Col>
              </Row> */}
              <div className="customtablemetting">
                <Table responsive bordered className="table-without-space mb-0">
                  <MeetingRoom />
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0">Room Types</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">
                <Table responsive bordered className="table-without-space mb-0">
                  <thead>
                    <tr className="h-40">
                      <th className="settitle text-start" >Room Type</th>
                      <th className="settitle text-center">Quantity</th>
                      {/* <th className="settitle text-center">Tax</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.roomTypes.length ? (
                      hotelDetails.roomTypes.map((rm_type, i) => (
                        <tr key={i}>
                          <td>{rm_type.roomType ? rm_type.roomType : "-"}</td>
                          <td className="text-center bg-white">
                            {rm_type.quantity ? rm_type.quantity : "-"}
                          </td>
                          {/* <td className="bluetext bg-white">
                            <div
                              role="button"
                              tabIndex={-1}
                              id={"lstRoomMeetingDetailViewTaxes_" + i}
                              onClick={() => OpenRoomTypeTax(i, rm_type.roomType, hotelDetails.roomTypeTaxes ? hotelDetails.roomTypeTaxes : [])}
                              onKeyDown={() => OpenRoomTypeTax(i, rm_type.roomType, hotelDetails.roomTypeTaxes ? hotelDetails.roomTypeTaxes : [])}
                            >
                              Tax Details
                            </div>
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={2}>
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {modalShowRoomTypeTaxInformation && (
        <TaxViewModal
          TaxRoomTypeRowNo={TaxRoomTypeRowNo}
          RoomNamePass={RoomNamePass}
          StoreEditRoomTypeTaxdata={StoreEditRoomTypeTaxdata}
          show={modalShowRoomTypeTaxInformation}
          onHide={() => {
            setmodalShowRoomTypeTaxInformation(false);
          }}
        />
      )}
    </>
  );
}
