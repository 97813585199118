import { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import TabsView from "../../../Component/Tabs/Index";

import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ByDefaultHotaleIcon from "../../../assets/Images/Icons/ByDefaultHotaleIcon.svg";
import PageLoader from "../../../Component/Loader/PageLoader";
// import NoDataFoundImage from "../../../Component/NoData/NoDataFoundImage";

// import { EditPropertiesByID } from "../../../Services/Setup&Config/HotelService";
import FoodAndAv from "./FoodAndAv";
import MergeDocument from "./MergeDocument";
import PropertyInformation from "./PropertyInformation";
import SalesChecklist from "./SalesChecklist";
import ThirdPartyWebsite from "./ThirdPartyWebsite";
// import AddHotelsModal from "../../../Model/AddHotelsModal";
import {
  EditPropertiesWithAllDetailByID,
  PropertiesPaymentInfoList,
} from "../../../Service/PropertieService";
import AddProperties from "../../Model/AddProperties";
import TaxInformationTab from "./TaxInformationTab";
import CompetitorHotelTab from "./CompetitorHotelTab";
import { CommonService, GetPageRight } from "../../../Service/CommonService";

function HotelDetails() {
  // const { property_id } = useParams<any>();
  let location: any = useLocation();
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [modalShowproperty, setModalShowproperty] = useState(false);
  const [EditPropertiesID, setEditPropertiesID] = useState("");
  const [EditClientID, setEditClientID] = useState("");
  const [PaymentInfo, setPaymentInfo] = useState([]);
  const [key, setKey] = useState("Hotel Information");
  const [modalShow, setModalShow] = useState(false);
  const [hotelDetails, setHotelDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchHotelDetailsByPropertyId = async () => {
    let data = {
      propertyID: location.state.property_id,
      clientID: location.state.client_id,
    };

    let res: any = await EditPropertiesWithAllDetailByID(data, Token);

    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            //console.log('detls' ,res.data.data)
            setHotelDetails(res.data.data);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.property_id) {
      fetchHotelDetailsByPropertyId();
    }
  }, []);

  // const tabList = [
  //   {
  //     key: "Property Information",
  //     // tabTitle: "Property Information",
  //     tabTitle: "Information",
  //     tabContent: <PropertyInformation hotelDetails={hotelDetails} />,
  //   },
  //   {
  //     key: "Merge Document",
  //     tabTitle: "Merge Document",
  //     tabContent: <MergeDocument property_Data={location.state && location.state} />,
  //   },
  //   {
  //     key: "Sales Checklist",
  //     tabTitle: "Sales Checklist",
  //     tabContent: <SalesChecklist property_Data={location.state && location.state} />,
  //   },
  //   {
  //     key: "Third Party Website Login Details",
  //     tabTitle: "Third Party Website Login Details",
  //     tabContent: <ThirdPartyWebsite property_Data={location.state && location.state} />,
  //   },
  //   {
  //     key: "Food/Beverages and AV Equipment Setup",
  //     // tabTitle: "Food/Beverages and AV Equipment Setup",
  //     tabTitle: "Food/Beverages and AV Equipment",
  //     tabContent: (
  //       <>
  //         <FoodAndAv property_Data={location.state && location.state} />
  //         {/* <FoodAndAv property_id={location.state && location.state.property_id} /> */}
  //       </>
  //     ),
  //   },
  //   {
  //     key: "TAX Information",
  //     tabTitle: "Tax/Fees",
  //     tabContent: (
  //       <>
  //         <TaxInformationTab
  //           hotelDetails={hotelDetails}
  //           property_id={location.state && location.state.property_id}
  //         />
  //       </>
  //     ),
  //   },
  // ];

  async function EditProperties() {
    if (
      location.state.property_id &&
      location.state.property_id !== null &&
      location.state.property_id !== undefined
    ) {
      setEditPropertiesID(location.state.property_id);
      setEditClientID(location.state.client_id);
    }
    setModalShowproperty(true);
  }

  async function getPaymentInfoListDropdown() {
    let input = {
      clientID: location.state.client_id,
      paymentInfoID: null,
    };
    const res: any = await PropertiesPaymentInfoList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          if (
            res.data.data.clientPaymentInfo !== null &&
            res.data.data.clientPaymentInfo !== undefined
          ) {
            setPaymentInfo(res.data.data.clientPaymentInfo);
          }
        }
      }
    }
  }

  const [showModel, setShowModel] = useState(false);
  let pageRights = GetPageRight('Hotels')

  return (
    <>
      {/* <div className="btn-icon-grp adjust-height"> */}
      <div className="tabelData endFlexCls" style={{ textAlign: "right" }}>
        {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove</Tooltip>}>
          <Button className="btn-icon button btn actdelatebtn"></Button>
        </OverlayTrigger> */}

        <div className="align-right-Editbtn pt-2">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit</Tooltip>}>
            <Button
              className="btn-icon button btn btn-primary editIcon acteditbtn"
              onClick={() => EditProperties()}
            ></Button>
          </OverlayTrigger>

          {key === "Competitor Hotel Tab" && (
            // <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Competitor</Tooltip>}>
            //   <Button
            //     // className="btn-icon addFormIcon"
            //     className="btn-icon button btn btn-primary addFormIcon"
            //     onClick={() => {
            //       if (pageRights.is_create) {
            //         setShowModel(true);
            //       } else {
            //         CommonService.unauthorizedAction();
            //       }
            //     }}
            //   ></Button>
            // </OverlayTrigger>

            <div className="client">
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Competitor</Tooltip>}>
                <button
                  className="modalbutn"
                  onClick={() => {
                    if (pageRights.is_create) {
                      setShowModel(true);
                    } else {
                      CommonService.unauthorizedAction();
                    }
                  }}
                // readOnly={true}
                // disabled={ClientID === "null" && ClientLabel === "Select Client" || !ClientID || !ClientLabel && true}
                >
                  <i className="fa-regular fa-circle-plus"></i>
                  {/* <span>Hotel</span> */}
                </button>
              </OverlayTrigger>
            </div>

          )}

        </div>
      </div >
      <div className="custom-profile-page">
        <Container fluid="true">
          <div className="hotels mb-2 d-flex align-items-center">
            <span className="d-inline-block me-2">
              <img
                onError={(e: any) => {
                  e.target.src = ByDefaultHotaleIcon;
                }}
                src={
                  hotelDetails
                    ? hotelDetails.properties[0].PropertyLogo !== "" &&
                      hotelDetails.properties[0].PropertyLogo
                      ? hotelDetails.properties[0].PropertyLogo
                      : ByDefaultHotaleIcon
                    : ByDefaultHotaleIcon
                }
                width={45}
                height={45}
                className="rounded o-fit-cover"
                alt=""
              />
            </span>
            <span className="hotels-title">
              {hotelDetails ? hotelDetails.properties[0].PropertyName : ""}
            </span>
          </div>
          {!loading ? (
            hotelDetails ? (
              // <TabsView tabPaneList={tabList} />
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k: any) => setKey(k)}
                className="tab-component mb-3"
              >
                <Tab eventKey="Hotel Information" title="Hotel Information">
                  {key === "Hotel Information" && (
                    <PropertyInformation hotelDetails={hotelDetails} />
                  )}
                </Tab>
                <Tab eventKey="Competitor Hotel Tab" title="Competitor Hotels">
                  {key === "Competitor Hotel Tab" && (
                    <CompetitorHotelTab
                      showModel={showModel}
                      setShowModel={setShowModel}
                      property_id={location.state && location.state}
                      property_Data={location.state && location.state}
                    />
                  )}
                </Tab>
                <Tab eventKey="Merge Document" title="Merge Document">
                  {key === "Merge Document" && (
                    <MergeDocument property_Data={location.state && location.state} />
                  )}
                </Tab>
                <Tab eventKey="Sales Checklist" title="Sales Checklist">
                  {key === "Sales Checklist" && (
                    <SalesChecklist property_Data={location.state && location.state} />
                  )}
                </Tab>
                <Tab
                  eventKey="Third Party Website Login Details"
                  title="Third Party Website Login Details"
                >
                  {key === "Third Party Website Login Details" && (
                    <ThirdPartyWebsite property_Data={location.state && location.state} />
                  )}
                </Tab>
                <Tab
                  eventKey="Food/Beverages and AV Equipment Setup"
                  title="Food/Beverages and AV Equipment Setup"
                >
                  {key === "Food/Beverages and AV Equipment Setup" && (
                    <FoodAndAv property_Data={location.state && location.state} />
                  )}
                </Tab>
                <Tab eventKey="TAX Information" title="TAX Information">
                  {key === "TAX Information" && (
                    <TaxInformationTab
                      hotelDetails={hotelDetails}
                      property_id={location.state && location.state.property_id}
                    />
                  )}
                </Tab>
              </Tabs>
            ) : (
              // <NoDataFoundImage />
              ""
            )
          ) : (
            <PageLoader />
          )}
        </Container>
      </div>

      {
        modalShowproperty === true && (
          <AddProperties
            getPropertiesListData={fetchHotelDetailsByPropertyId}
            show={modalShowproperty}
            onHide={() => {
              setEditPropertiesID("");
              setModalShowproperty(false);
              setKey("Hotel Information")
              // Blank The fields After close PopUp.
            }}
            EditPropertiesID={EditPropertiesID}
            EditClientID={EditClientID}
            PaymentInfo={PaymentInfo}
            getPaymentInfoListDropdown={getPaymentInfoListDropdown}
          />
        )
      }
    </>
  );
}

export default HotelDetails;
