/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import PatternFormat from "react-number-format";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import TagsInput from "react-tagsinput";
import { toast } from 'react-toastify';
import PageLoader from '../../Component/Loader/PageLoader';
import { CommonService, GetPageRight, fnCheckValidationOfObject } from '../../Service/CommonService';
import { CompSetHotelGetListByProperty, CompSetHotelsDeleteByProperty, EditMeetingRoomsListByPropertyID, EditPropertiesWithAllDetailByID, GetAvEquipmentsAndMISCList, GetFoodAndBeveragesList, InsertProperties, PropertiesPaymentInfoList, UpdateProperties, UpdatePropertyLogo } from '../../Service/PropertieService';
import { getProjectTermByCategory, getUserPortfolioList } from '../../Service/UserSetupService';
import SquarePlus from "../../assets/Images/Icons/square-plus.svg";
import AddPaymentInfoModal from './AddPaymentInfoModal';
import RoomTypeTaxDetailModal from './RoomTypeTaxDetailModal';
import TaxInformationModal from './TaxInformationModal';
import AddEditCompetitorModal from './AddEditCompetitorModal';
import Swal from 'sweetalert2';

export const AddProperties: any = (props) => {
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  let ClientLabel = props.EditPropertiesID !== null && props.EditPropertiesID !== undefined && props.EditPropertiesID !== "" ? props.EditClientNAme : localStorage.getItem('SelectedClientLabel');
  const dataSelector: any = useSelector((state: any) => state.session);
  const [SourceDropdownList, setSourceDropdownList] = useState([]);
  const [RoomTypeDropdownList, setRoomTypeDropdownList] = useState([]);
  const [AssociationDropdown, setAssociationDropdown]: any = useState([]);
  //Declare Tax
  let validationJson = {
    TaxTitle: "",
    RateOrPercentage: "",
  };
  const [inputTaxHotelListEr, setInputTaxHotelListEr]: any = useState([validationJson]);
  const [inputTaxHotelList, setInputTaxHotelList]: any = useState([]);
  const [inputMeetingRoomTaxList111, setInputMeetingRoomTaxList111]: any = useState([]);
  const [inputGuestRoomTaxList, setInputGuestRoomTaxList]: any = useState([]);
  const [inputAVEquipmentTaxList, setInputAVEquipmentTaxList]: any = useState([]);
  const [inputFoodTaxList, setInputFoodTaxList]: any = useState([]);
  const [inputBeverageTaxList, setInputBeverageTaxList]: any = useState([]);
  const [IsvalidState, setIsvalidState] = React.useState({ status: false, Value: "" });
  const [ValidTaxRoomTab, setValidTaxRoomTab]: any = useState(false);


  const [HotelTaxDropdownList, setHotelTaxDropdownList] = useState([]);
  const [AddPropertyActiveKey, setAddPropertyActiveKey] = useState(1);
  let ClientID = props.EditPropertiesID !== null && props.EditPropertiesID !== undefined && props.EditPropertiesID !== "" ? props.EditClientID : localStorage.getItem('SelectedClientID');
  const Token = dataSelector.access_Token;
  const [tags, setTags] = useState([]);
  const handleChange = (value) => {
    setTags(value);
  };
  const [PortfolioDropdown, setPortfolioDropdown] = React.useState([])
  const [ChainItems, setChainItems] = useState([])
  const [ValidMeetingRoom, setValidMeetingRoom]: any = useState(false);

  const [ValidGuestRoom, setValidGuestRoom]: any = useState(false);

  const [inputList, setInputList]: any = useState([]);
  let found: any = [];
  if (props.PaymentInfo) {

    found = props.PaymentInfo && props.PaymentInfo.find(e => e.isDefaultPaymentInfo === true);

  }
  const [PaymentInfo, setPaymentInfo]: any = React.useState(found);
  const [InputTaxList, setInputTaxList]: any = useState({ lstRoomTypeTaxes: [] });
  const [StoreEditRoomMeetingTaxdata, setStoreEditRoomMeetingTaxdata] = useState([]);
  const [StoreEditRoomTypeTaxdata, setStoreEditRoomTypeTaxdata] = useState([]);
  let [TaxRowNo, setTaxRowNo]: any = React.useState(1);
  let [TaxRoomTypeRowNo, setTaxRoomTypeRowNo]: any = React.useState(1);
  let [RoomNamePass, setRoomNamePass]: any = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [modalShowTaxInformation, setModalShowTaxInformation] = React.useState(false);
  const [modalShowRoomTypeTaxInformation, setmodalShowRoomTypeTaxInformation] = React.useState(false);
  let [PropertySave, setPropertySave]: any = useState({
    //HOTEL TAB START
    HChain: "",
    HPropertyName: "",
    HTARGETBAR: "",
    HSecondaryName: "",
    HTotalRoom: "",
    HPropertyCode: "",
    HPropertyID: "00000000-0000-0000-0000-000000000000",
    HHotelCode: "",
    HPortFolio: "",
    HPortFolioName: "",
    HClientID: ClientID,
    HTARGETLNR: "",
    HDUNSNumber: "",
    HFEMANumber: "",
    HRate: "",
    // HWebsiteLogIn: "",
    // HLogin: "",
    // HLoginPass: "",
    // HCVENTLogin: "",
    // HCVENTPassword: "",
    HBrandWebsite: "",
    HBrandLogin: "",
    HBrandPassword: "",
    HPlanLogin: "",
    HPlanPasword: "",
    HStatus: "Active",
    HAssignUser: "",
    HLogo: "",
    HRFPSystem: "",
    HpaymentInfoID: found && found.paymentInfoID,
    //ORGANIZATION TAB START
    OOWName: "",
    OOWPhone: "",
    OOWEmail: "",
    OOWExt: "",

    OGMName: "",
    OGMPhone: "",
    OGMEmail: "",
    OGMExt: "",

    ODSName: "",
    ODSPhone: "",
    ODSEmail: "",
    ODSExt: "",

    OCMName: "",
    OCMPhone: "",
    OCMEmail: "",
    OCMExt: "",

    OSMName: "",
    OSMPhone: "",
    OSMEmail: "",
    OSMExt: "",

    ORMName: "",
    ORMPhone: "",
    ORMEmail: "",
    ORMExt: "",

    OREName: "",
    OREEmail: "",
    OREPhone: "",
    OREExt: "",

    ORSName: "",
    ORSEmail: "",
    ORSPhone: "",
    ORSExt: "",
    //Address TAB START
    AStreet: "",
    AState: "",
    ACity: "",
    ACountry: "",
    AZipCode: "",
    ACountryCode: "",
    APhoneno: "",
    AReportEmail: "",
    //Meeting Rooms TAB START
    MServiceCharge: "",
    MGratuity: "",
    MTaxRate: "",
    IsTaxChange: false,
    errors: {
      //HOTEL TAB START
      HChain: "",
      HPropertyName: "",
      HTotalRoom: "",
      HSecondaryName: "",
      HPropertyCode: "",
      HPortFolio: "",
      HRate: "",
      // HTARGETBAR: "",
      // HTARGETLNR: "",

      // HPortFolioName: "",
      // HClientID: ClientID,
      // HDUNSNumber: "",
      // HFEMANumber: "",
      // HWebsiteLogIn: "",
      // HLogin: "",
      // // HLoginPass: "",
      // // HCVENTLogin: "",
      // HCVENTPassword: "",
      // HBrandWebsite: "",
      // HBrandLogin: "",
      // HBrandPassword: "",
      // HPlanLogin: "",
      // HPlanPasword: "",
      // HStatus: "",
      // HAssignUser: "",
      // HLogo: "",
      // HRFPSystem: "",
      // Second Tab :
      OOWName: "",
      OOWPhone: "",
      OOWEmail: "",

      OGMName: "",
      OGMPhone: "",
      OGMEmail: "",

      ODSName: "",
      ODSPhone: "",
      ODSEmail: "",

      OCMName: "",
      OCMPhone: "",
      OCMEmail: "",

      OSMName: "",
      OSMPhone: "",
      OSMEmail: "",

      ORMName: "",
      ORMPhone: "",
      ORMEmail: "",

      OREName: "",
      OREEmail: "",
      OREPhone: "",

      ORSName: "",
      ORSEmail: "",
      ORSPhone: "",

      // Fourth Tab :
      MServiceCharge: "",
      MGratuity: "",
      MTaxRate: "",
      APhoneno: "",
      Rate: "",
      ValidationRules: [
        //#region // Tab One Start : 
        {
          FieldName: "HChain",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HPropertyName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HTotalRoom",
          // ValidationType: "required",
          // ValidationMessage: "Please enter a valid number.",
          ValidationType: "minmax",
          ValidationMessage: "Enter Valid Between {0} And {1}",
          Min: 0,
          Max: 999
        },
        {
          FieldName: "HSecondaryName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HPropertyCode",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "HPortFolio",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        // {
        //   FieldName: "HTARGETBAR",
        //   ValidationType: "required",
        //   ValidationMessage: "Please enter a valid number.",
        // },
        {
          FieldName: "HRate",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        //#endregion // Tab One End :

        //#region // Tab Two Start : 
        // Owner :
        {
          FieldName: "OOWPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OOWEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // GM :
        {
          FieldName: "OGMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OGMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Director of Sales
        {
          FieldName: "ODSPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ODSEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Catering Manager
        {
          FieldName: "OCMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OCMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Sales Manager
        {
          FieldName: "OSMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OSMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Revenue Manager
        {
          FieldName: "ORMPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ORMEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Regional
        {
          FieldName: "OREPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "OREEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        // Regional Sales
        {
          FieldName: "ORSPhone",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "ORSEmail",
          ValidationType: "email",
          ValidationMessage: "Please enter a valid email address.",
        },
        //#endregion   // Tab Two End :
        {
          FieldName: "APhoneno",
          ValidationType: "mobile-mask",
          ValidationMessage: "Enter Valid Contact No.",
        },
        //#region  // Tab Four Start :
        {
          FieldName: "MServiceCharge",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        {
          FieldName: "MGratuity",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        {
          FieldName: "MTaxRate",
          ValidationType: "number",
          ValidationMessage: "Please enter a valid number.",
        },

        //#endregion // Tab Four End :
      ],
    },
  });
  const [MeetingRoomTable, setMeetingRoomTable]: any = useState([
    {
      Col0: "Meeting Room Name",
      Col1: "Association Room",
      Col2: "Square Feet",
      Col3: "Tax",
      Col4: "",
      // Col5: "",
      // Col6: "",
    },
  ]);
  let UpdatedoptionsAssociation: any = [];
  const [ProjectTermData, setProjectTermData]: any = React.useState([]);
  const [modalShowProperties, setModalShowProperties] = React.useState(false);
  let optionsPortFolio: any = [{
    value: null,
    label: "Select Portfolio",
  }];
  PortfolioDropdown.map((item: any) => {
    // if (item.portfolioName !== "All") {
    optionsPortFolio.push({
      value: item.PortfolioID,
      label: item.PortfolioName,
    })
    // };
  });
  let optionsTax: any = [
    {
      value: null,
      label: "Select Service Tax",
    },
  ];


  let aveTypesList: any = [];

  HotelTaxDropdownList && HotelTaxDropdownList.map((item: any) => {
    if (item.category === "Hotel Tax") {
      optionsTax.push({
        label: item.term,
        value: item.term,
        node: "Creatable",
      });
    }
    if (item.category === "AVEType") {
      aveTypesList.push({
        label: item.termCode,
        value: item.termID,
      });
    }
  })

  let items: any = []
  ChainItems !== undefined && ChainItems !== null && ChainItems.length > 0 && ChainItems.map((item: any) => {
    items.push({
      label: item.termCode + "-" + item.term,
      value: item.termCode + "-" + item.term,
    });
  });
  let optionsAssociation: any = [];
  AssociationDropdown !== undefined &&
    AssociationDropdown !== null &&
    AssociationDropdown.length > 0 &&
    AssociationDropdown.map((item: any) => {
      optionsAssociation.push({
        value: item.roomName,
        label: item.roomName,
      });
    });
  let optionsRFP: any = [{
    value: null,
    label: "Select RFP List",
  }];
  SourceDropdownList.map((item: any) => {
    optionsRFP.push({
      label: item.term,
      value: item.term,

    });
  });
  let itemsEditNewValue: any = [];
  let itemsNew: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.category === "RoomSetup") {
        itemsNew.push({
          label: item.term,
          value: item.termCode,
        });
      }
    });
  let RoomTypesDropdown: any = [];
  RoomTypeDropdownList !== undefined &&
    RoomTypeDropdownList !== null &&
    RoomTypeDropdownList.length > 0 &&
    RoomTypeDropdownList.map((item: any) => {
      RoomTypesDropdown.push({
        value: item.termCode,
        label: item.termCode,
      });
    });


  // let aveTypesList: any = [];
  // ProjectTermData !== undefined &&
  //   ProjectTermData !== null &&
  //   ProjectTermData.length > 0 &&
  //   ProjectTermData.map((item: any) => {

  //     if (item.category === "AVEType") {
  //       aveTypesList.push({
  //         label: item.termCode,
  //         value: item.termID,
  //       });
  //     }
  //   });

  React.useEffect(() => {
    handleChainItem()
    getPortFolioDropdown()
    getRFPListDropdown();
    getRoomTypeListDropdown();

    getRoomSetupListDropdown();
    getHotelTaxDropdown();

  }, [])
  React.useEffect(() => {

    // if (
    //   props.EditPropertiesID === "" ||
    //   props.EditPropertiesID === undefined ||
    //   props.EditPropertiesID === null
    // ) {
    let x: any = "";
    for (let index = 1; index < MeetingRoomTable.length; index++) {
      x = document.getElementById("MeetingRoom_" + index + "_Col0")?.value;

      if (x !== "" && x !== undefined && x !== null) {
        if (optionsAssociation.find((i) => i.value === x) === undefined) {

          optionsAssociation.push({
            value: x,
            label: x,
          });
        }

      } else {
        optionsAssociation.splice(index, 1);
      }
      // }
    }
  }, [UpdatedoptionsAssociation]);
  async function handleChainItem() {
    let inputs = {
      category: "Hotel Brand",
      clientID: null,
    };
    //Api for souce but used for Reuse Code
    const res: any = await getProjectTermByCategory(inputs, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          if (res.data.data.projectTermL !== undefined && res.data.data.projectTermL !== null) {
            setChainItems(res.data.data.projectTermL);
          }
        }
      }
    }
  }
  const handleInputRoomCreatable = (event: any, index) => {

    if (event !== "") {
      const list = [...inputList];
      list[index].roomType = event;
      setInputList(list);
    } else {
      const list = [...inputList];
      list[index].roomType = "";
      setInputList(list);
    }
  };
  const handleInputRoomSelect = (event: any, index) => {

    if (event !== null) {
      const list = [...inputList];
      list[index].roomType = event.value;
      setInputList(list);
    }
    else {
      const list = [...inputList];
      list[index].roomType = "";
      setInputList(list);
    }
  };
  async function getEditPropertiesDataNewTable(item: any, id) {
    let input = {
      propertyID: item,
      clientID: id, // localStorage.getItem('SelectedClientID'),
    };


    setLoading(true);
    let res: any = await EditPropertiesWithAllDetailByID(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {

          if (res.data.data.properties[0].ReportEmailTo) {
            setTags(res.data.data.properties[0].ReportEmailTo.split(","));
          }
          debugger
          setPropertySave({
            ...PropertySave,
            OOWExt: res.data.data.properties && res.data.data.properties[0].OwnerExt === null ? "" : res.data.data.properties[0].OwnerExt,
            OGMExt: res.data.data.properties && res.data.data.properties[0].GmExt === null ? "" : res.data.data.properties[0].GmExt,
            ODSExt: res.data.data.properties && res.data.data.properties[0].DirectorExt === null ? "" : res.data.data.properties[0].DirectorExt,
            OCMExt: res.data.data.properties && res.data.data.properties[0].CateringMangerExt === null ? "" : res.data.data.properties[0].CateringMangerExt,
            OSMExt: res.data.data.properties && res.data.data.properties[0].SalesaManagerExt === null ? "" : res.data.data.properties[0].SalesaManagerExt,
            ORMExt: res.data.data.properties && res.data.data.properties[0].RevenueManagerExt === null ? "" : res.data.data.properties[0].RevenueManagerExt,
            OREExt: res.data.data.properties && res.data.data.properties[0].RegOpeExt === null ? "" : res.data.data.properties[0].RegOpeExt,
            ORSExt: res.data.data.properties && res.data.data.properties[0].RegSlsExt === null ? "" : res.data.data.properties[0].RegSlsExt,

            HChain: res.data.data.properties && res.data.data.properties[0].BrandName === null ? "" : res.data.data.properties[0].BrandName,
            HPropertyName: res.data.data.properties && res.data.data.properties[0].PropertyName === null ? "" : res.data.data.properties[0].PropertyName,
            HTARGETBAR: res.data.data.properties && res.data.data.properties[0].TargetBARWeekdayRate === null ? 0 : res.data.data.properties[0].TargetBARWeekdayRate,
            HSecondaryName:
              res.data.data.properties && res.data.data.properties[0].SecondaryFirstName === null ? "" : res.data.data.properties[0].SecondaryFirstName,
            HTotalRoom: res.data.data.properties && res.data.data.properties[0].TotalRooms === null ? "" : res.data.data.properties[0].TotalRooms,
            HPropertyCode: res.data.data.properties && res.data.data.properties[0].PropertyCode === null ? "" : res.data.data.properties[0].PropertyCode,
            HPropertyID: res.data.data.properties && res.data.data.properties[0].PropertyID === null ? "" : res.data.data.properties[0].PropertyID,
            HHotelCode: res.data.data.properties && res.data.data.properties[0].HotelCode === null ? "" : res.data.data.properties[0].HotelCode,
            HPortFolio: res.data.data.properties && res.data.data.properties[0].PortfolioID === null ? "" : res.data.data.properties[0].PortfolioID,
            HPortFolioName: res.data.data.properties && res.data.data.properties[0].Portfolio === null ? "" : res.data.data.properties[0].Portfolio,
            HClientID: res.data.data.properties && res.data.data.properties[0].ClientID === null ? "" : res.data.data.properties[0].ClientID,
            HTARGETLNR: res.data.data.properties && res.data.data.properties[0].TARGETLNRRFPRATE === null ? 0 : res.data.data.properties[0].TARGETLNRRFPRATE,
            HDUNSNumber: res.data.data.properties && res.data.data.properties[0].DUNSNumber === null ? "" : res.data.data.properties[0].DUNSNumber,
            HFEMANumber: res.data.data.properties && res.data.data.properties[0].FEMANumber === null ? "" : res.data.data.properties[0].FEMANumber,
            HWebsiteLogIn: res.data.data.properties && res.data.data.properties[0].HotelLoginUrl === null ? "" : res.data.data.properties[0].HotelLoginUrl,
            HLogin: res.data.data.properties && res.data.data.properties[0].HotelLoginUserName === null ? "" : res.data.data.properties[0].HotelLoginUserName,
            HLoginPass: res.data.data.properties && res.data.data.properties[0].HotelLoginPassword === null ? "" : res.data.data.properties[0].HotelLoginPassword,
            HCVENTLogin: res.data.data.properties && res.data.data.properties[0].CVENTUserName === null ? "" : res.data.data.properties[0].CVENTUserName,
            HCVENTPassword: res.data.data.properties && res.data.data.properties[0].CVENTPassword === null ? "" : res.data.data.properties[0].CVENTPassword,
            HBrandWebsite:
              res.data.data.properties && res.data.data.properties[0].BrandPortalAccessUrl === null ? "" : res.data.data.properties[0].BrandPortalAccessUrl,
            HBrandLogin:
              res.data.data.properties && res.data.data.properties[0].BrandPortalAccessUserName === null ? "" : res.data.data.properties[0].BrandPortalAccessUserName,
            HBrandPassword:
              res.data.data.properties && res.data.data.properties[0].BrandPortalAccessPassword === null ? "" : res.data.data.properties[0].BrandPortalAccessPassword,
            HPlanLogin: res.data.data.properties && res.data.data.properties[0].HotelPlannerUserName === null ? "" : res.data.data.properties[0].HotelPlannerUserName,
            HPlanPasword:
              res.data.data.properties && res.data.data.properties[0].HotelPlannerPassword === null ? "" : res.data.data.properties[0].HotelPlannerPassword,
            HStatus: res.data.data.properties && res.data.data.properties[0].PropertyStatus_Term === null ? "" : res.data.data.properties[0].PropertyStatus_Term,
            HAssignUser: res.data.data.properties && res.data.data.properties[0].AutoActivities === null ?
              true : res.data.data.properties[0].AutoActivities === null ? "" : res.data.data.properties[0].AutoActivities,
            // HLogo: res.data.data.properties&& res.data.data.properties[0].propertyLogo=== null ? "" : res.data.data.properties[0].OwnerExt,
            HRFPSystem: res.data.data.properties && res.data.data.properties[0].RFPSystem === null ? "" : res.data.data.properties[0].RFPSystem,
            HRFPSystemID: res.data.data.properties && res.data.data.properties[0].RFPSystem === null ? "" : res.data.data.properties[0].RFPSystem,
            HpaymentInfoID: res.data.data.properties && res.data.data.properties[0].PaymentInfoID === null ?
              "00000000-0000-0000-0000-000000000000" : res.data.data.properties[0].PaymentInfoID === null ? "" : res.data.data.properties[0].PaymentInfoID,

            OOWName: res.data.data.properties && res.data.data.properties[0].OwnerName === null ? "" : res.data.data.properties[0].OwnerName,
            OOWPhone: res.data.data.properties && res.data.data.properties[0].PrimaryContactNo.includes(" ") ? res.data.data.properties[0].PrimaryContactNo.split(" ")[1] : res.data.data.properties[0].PrimaryContactNo,
            OOWCountryCode: res.data.data.properties &&
              res.data.data.properties[0].PrimaryContactNo.split(" ")[0],
            OOWEmail: res.data.data.properties && res.data.data.properties[0].PrimaryEmailID === null ? "" : res.data.data.properties[0].PrimaryEmailID,
            OGMName: res.data.data.properties && res.data.data.properties[0].GmName === null ? "" : res.data.data.properties[0].GmName,
            OGMPhone: res.data.data.properties && res.data.data.properties[0].SecondaryContactNo && res.data.data.properties[0].SecondaryContactNo.includes(" ") ? res.data.data.properties[0].SecondaryContactNo.split(" ")[1] : res.data.data.properties[0].SecondaryContactNo,
            OGMCountryCode: res.data.data.properties && res.data.data.properties[0].SecondaryContactNo && res.data.data.properties[0].SecondaryContactNo.split(" ")[0],
            OGMEmail: res.data.data.properties && res.data.data.properties[0].SecondaryEmailID === null ? "" : res.data.data.properties[0].SecondaryEmailID,
            ODSName: res.data.data.properties && res.data.data.properties[0].DirectorOfSales === null ? "" : res.data.data.properties[0].DirectorOfSales,
            ODSPhone: res.data.data.properties && res.data.data.properties[0].DirectorContact && res.data.data.properties[0].DirectorContact.includes(" ") ? res.data.data.properties[0].DirectorContact.split(" ")[1] : res.data.data.properties[0].DirectorContact,
            ODSCountryCode: res.data.data.properties && res.data.data.properties[0].DirectorContact && res.data.data.properties[0].DirectorContact.split(" ")[0],
            ODSEmail: res.data.data.properties && res.data.data.properties[0].DirectorEmail === null ? "" : res.data.data.properties[0].DirectorEmail,
            OCMName: res.data.data.properties && res.data.data.properties[0].CateringManger === null ? "" : res.data.data.properties[0].CateringManger,
            OCMPhone: res.data.data.properties && res.data.data.properties[0].CateringMangerContact && res.data.data.properties[0].CateringMangerContact.includes(" ") ? res.data.data.properties[0].CateringMangerContact.split(" ")[1] : res.data.data.properties[0].CateringMangerContact,
            OCMCountryCode: res.data.data.properties && res.data.data.properties[0].CateringMangerContact && res.data.data.properties[0].CateringMangerContact.split(" ")[0],
            OCMEmail: res.data.data.properties && res.data.data.properties[0].CateringMangerEmail === null ? "" : res.data.data.properties[0].CateringMangerEmail,
            OSMName: res.data.data.properties && res.data.data.properties[0].SalesaManager === null ? "" : res.data.data.properties[0].SalesaManager,
            OSMPhone: res.data.data.properties && res.data.data.properties[0].SalesaManagerContact && res.data.data.properties[0].SalesaManagerContact.includes(" ") ? res.data.data.properties[0].SalesaManagerContact.split(" ")[1] : res.data.data.properties[0].SalesaManagerContact,
            OSMCountryCode: res.data.data.properties && res.data.data.properties[0].SalesaManagerContact && res.data.data.properties[0].SalesaManagerContact.split(" ")[0],
            OSMEmail: res.data.data.properties && res.data.data.properties[0].SalesaManagerEmail === null ? "" : res.data.data.properties[0].SalesaManagerEmail,
            ORMName: res.data.data.properties && res.data.data.properties[0].RevenueManager === null ? "" : res.data.data.properties[0].RevenueManager,
            ORMPhone: res.data.data.properties && res.data.data.properties[0].RevenueManagerContact && res.data.data.properties[0].RevenueManagerContact.includes(" ") ? res.data.data.properties[0].RevenueManagerContact.split(" ")[1] : res.data.data.properties[0].RevenueManagerContact,
            ORMCountryCode: res.data.data.properties && res.data.data.properties[0].RevenueManagerContact && res.data.data.properties[0].RevenueManagerContact.split(" ")[0],
            ORMEmail: res.data.data.properties && res.data.data.properties[0].RevenueManagerEmail === null ? "" : res.data.data.properties[0].RevenueManagerEmail,
            OREName: res.data.data.properties && res.data.data.properties[0].RegionalOperations === null ? "" : res.data.data.properties[0].RegionalOperations,
            OREEmail: res.data.data.properties && res.data.data.properties[0].RegionalOperationsEmail === null ? "" : res.data.data.properties[0].RegionalOperationsEmail,
            OREPhone: res.data.data.properties && res.data.data.properties[0].RegionalOperationsPhone && res.data.data.properties[0].RegionalOperationsPhone.includes(" ") ? res.data.data.properties[0].RegionalOperationsPhone.split(" ")[1] : res.data.data.properties[0].RegionalOperationsPhone,
            ORECountryCode: res.data.data.properties && res.data.data.properties[0].RegionalOperationsPhone && res.data.data.properties[0].RegionalOperationsPhone.split(" ")[0],
            ORSName: res.data.data.properties && res.data.data.properties[0].RegionalSales === null ? "" : res.data.data.properties[0].RegionalSales,
            ORSEmail: res.data.data.properties && res.data.data.properties[0].RegionalSalesEmail === null ? "" : res.data.data.properties[0].RegionalSalesEmail,
            ORSPhone: res.data.data.properties && res.data.data.properties[0].RegionalSalesPhone && res.data.data.properties[0].RegionalSalesPhone.includes(" ") ? res.data.data.properties[0].RegionalSalesPhone.split(" ")[1] : res.data.data.properties[0].RegionalSalesPhone,
            ORSCountryCode: res.data.data.properties && res.data.data.properties[0].RegionalSalesPhone && res.data.data.properties[0].RegionalSalesPhone.split(" ")[0],
            AStreet: res.data.data.properties && res.data.data.properties[0].PropertyAddress === null ? "" : res.data.data.properties[0].PropertyAddress,
            AState: res.data.data.properties && res.data.data.properties[0].State === null ? "" : res.data.data.properties[0].State,
            ACity: res.data.data.properties && res.data.data.properties[0].City === null ? "" : res.data.data.properties[0].City,
            ACountry: res.data.data.properties && res.data.data.properties[0].Country === null ? "" : res.data.data.properties[0].Country,
            AZipCode: res.data.data.properties && res.data.data.properties[0].ZipCode === null ? "" : res.data.data.properties[0].ZipCode,
            AExt: res.data.data.properties && res.data.data.properties[0].HotelExt === null ? "" : res.data.data.properties[0].HotelExt,
            APhoneno: res.data.data.properties && res.data.data.properties[0].HotelPhone && res.data.data.properties[0].HotelPhone.includes(" ") ? res.data.data.properties[0].HotelPhone.split(" ")[1] : res.data.data.properties[0].HotelPhone,
            ACountryCode: res.data.data.properties && res.data.data.properties[0].HotelPhone && res.data.data.properties[0].HotelPhone.split(" ")[0],
            AReportEmail: res.data.data.properties && res.data.data.properties[0].ReportEmailTo === null ? "" : res.data.data.properties[0].ReportEmailTo,
            MServiceCharge: res.data.data.properties && res.data.data.properties[0].ServiceCharge === null ? 0 : res.data.data.properties[0].ServiceCharge,
            MGratuity: res.data.data.properties && res.data.data.properties[0].Gratuity === null ? 0 : res.data.data.properties[0].Gratuity,
            MTaxRate: res.data.data.properties && res.data.data.properties[0].TaxRate === null ? 0 : res.data.data.properties[0].TaxRate,
            HRate: res.data.data.properties && res.data.data.properties[0].Rate === null ? 0 : res.data.data.properties[0].Rate,
          });
          debugger
          setLogoUpload(
            res.data.data.properties && res.data.data.properties[0].PropertyLogo
          );

          let inputs = {
            category: "RoomSetup,State,Country,AVEType",
            clientID: id,
          };

          const resDropdown: any = await getProjectTermByCategory(inputs, Token);

          if (resDropdown !== undefined && resDropdown !== null) {
            if (resDropdown.data !== undefined && resDropdown.data !== null) {
              if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {

                setProjectTermData(resDropdown.data.data.projectTermL);

                resDropdown.data.data.projectTermL.map((item: any) => {
                  if (item.Category === "RoomSetup") {
                    itemsEditNewValue.push({
                      label: item.Term,
                      value: item.TermCode,
                    });
                  }
                });
                if (
                  res.data.data.roomMeeting !== null &&
                  res.data.data.roomMeeting.length !== 0
                ) {
                  let RoomMeeting: any = res.data.data.roomMeeting;

                  let NoOfCol = RoomMeeting[0].length;
                  let list: any = [];
                  let NewObjHeader = {};

                  let countCols = 0;
                  let countColsNew = 4;
                  setMeetingRoomTable([]);

                  for (let c = 0; c < NoOfCol + 4; c++) {
                    if (c === 0) {
                      NewObjHeader["Col" + c] = "Meeting Room Name";
                    } else if (c === 1) {
                      NewObjHeader["Col" + c] = "Association Room";
                    } else if (c === 2) {
                      NewObjHeader["Col" + c] = "Square Feet";
                    } else if (c === 3) {
                      NewObjHeader["Col" + c] = "Tax";
                    } else if (c >= 4) {
                      // let result: any = [];
                      let term = RoomMeeting[0][countCols]["roomSetupTitle"];
                      if (term !== "undefined") {
                        let xyz = itemsEditNewValue.filter(
                          (obj) =>
                            RoomMeeting[0][countCols]["roomSetupTitle"] &&
                            RoomMeeting[0][countCols]["roomSetupTitle"].includes(obj.label)
                        );
                        if (xyz === undefined) {
                          itemsEditNewValue.push({ label: term, value: term });
                        }
                      }
                      NewObjHeader["Col" + c] = RoomMeeting[0][countCols]["roomSetupTitle"];
                    }

                    if (c > 3) {
                      countCols = countCols + 1;
                      countColsNew = countColsNew + 1;
                    }
                  }

                  list.push(NewObjHeader);
                  for (let r = 1; r <= RoomMeeting.length; r++) {
                    let NewObj = {};
                    for (let x = 0; x < NoOfCol + 4; x++) {
                      let HTMl = "";
                      if (x === 0) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x] &&
                          RoomMeeting[r - 1][x]["roomName"] &&
                          RoomMeeting[r - 1][x]["roomName"];
                      } else if (x === 1) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 1] &&
                          RoomMeeting[r - 1][x - 1]["parentmeetingroom"] &&
                          RoomMeeting[r - 1][x - 1]["parentmeetingroom"];
                      } else if (x === 2) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 2] &&
                          RoomMeeting[r - 1][x - 2]["squareFeet"] &&
                          RoomMeeting[r - 1][x - 2]["squareFeet"];
                      } else if (x === 3) {
                        HTMl = res.data.data.roomMeetingTaxes.filter((a) => a.rowNo === r);
                      } else if (x >= 4) {
                        HTMl =
                          RoomMeeting &&
                          RoomMeeting[r - 1] &&
                          RoomMeeting[r - 1][x - 4] &&
                          RoomMeeting[r - 1][x - 4]["roomSetup"] &&
                          RoomMeeting[r - 1][x - 4]["roomSetup"];
                      }
                      NewObj["Col" + x] = HTMl;
                    }
                    list.push(NewObj);
                  }

                  if (list.length !== 0) {
                    setMeetingRoomTable((oldData) => [...oldData, ...list]);
                  }
                }
              }
            }
          }
          if (res.data.data.roomTypes.length > 0) {
            setInputList(res.data.data.roomTypes);
          }
          setInputTaxList(res.data.data.roomTypeTaxes);
          setInputMeetingRoomTaxList111(res.data.data.propertiesRoomMeetingTaxes);
          if (res.data.data.propertiesRoomTypeTaxes.length > 0) {
            setInputGuestRoomTaxList(res.data.data.propertiesRoomTypeTaxes);
          }
          if (res.data.data.avEquipmentTaxes.length > 0) {
            setInputAVEquipmentTaxList(res.data.data.avEquipmentTaxes);
          }
          if (res.data.data.foodTaxes.length > 0) {
            setInputFoodTaxList(res.data.data.foodTaxes);
          }
          if (res.data.data.beverageTaxes.length > 0) {
            setInputBeverageTaxList(res.data.data.beverageTaxes);
          }
          if (res.data.data.propertiesTaxes.length > 0) {
            setInputTaxHotelList(res.data.data.propertiesTaxes);
          }


          let JsonToImEr: any = [];
          let JsonObjEr: any = {};

          res.data.data.propertiesTaxes.map(() => {
            JsonObjEr = {
              ...JsonObjEr,
              TaxTitle: "",
              RateOrPercentage: "",
            };
            JsonToImEr.push(JsonObjEr);
          });
          if (JsonToImEr.length > 0) {
            setInputTaxHotelListEr(JsonToImEr);
          }

          setLoading(false);
        }
      }
    }
  }
  async function getHotelTaxDropdown() {
    let inputs = {
      category: "Hotel Tax, AVEType",
      clientID: ClientID,
    };
    setLoading(true);
    const resDropdown: any = await getProjectTermByCategory(inputs, Token);
    setLoading(false);
    if (resDropdown.data.success) {
      if (resDropdown.data !== null && resDropdown.data !== undefined) {
        if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {
          setHotelTaxDropdownList(resDropdown.data.data.projectTermL);
        }
      }
    }
  }

  async function getPortFolioDropdown() {
    let input = {
      portfolioID: null,
      portfolioName: null,
      clientID: ClientID,
      isActive: true,
      IsIncludeAllPortfolio: true
    }
    let res: any = await getUserPortfolioList(input, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          if (res.data.data.dataList1 !== null && res.data.data.dataList1 !== undefined) {

            setPortfolioDropdown(res.data.data.dataList1);
          }
        }
      }
    }
  }
  async function getRFPListDropdown() {
    let inputs = {
      category: "RFP System",
      // form: null,
      clientID: null,
    };
    const res: any = await getProjectTermByCategory(inputs, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          if (res.data.data.projectTermL !== null && res.data.data.projectTermL !== undefined) {

            setSourceDropdownList(res.data.data.projectTermL);
          }
        }
      }
    }
  }
  async function getRoomTypeListDropdown() {
    let inputs = {
      category: "RoomTypes",
      // form: null,
      clientID: null,
    };

    const res: any = await getProjectTermByCategory(inputs, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          if (res.data.data.projectTermL !== null && res.data.data.projectTermL !== undefined) {

            setRoomTypeDropdownList(res.data.data.projectTermL);
          }
        }
      }
    }
  }
  async function getRoomSetupListDropdown() {

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      // Competitor Data :
      fetchCompetitorListData()

      fetchAvData(props.EditPropertiesID)
      fetchFoodData(props.EditPropertiesID)
      fetchFoodBeverage(props.EditPropertiesID)
      // getEditPropertiesData(props.EditPropertiesID, props.EditClientID);
      getEditPropertiesDataNewTable(props.EditPropertiesID, props.EditClientID);
      getEditAssociationRoomsDropdown(props.EditPropertiesID);

    } else {
      setLoading(true);
      AddTime();
    }




  }
  async function getEditAssociationRoomsDropdown(item: any) {
    let iX = {
      propertyID: item,
      clientID: ClientID,
    };
    const resAssociationDropdown: any = await EditMeetingRoomsListByPropertyID(iX, Token);
    if (resAssociationDropdown.data.success) {
      if (resAssociationDropdown.data !== undefined) {
        if (
          resAssociationDropdown.data.data !== undefined &&
          resAssociationDropdown.data.data !== null
        ) {
          if (
            resAssociationDropdown.data.data.roomMeetings !== undefined &&
            resAssociationDropdown.data.data.roomMeetings !== null
          ) {
            setAssociationDropdown(resAssociationDropdown.data.data.roomMeetings);
          }
        }
      }
    }
  }
  async function AddTime() {
    let inputs = {
      category: "RoomSetup",
      clientID: ClientID,
    };
    const resDropdown: any = await getProjectTermByCategory(inputs, Token);

    if (resDropdown !== undefined && resDropdown !== null) {
      if (resDropdown.data !== undefined && resDropdown.data !== null) {
        if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {

          setProjectTermData(resDropdown.data.data.projectTermL);
        }
      }
    }

    setLoading(false);
  }

  function storeRoomMeetingTax(RowNo: any, RowData: any) {
    let lst: any = [...MeetingRoomTable];

    lst[RowNo]["Col3"] = RowData;
    setMeetingRoomTable(lst);

    // if (document.getElementById("lstRoomMeetingTaxes_" + RowNo) !== null) {
    //   document
    //     .getElementById("lstRoomMeetingTaxes_" + RowNo)
    //     ?.setAttribute("data-lstroommeetingtaxes", JSON.stringify(RowData));
    // }
  }
  function storeRoomTypeTax(RowNo: any, RowData: any) {

    if (document.getElementById("lstRoomTypeTaxes" + RowNo) !== null) {
      document
        .getElementById("lstRoomTypeTaxes" + RowNo)
        ?.setAttribute("data-lstRoomTypeTaxes", JSON.stringify(RowData));
    }


    // if(PropertySave.HPropertyID !== undefined && PropertySave.HPropertyID !== null && PropertySave.HPropertyID !== ""){
    // InputTaxList[RowNo] = RowData;
    // }else{inputList[RowNo].lstRoomTypeTaxes = RowData;}
  }

  function RemoveOrCheckAssociation(value: any, e, RowIndex, Col) {


    let lst: any = [...MeetingRoomTable];

    if (e.target.value === "") {
      e.target.classList.add("addValidBorder");
    }
    else {
      e.target.classList.remove("addValidBorder");

    }
    lst[RowIndex][Col] = e.target.value;
    setMeetingRoomTable(lst);

    // let lst = [...MeetingRoomTable];
    // let X = lst[RowIndex][Col];
    // if (e.target.value === "") {
    //   let newValue: any = [];
    //   for (let index = 1; index < MeetingRoomTable.length; index++) {
    //     // x.push(MeetingRoomTable[index]["Col0"]);
    //     newValue.push(MeetingRoomTable[index]["Col1"]);
    //   }
    //   for (let ix = 0; ix < newValue.length; ix++) {
    //     let ixz = ix + 1;
    //     let element: any = document.getElementById("Association_" + ixz + "_Col1");
    //     if (lst[ix + 1]["Col1"].includes(X)) {

    //       element !== null && element.classList.add("addValidBorder");
    //     }
    //   }
    // } else {
    //   let newValue: any = [];
    //   for (let index = 1; index < MeetingRoomTable.length; index++) {
    //     // x.push(MeetingRoomTable[index]["Col0"]);
    //     newValue.push(MeetingRoomTable[index]["Col1"]);
    //   }
    //   for (let ix = 0; ix < newValue.length; ix++) {
    //     let ixz = ix + 1;
    //     let element: any = document.getElementById("Association_" + ixz + "_Col1");
    //     if (lst[ix + 1]["Col1"].includes(X)) {
    //       element !== null && element.classList.remove("addValidBorder");
    //     }
    //   }
    // }
    // lst[RowIndex][Col] = e.target.value;
    // setMeetingRoomTable(lst);
  }
  async function SaveData() {

    let roomNameNotBlank = true;
    let roomTypeNameNotBlank = true;
    let IsMeetingRoomNamesAndAssociationRoomValid = true;

    let _MeetingRoomNames: any = [];
    for (let index = 1; index < MeetingRoomTable.length; index++) {
      _MeetingRoomNames.push(MeetingRoomTable[index]["Col0"]);
    }

    for (let index = 1; index < MeetingRoomTable.length; index++) {
      if (MeetingRoomTable[index]["Col1"] !== null && MeetingRoomTable[index]["Col1"] !== undefined && MeetingRoomTable[index]["Col1"] !== "") {
        let _AssociationRoom: any = MeetingRoomTable[index]["Col1"].split(",");
        for (let ar = 0; ar < _AssociationRoom.length; ar++) {
          ;
          if (!_MeetingRoomNames.includes(_AssociationRoom[ar])) {
            IsMeetingRoomNamesAndAssociationRoomValid = false;
          }
        }
      }
    }

    if (!IsMeetingRoomNamesAndAssociationRoomValid) {
      toast.error("Hello its Miss Match Meeting Room Or Association Room Please Select Again", {
        theme: "colored",
      });
      return;
    }
    // let M: any = [];
    // let newValue: any = [];

    // let resultValue: any = [];

    // let UpdatedNEwValue: any = [];
    // for (let index = 1; index < MeetingRoomTable.length; index++) {
    //   M.push(MeetingRoomTable[index]["Col0"]);
    //   newValue.push(MeetingRoomTable[index]["Col1"]);
    // }
    // let Array: any;

    // for (let t = 0; t < newValue.length; t++) {


    //   if (newValue[t] !== null && newValue[t].includes(",")) {
    //     Array = newValue[t].split(",");
    //     UpdatedNEwValue.push([...Array]);
    //   } else {
    //     if (newValue[t] !== "") {
    //       UpdatedNEwValue.push(newValue[t]);
    //     }
    //   }
    // }
    // for (let z = 0; z < UpdatedNEwValue.length; z++) {
    //   if (UpdatedNEwValue[z] !== null) {
    //     for (let y = 0; y < UpdatedNEwValue[z].length; y++) {
    //       resultValue.push(M.find((i) => i === UpdatedNEwValue[z][y]));
    //     }
    //   }
    // }

    // if (MeetingRoomTable.length > 1 && resultValue.length !== 0) {

    //   if (resultValue.includes(undefined)) {
    //     toast.error("Hello its Miss Match Meeting Room Or Association Room Please Select Again", {
    //       theme: "colored",
    //     });
    //     return
    //   }
    // }
    let obj = fnCheckValidationOfObject(PropertySave);

    setPropertySave({
      ...obj.obj,
    });
    for (let index = 1; index < MeetingRoomTable.length; index++) {
      if (MeetingRoomTable[index]["Col0"] === "" || MeetingRoomTable[index]["Col0"] === undefined) {
        let element: any = document.getElementById("MeetingRoom_" + index + "_Col0");
        element !== null && element.classList.add("addValidBorder");
        // toast.error("Please Enter Room Name", { theme: "colored" });
        setValidMeetingRoom(true);
        return;
      } else {
        let element: any = document.getElementById("MeetingRoom_" + index + "_Col0");
        element !== null && element.classList.remove("addValidBorder");
        roomNameNotBlank = true;
        setValidMeetingRoom(false);
      }
    }
    for (let x = 0; x < inputList.length; x++) {

      if (inputList[x]['roomType'] === "" || inputList[x]['roomType'] === undefined) {
        let element: any = document.getElementById("RoomType_" + x);
        element !== null && element.classList.add("addValidBorder");
        // toast.error("Please Select RoomType", { theme: "colored" });
        setValidGuestRoom(true)
        return;
      } else {

        let New: any = document.getElementById("RoomType_" + x);

        New !== null && New.classList.remove("addValidBorder");
        roomTypeNameNotBlank = true;
        setValidGuestRoom(false)
      }
    }



    let tempBeverage: any = []
    let tempFood: any = []
    let tempAv: any = []

    Object.keys(dataResBeverage).map((itm: any) => {
      tempBeverage.push({
        itemName: dataResBeverage[itm].itemName,
        itemType_Term: dataResBeverage[itm].itemType_Term,
        itemCostPerUnit: dataResBeverage[itm].itemCostPerUnit,
        taxRatePerc: dataResBeverage[itm].taxRatePerc,
        orderNo: dataResBeverage[itm].orderNo,
        description: dataResBeverage[itm].description,
      })
    })

    Object.keys(dataResFood).map((itm: any) => {
      tempFood.push({
        description: dataResFood[itm].description,
        itemCostPerUnit: dataResFood[itm].itemCostPerUnit,
        itemName: dataResFood[itm].itemName,
        itemType_Term: dataResFood[itm].itemType_Term,
        orderNo: dataResFood[itm].orderNo,
        taxRatePerc: dataResFood[itm].taxRatePerc,
      })
    })

    Object.keys(avDataRes).map((itm: any) => {
      tempAv.push({
        avEquipName: avDataRes[itm].avEquipName,
        avEquipType_Term: avDataRes[itm].avEquipType_Term,
        avEquipCost: avDataRes[itm].avEquipCost,
        taxRatePerc: avDataRes[itm].taxRatePerc,
        orderNO: avDataRes[itm].orderNO,
      })
    })


    let tempValidFood = validateFoodData()
    let tempValidBeverage = validateBeverageData()
    let tempValidAv = validAvEquipmentData()

    if (tempValidFood &&
      tempValidBeverage &&
      tempValidAv && obj.isValid) {
      if (validateForm()) {
        setInputTaxHotelListEr([...inputTaxHotelListEr, { TaxTitle: "", rateOrPercentage: "" }]);
        if (roomNameNotBlank === true || roomTypeNameNotBlank === true) {


          let formData: any = new FormData();
          inputTaxHotelList.forEach((itm, i) => {
            formData.append(`lstProperyTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyTaxes[${i}].IsPercentage`, itm.IsPercentage);
            formData.append(`lstProperyTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
            // formData.append(`lstProperyTaxes[${i}].OrderNo`, itm.OrderNo);
            formData.append(`lstProperyTaxes[${i}].OrderNo`, i);
          });

          inputMeetingRoomTaxList111.forEach((itm, i) => {
            formData.append(`lstProperyRoomMeetingTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyRoomMeetingTaxes[${i}].IsPercentage`, itm.IsPercentage);

            formData.append(
              `lstProperyRoomMeetingTaxes[${i}].RateOrPercentage`,
              itm.RateOrPercentage
            );
            // formData.append(`lstProperyRoomMeetingTaxes[${i}].OrderNo`, itm.OrderNo);
            formData.append(`lstProperyRoomMeetingTaxes[${i}].OrderNo`, i);
            formData.append(
              `lstProperyRoomMeetingTaxes[${i}].IsApply`,
              itm.IsApply === undefined ? false : itm.IsApply
            );
          });

          inputGuestRoomTaxList.forEach((itm, i) => {
            formData.append(`lstProperyRoomTypeTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyRoomTypeTaxes[${i}].IsPercentage`, itm.IsPercentage);
            formData.append(`lstProperyRoomTypeTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
            formData.append(
              `lstProperyRoomTypeTaxes[${i}].IsApply`,
              itm.IsApply === undefined ? false : itm.IsApply
            );
            // formData.append(`lstProperyRoomTypeTaxes[${i}].OrderNo`, itm.OrderNo);
            formData.append(`lstProperyRoomTypeTaxes[${i}].OrderNo`, i);
          });

          inputAVEquipmentTaxList.forEach((itm, i) => {
            formData.append(`lstProperyAvEquipmentTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyAvEquipmentTaxes[${i}].IsPercentage`, itm.IsPercentage);
            formData.append(`lstProperyAvEquipmentTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
            formData.append(
              `lstProperyAvEquipmentTaxes[${i}].IsApply`,
              itm.IsApply === undefined ? false : itm.IsApply
            );
            // formData.append(`lstProperyAvEquipmentTaxes[${i}].OrderNo`, itm.OrderNo);
            formData.append(`lstProperyAvEquipmentTaxes[${i}].OrderNo`, i);
          });
          inputFoodTaxList.forEach((itm, i) => {
            formData.append(`lstProperyFoodTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyFoodTaxes[${i}].IsPercentage`, itm.IsPercentage);
            formData.append(`lstProperyFoodTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
            formData.append(
              `lstProperyFoodTaxes[${i}].IsApply`,
              itm.IsApply === undefined ? false : itm.IsApply
            );
            formData.append(`lstProperyFoodTaxes[${i}].OrderNo`, i);
          });
          inputBeverageTaxList.forEach((itm, i) => {
            formData.append(`lstProperyBeverageTaxes[${i}].TaxTitle`, itm.TaxTitle);
            formData.append(`lstProperyBeverageTaxes[${i}].IsPercentage`, itm.IsPercentage);
            formData.append(`lstProperyBeverageTaxes[${i}].RateOrPercentage`, itm.RateOrPercentage);
            formData.append(
              `lstProperyBeverageTaxes[${i}].IsApply`,
              itm.IsApply === undefined ? false : itm.IsApply
            );
            // formData.append(`lstProperyBeverageTaxes[${i}].OrderNo`, itm.OrderNo);
            formData.append(`lstProperyBeverageTaxes[${i}].OrderNo`, i);
          });

          formData.append("propertyID", PropertySave.HPropertyID);
          formData.append("propertyName", PropertySave.HPropertyName);
          formData.append("BrandName", PropertySave.HChain);
          formData.append("totalRooms", PropertySave.HTotalRoom);
          formData.append("secondaryFirstName", PropertySave.HSecondaryName);
          formData.append("propertyCode", PropertySave.HPropertyCode);
          formData.append("hotelCode", document.getElementById("HotelCode_Value")?.value !== "" &&
            document.getElementById("HotelCode_Value")?.value);
          formData.append("portfolioID", PropertySave.HPortFolio);
          formData.append("portfolio", PropertySave.HPortFolioName);
          formData.append("propertyStatus_Term", PropertySave.HStatus);
          formData.append("rfpSystem", PropertySave.HRFPSystem);
          formData.append("hotelLoginUrl", PropertySave.HWebsiteLogIn !== undefined ? PropertySave.HWebsiteLogIn : null);
          formData.append("hotelLoginUserName", PropertySave.HLogin !== undefined ? PropertySave.HLogin : null);
          formData.append("hotelLoginPassword", PropertySave.HLoginPass !== undefined ? PropertySave.HLoginPass : null);
          formData.append("brandPortalAccessUrl", PropertySave.HBrandWebsite);
          formData.append("brandPortalAccessUserName", PropertySave.HBrandLogin);
          formData.append("brandPortalAccessPassword", PropertySave.HBrandPassword);
          formData.append("dunsNumber", PropertySave.HDUNSNumber);
          formData.append("femaNumber", PropertySave.HFEMANumber);
          formData.append("cventUserName", PropertySave.HCVENTLogin !== undefined ? PropertySave.HCVENTLogin : null);
          formData.append("cventPassword", PropertySave.HCVENTPassword !== undefined ? PropertySave.HCVENTPassword : null);
          formData.append("hotelPlannerUserName", PropertySave.HPlanLogin);
          formData.append("hotelPlannerPassword", PropertySave.HPlanPasword);
          formData.append("targetBARWeekdayRate", PropertySave.HTARGETBAR === null ? 0 : PropertySave.HTARGETBAR);
          formData.append("targetlnrrfprate", PropertySave.HTARGETLNR === null ? 0 : PropertySave.HTARGETLNR);
          formData.append("AutoActivities", PropertySave.HAssignUser);
          formData.append("paymentInfoID", PropertySave.HpaymentInfoID);
          formData.append("clientID", PropertySave.HClientID);
          formData.append("propertyLogo", "");
          formData.append("FormFiles", Logo.link[0]); // LogoUpload,

          //tab 2
          formData.append("ownerName", PropertySave.OOWName);
          formData.append("primaryContactNo",
            // PropertySave.OOWCountryCode === null ? "": 
            PropertySave.OOWCountryCode
            + " " +
            PropertySave.OOWPhone
            // === null ? "" : PropertySave.OOWPhone
          );
          formData.append("primaryEmailID", PropertySave.OOWEmail);
          formData.append("OwnerExt", PropertySave.OOWExt !== undefined ? PropertySave.OOWExt : null);

          formData.append("gmName", PropertySave.OGMName);
          formData.append("secondaryContactNo",
            // PropertySave.OGMCountryCode === null ? "" : 
            PropertySave.OGMCountryCode + " " + PropertySave.OGMPhone
            // === null ? "" : PropertySave.OGMPhone
          );
          formData.append("secondaryEmailID", PropertySave.OGMEmail);
          formData.append("GmExt", PropertySave.OGMExt !== undefined ? PropertySave.OGMExt : null);

          formData.append("DirectorOfSales", PropertySave.ODSName);
          formData.append("DirectorContact",
            //  PropertySave.ODSCountryCode === null ? "" : 
            PropertySave.ODSCountryCode + " " + PropertySave.ODSPhone
            // === null ? "" : PropertySave.ODSPhone
          );
          formData.append("DirectorEmail", PropertySave.ODSEmail);
          formData.append("DirectorExt", PropertySave.ODSExt !== undefined ? PropertySave.ODSExt : null);

          formData.append("CateringManger", PropertySave.OCMName);
          formData.append("CateringMangerContact",
            // PropertySave.OCMCountryCode === null ? "" : 
            PropertySave.OCMCountryCode + " " + PropertySave.OCMPhone
            // === null ? "" : PropertySave.OCMPhone
          );
          formData.append("CateringMangerEmail", PropertySave.OCMEmail);
          formData.append("CateringMangerExt", PropertySave.OCMExt !== undefined ? PropertySave.OCMExt : null);
          formData.append("IsTaxChange", PropertySave.IsTaxChange);

          formData.append("SalesaManager", PropertySave.OSMName);
          formData.append("SalesaManagerContact",
            // PropertySave.OSMCountryCode === null ? "" :
            PropertySave.OSMCountryCode + " " + PropertySave.OSMPhone
            // === null ? "" : PropertySave.OSMPhone
          );
          formData.append("SalesaManagerEmail", PropertySave.OSMEmail);
          formData.append("SalesaManagerExt", PropertySave.OSMExt !== undefined ? PropertySave.OSMExt : null);

          formData.append("RevenueManager", PropertySave.ORMName);
          formData.append("RevenueManagerContact",
            // PropertySave.ORMCountryCode === null ? "" : 
            PropertySave.ORMCountryCode + " " + PropertySave.ORMPhone
            // === null ? "" : PropertySave.ORMPhone
          );
          formData.append("RevenueManagerEmail", PropertySave.ORMEmail);
          formData.append("RevenueManagerExt", PropertySave.ORMExt !== undefined ? PropertySave.ORMExt : null);

          formData.append("regionalOperations", PropertySave.OREName);
          formData.append("regionalOperationsPhone",
            //  PropertySave.ORECountryCode === null ? "" : 
            PropertySave.ORECountryCode + " " + PropertySave.OREPhone
            // === null ? "" : PropertySave.OREPhone
          );
          formData.append("regionalOperationsEmail", PropertySave.OREEmail);
          formData.append("RegOpeExt", PropertySave.OREExt !== undefined ? PropertySave.OREExt : null);

          formData.append("regionalSales", PropertySave.ORSName);
          formData.append("regionalSalesPhone",
            // PropertySave.ORSCountryCode === null ? "" : 
            PropertySave.ORSCountryCode + " " + PropertySave.ORSPhone
            //  === null ? "" : PropertySave.ORSPhone
          );
          formData.append("regionalSalesEmail", PropertySave.ORSEmail);
          formData.append("RegSlsExt", PropertySave.ORSExt !== undefined ? PropertySave.ORSExt : null);

          //tab 3
          formData.append("propertyAddress", PropertySave.AStreet);
          formData.append("state", PropertySave.AState);
          formData.append("city", PropertySave.ACity);
          formData.append("country", PropertySave.ACountry);
          formData.append("zipCode", PropertySave.AZipCode);
          formData.append("hotelPhone", PropertySave.ACountryCode + " " + PropertySave.APhoneno);
          formData.append("hotelExt", PropertySave.AExt !== undefined ? PropertySave.AExt : null);
          formData.append("reportEmailTo", tags.toString()); //PropertySave.AReportEmail);

          //tab 4
          formData.append("serviceCharge", PropertySave.MServiceCharge === null ? 0 : PropertySave.MServiceCharge);
          formData.append("taxRate", PropertySave.MTaxRate === null ? 0 : PropertySave.MTaxRate);
          formData.append("gratuity", PropertySave.MGratuity === null ? 0 : PropertySave.MGratuity);
          let indexPosition = 0;
          MeetingRoomTable.forEach((itm, i) => {

            if (i > 0) {
              Object.keys(itm).map((col, ii) => {
                if (
                  col !== "Col0" &&
                  col !== "Col1" &&
                  col !== "Col2" &&
                  col !== "Col3"
                  //  &&
                  // col !== "Col4"
                ) {
                  // formData.append(`lstPropertiesRoomMeeting[${indexPosition}].roomName`, itm.Col0);
                  formData.append(`lstPropertiesRoomMeeting[${indexPosition}].roomName`, itm.Col0);
                  formData.append(`lstPropertiesRoomMeeting[${indexPosition}].squareFeet`, itm.Col2);
                  formData.append(
                    `lstPropertiesRoomMeeting[${indexPosition}].orderNo`,
                    indexPosition + 1
                  );
                  formData.append(`lstPropertiesRoomMeeting[${indexPosition}].rowNo`, i);
                  formData.append(`lstPropertiesRoomMeeting[${indexPosition}].columnNo`, ii);
                  formData.append(`lstPropertiesRoomMeeting[${indexPosition}].roomSetup`, itm[col]);
                  formData.append(
                    `lstPropertiesRoomMeeting[${indexPosition}].roomSetupTitle`,
                    MeetingRoomTable[0][col]
                  );
                  formData.append(
                    `lstPropertiesRoomMeeting[${indexPosition}].parentmeetingroom`,
                    itm.Col1
                  );


                  itm.Col3 &&
                    itm.Col3.forEach((subitem, ii) => {
                      formData.append(
                        `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].taxTitle`,
                        subitem.taxTitle === undefined ? 0 : subitem.taxTitle
                      );
                      formData.append(
                        `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].isPercentage`,
                        subitem.isPercentage === undefined ? false : subitem.isPercentage
                      );
                      formData.append(
                        `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].rateOrPercentage`,
                        subitem.rateOrPercentage === undefined
                          ? 0
                          : subitem.rateOrPercentage === ""
                            ? "0"
                            : subitem.rateOrPercentage
                      );
                      formData.append(
                        `lstPropertiesRoomMeeting[${indexPosition}].lstRoomMeetingTaxes[${ii}].orderNo`,
                        subitem.orderNo === undefined ? 0 : subitem.orderNo
                      );
                    });
                  indexPosition++;
                }
              });
            }
          });
          // DataList.forEach((itm, i) => {
          //   // formData.append(`lstPropertiesRoomMeeting[${i}].meetingRoomID`, itm.meetingRoomID === undefined ? "00000000-0000-0000-0000-000000000000" : itm.meetingRoomID);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].roomName`, itm.roomName);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].squareFeet`, itm.squareFeet);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].roomSetup`, itm.roomSetup !== undefined && itm.roomSetup !== null ? itm.roomSetup : 0);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].orderNo`, itm.orderNo === undefined ? 0 : itm.orderNo);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].rowNo`, itm.rowNo === undefined ? 0 : itm.rowNo);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].roomSetupTitle`, itm.roomSetupTitle);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].parentmeetingroom`, itm.parentmeetingroom);
          //   formData.append(`lstPropertiesRoomMeeting[${i}].columnNo`, itm.columnNo === undefined ? 0 : itm.columnNo);
          //   itm.lstRoomMeetingTaxes &&
          //     itm.lstRoomMeetingTaxes.forEach((subitem, ii) => {
          //       formData.append(
          //         `lstPropertiesRoomMeeting[${i}].lstRoomMeetingTaxes[${ii}].taxTitle`,
          //         subitem.taxTitle === undefined ? 0 : subitem.taxTitle
          //       );
          //       formData.append(
          //         `lstPropertiesRoomMeeting[${i}].lstRoomMeetingTaxes[${ii}].isPercentage`,
          //         subitem.isPercentage === undefined ? false : subitem.isPercentage
          //       );
          //       formData.append(
          //         `lstPropertiesRoomMeeting[${i}].lstRoomMeetingTaxes[${ii}].rateOrPercentage`,
          //         subitem.rateOrPercentage === undefined ? 0 : subitem.rateOrPercentage === "" ? "0" : subitem.rateOrPercentage
          //       );
          //       formData.append(
          //         `lstPropertiesRoomMeeting[${i}].lstRoomMeetingTaxes[${ii}].orderNo`,
          //         subitem.orderNo === undefined ? 0 : subitem.orderNo
          //       );
          //     });
          // });
          let xyz = 0;
          // for (let x = 0; x < inputList.length; x++) {

          //   RoomTypeTaxList.push(JSON.parse(
          //     document
          //       .getElementById("lstRoomTypeTaxes" + x)
          //       .getAttribute("data-lstRoomTypeTaxes")
          //   ))
          // }

          inputList.forEach((itma: any, i) => {
            // formData.append(`lstPropertiesRoomTypes[${i}].roomTypeId`, itma.roomTypeId);
            formData.append(`lstPropertiesRoomTypes[${i}].roomType`, itma.roomType);
            formData.append(`lstPropertiesRoomTypes[${i}].quantity`, itma.quantity === undefined ? 0 : itma.quantity);
            formData.append(`lstPropertiesRoomTypes[${i}].orderNo`, itma.orderNo === undefined ? 0 : xyz);
            // RoomTypeTaxList[0] !== false &&
            //   RoomTypeTaxList !== null &&
            //   RoomTypeTaxList !== undefined &&
            //   RoomTypeTaxList.length !== 0 &&
            //   RoomTypeTaxList[i].forEach((subitem, ii) => {

            //     formData.append(
            //       `lstPropertiesRoomTypes[${i}].lstRoomTypeTaxes[${ii}].taxTitle`,
            //       subitem.taxTitle === undefined ? 0 : subitem.taxTitle
            //     );
            //     formData.append(
            //       `lstPropertiesRoomTypes[${i}].lstRoomTypeTaxes[${ii}].isPercentage`,
            //       subitem.isPercentage === undefined ? false : subitem.isPercentage
            //     );
            //     formData.append(
            //       `lstPropertiesRoomTypes[${i}].lstRoomTypeTaxes[${ii}].rateOrPercentage`,
            //       subitem.rateOrPercentage === undefined ? 0 : subitem.rateOrPercentage === "" ? "0" : subitem.rateOrPercentage
            //     );
            //     formData.append(
            //       `lstPropertiesRoomTypes[${i}].lstRoomTypeTaxes[${ii}].orderNo`,
            //       subitem.orderNo === undefined ? 0 : subitem.orderNo
            //     );
            //   });
            xyz = xyz + 1;
          });

          formData.append("region", "");
          formData.append("area", "");
          formData.append("primaryWebURL", "");
          formData.append("secondaryWebURL", "");
          formData.append("aboutProperty", "");
          formData.append("userInputGDUrl", "");
          formData.append("companySetRateGDUrl", "");
          formData.append("territeryID", "00000000-0000-0000-0000-000000000000");
          formData.append("noOfRooms", 0);
          formData.append("isOverBookedAllowed", true);
          formData.append("overBookedPercentage", 0);
          formData.append("isEnable", true);
          formData.append("isActive", true);
          formData.append("isBlock", false);
          formData.append("updateBy", "00000000-0000-0000-0000-000000000000");
          formData.append("bankName", "");
          formData.append("bankAccountNo", "");
          formData.append("bankContact", "");
          formData.append("bankCity", "");
          formData.append("bankState", "");
          formData.append("bankFax", "");
          formData.append("bankZipCode", "");
          formData.append("sortCode", "");
          formData.append("primaryLastName", "");

          formData.append("primaryFirstName", "");
          formData.append("secondaryLastName", "");
          formData.append("salesLevel", "");
          // formData.append("ownerExt", "");
          // formData.append("gmExt", "");
          // formData.append("regOpeExt", "");
          // formData.append("regSlsExt", "");
          formData.append("autoActivities", true);
          // formData.append("DirectorExt", "");
          // formData.append("SalesaManagerExt", "");
          // formData.append("CateringMangerExt", "");
          formData.append("Rate", PropertySave.HRate);

          // tab AV
          // formData.append("listAvEquipmentANDMISC", tempAv);

          tempAv.forEach((itma, i) => {
            formData.append(`listAvEquipmentANDMISC[${i}].avEquipName`, itma.avEquipName);
            formData.append(
              `listAvEquipmentANDMISC[${i}].avEquipType_Term`, itma.avEquipType_Term
            );
            formData.append(
              `listAvEquipmentANDMISC[${i}].avEquipCost`, itma.avEquipCost
            );
            formData.append(
              `listAvEquipmentANDMISC[${i}].taxRatePerc`, itma.taxRatePerc
            );
            formData.append(
              `listAvEquipmentANDMISC[${i}].orderNO`, i
            );
          })

          // tab Food

          tempFood.forEach((itma, i) => {
            formData.append(`listFood[${i}].description`, itma.description);
            formData.append(
              `listFood[${i}].itemCostPerUnit`, itma.itemCostPerUnit
            );
            formData.append(
              `listFood[${i}].itemName`, itma.itemName
            );
            formData.append(
              `listFood[${i}].itemType_Term`, itma.itemType_Term
            );
            formData.append(
              `listFood[${i}].orderNO`, itma.orderNo
            );
            formData.append(
              `listFood[${i}].taxRatePerc`, itma.taxRatePerc
            );
          })


          // formData.append("listFood", tempFood);

          // tab Beverage


          tempBeverage.forEach((itma, i) => {
            formData.append(`listBeverages[${i}].itemName`, itma.itemName);
            formData.append(
              `listBeverages[${i}].itemType_Term`, itma.itemType_Term
            );
            formData.append(
              `listBeverages[${i}].itemCostPerUnit`, itma.itemCostPerUnit
            );
            formData.append(
              `listBeverages[${i}].taxRatePerc`, itma.taxRatePerc
            );
            formData.append(
              `listBeverages[${i}].orderNo`, itma.orderNo
            );
            formData.append(
              `listBeverages[${i}].description`, itma.description
            );
          })
          // Competitor Hotel_Tab Data :
          tempMap.forEach((itm, i) => {
            formData.append(`lstPropertyCompSetHotels[${i}].clientID`, itm.ClientID !== null ? itm.ClientID : null);
            formData.append(`lstPropertyCompSetHotels[${i}].hotelID`, itm.HotelID !== "" ? itm.HotelID : "00000000-0000-0000-0000-000000000000");
            formData.append(`lstPropertyCompSetHotels[${i}].compHotelID`, itm.CompHotelID !== "00000000-0000-0000-0000-000000000000" ? itm.CompHotelID : "00000000-0000-0000-0000-000000000000");
            formData.append(`lstPropertyCompSetHotels[${i}].hotelName`, itm.HotelName !== null ? itm.HotelName : "");
            formData.append(`lstPropertyCompSetHotels[${i}].hotelCode`, itm.HotelCode !== null ? itm.HotelCode : "");
            formData.append(`lstPropertyCompSetHotels[${i}].brandName`, itm.BrandName !== null ? itm.BrandName : "");
            formData.append(`lstPropertyCompSetHotels[${i}].address`, itm.Address !== null ? itm.Address : "");
            formData.append(`lstPropertyCompSetHotels[${i}].rateShopURL`, itm.RateShopURL !== null ? itm.RateShopURL : "");
            formData.append(`lstPropertyCompSetHotels[${i}].websiteURL`, itm.WebsiteURL !== null ? itm.WebsiteURL : "");
            formData.append(`lstPropertyCompSetHotels[${i}].hotelLogoURL`, "");// itm !== null ? itm.ClientID : "".HotelLogoURL);
            formData.append(`lstPropertyCompSetHotels[${i}].hotelLogo`, itm.HotelLogo !== null ? itm.HotelLogo : "");
            formData.append(`lstPropertyCompSetHotels[${i}].orderNo`, itm.OrderNo !== null ? itm.OrderNo : "");
          });

          let res: any = [];

          if (PropertySave.HPropertyID !== null && PropertySave.HPropertyID !== undefined && PropertySave.HPropertyID !== "" && PropertySave.HPropertyID !== "00000000-0000-0000-0000-000000000000"
          ) {
            setOnProcessHide(true)

            res = await UpdateProperties(formData, Token);
            // UploadLogo(res.data.data)

            setOnProcessHide(false)
            if (res.data.success) {
              if (res.data !== undefined) {
                if (res.data.statusCode === 200) {
                  toast.success("Properties Updated SuccessFully", {
                    theme: "colored",
                  });
                  if (props) {
                    props.getPropertiesListData(ClientID)
                  }
                  handleClose();
                }
                else {
                  toast.error(res.message, {
                    theme: "colored",
                  });
                }
              }
            }
          }
          else {
            // Add
            setOnProcessHide(true)
            res = await InsertProperties(formData, Token);

            setOnProcessHide(false)
            if (res.data.success) {
              if (res.data !== undefined) {
                if (res.data.statusCode === 200) {
                  // UploadLogo(res.data.data)
                  toast.success("Properties Inserted SuccessFully", {
                    theme: "colored",
                  });
                  if (props) {
                    props.getPropertiesListData()
                  }
                  handleClose();
                }
                else {
                  toast.error(res.message, {
                    theme: "colored",
                  });
                }
              }
            }
            else {
              toast.error(res.data.message, {
                theme: "colored",
              });

            }
          }
        }
        else {
          var result = Object.keys(obj.obj.errors).find(key => (obj.obj.errors[key] !== '' ||
            obj.obj.errors[key] === null));

          if (result?.startsWith('H')) {
            setAddPropertyActiveKey(1);
          }
          else if (result?.startsWith('O')) {
            setAddPropertyActiveKey(2);
          }
          else if (result?.startsWith('A')) {
            setAddPropertyActiveKey(3);
          }
          else if (result?.startsWith('M')) {
            setAddPropertyActiveKey(4);
          }
        }
      } else {
        setAddPropertyActiveKey(4);
      }
    }

  }


  //#region Room Type Row Add Start
  const handleInputRoomChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };


  const handleAddROOMRowClick = () => {

    setInputList([
      ...inputList,
      {
        roomType: "",
        quantity: "",
        hotelID: "00000000-0000-0000-0000-000000000000",
        orderNo: 0,
        lstRoomTypeTaxes: [],
      },
    ]);
  };
  //#endregion




  const handleClose = () => {
    // if (props.EditPropertiesData !== undefined && props.EditPropertiesData.properties) {
    // props.EditPropertiesData.properties[0] = [];
    // } else {
    setLogoUpload(null)
    setPropertySave({
      ...PropertySave,
      HChain: "",
      HTARGETBAR: "",
      HSecondaryName: "",
      HTotalRoom: "",
      HPropertyCode: "",
      HPropertyID: "00000000-0000-0000-0000-000000000000",
      HHotelCode: "",
      HPortFolio: "",
      HPortFolioName: "",
      HClientID: "",
      HTARGETLNR: "",
      HDUNSNumber: "",
      HFEMANumber: "",
      // HWebsiteLogIn: "",
      // HLogin: "",
      // HLoginPass: "",
      // HCVENTLogin: "",
      // HCVENTPassword: "",
      HBrandWebsite: "",
      HBrandLogin: "",
      HBrandPassword: "",
      HPlanLogin: "",
      HPlanPasword: "",
      HStatus: "Active",
      HAssignUser: "",
      HLogo: "",
      HRFPSystem: "",
      HRFPSystemID: "",
      HpaymentInfoID: "",

      OOWName: "",
      OOWPhone: "",
      OOWEmail: "",
      OGMName: "",
      OGMPhone: "",
      OGMEmail: "",
      ODSName: "",
      ODSPhone: "",
      ODSEmail: "",
      OCMName: "",
      OCMPhone: "",
      OCMEmail: "",
      OSMName: "",
      OSMPhone: "",
      OSMEmail: "",
      ORMName: "",
      ORMPhone: "",
      ORMEmail: "",
      OREName: "",
      OREEmail: "",
      OREPhone: "",
      ORSName: "",
      ORSEmail: "",
      ORSPhone: "",
      AStreet: "",
      AState: "",
      ACity: "",
      ACountry: "",
      AZipCode: "",
      APhoneno: "",
      AReportEmail: "",
      MServiceCharge: "",
      MGratuity: "",
      MTaxRate: "",
    })
    // }
    props.onHide(false);
  }

  function TabNextPrevious(Type) {
    if (Type.toUpperCase() === "PREVIOUS") {
      if (AddPropertyActiveKey > 1) {
        setAddPropertyActiveKey(AddPropertyActiveKey - 1);
      }
    }
    else if (Type.toUpperCase() === "NEXT") {
      if (AddPropertyActiveKey < 10) {
        setAddPropertyActiveKey(AddPropertyActiveKey + 1);
      }
    }
  }

  async function SelectPaymentInfo(event) {
    if (event.target.value !== "") {
      let input = {
        clientID: ClientID,
        paymentInfoID: event.target.value,
      }
      setPropertySave({
        ...PropertySave,
        HpaymentInfoID: event.target.value ? event.target.value : "",
      })

      if (event.target.value) {
        const res: any = await PropertiesPaymentInfoList(input, Token);
        if (res.data.success) {
          if (res.data !== null && res.data !== undefined) {
            if (res.data.data !== null && res.data.data !== undefined) {
              if (res.data.data.clientPaymentInfo !== null && res.data.data.clientPaymentInfo !== undefined && res.data.data.clientPaymentInfo.length !== 0) {
                setPaymentInfo(res.data.data.clientPaymentInfo[0]);
              }
            }
          }
        }
      }


    }
    else {
      setPaymentInfo({
        ...PaymentInfo,
        ["invoiceContactName"]: "",
        ["invoiceContactEmail"]: "",
        ["invoiceContactPhone"]: "",
        ["paymentOption"]: "",
      })

      setPropertySave({
        ...PropertySave,
        HpaymentInfoID: event.target.value ? event.target.value : "",
      })
    }
  }

  //#region MeetingRoom Type Column Add Start


  //#endregion

  // Upload LOGO :
  const [Logo, setLogo]: any = React.useState({
    name: "",
    link: [],
    errors: "",
  });

  const [LogoUpload, setLogoUpload]: any = React.useState(null);

  function SelectLogo(e: any) {
    setLogo({ ...Logo, ["link"]: e.target.files })
    const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
    setLogoUpload(fileLoaded);
  }


  async function UploadLogo(propertyID: any) {

    let formData: any = new FormData();
    formData.append("PropertyID", propertyID);
    formData.append("ClientID", ClientID);
    formData.append("PropertyLogoFile", Logo.link[0]);
    formData.append("PropertyLogoFilePath", "");
    await UpdatePropertyLogo(formData, Token)
  }

  function handleOnSelect(event) {

    if (event !== null) {
      setPropertySave({
        ...PropertySave,
        ["HChain"]: event.value,
      });
    }
    else {
      setPropertySave({
        ...PropertySave,
        ["HChain"]: "",
      });
    }

  }

  function ColumnExistORNot(obj, NextColNo) {
    if (obj.hasOwnProperty("Col" + NextColNo)) {
      NextColNo = NextColNo + 1;
      NextColNo = ColumnExistORNot(obj, NextColNo);
    }
    return NextColNo;
  }
  function RemoveRoomTypesRow(Id: any) {

    const list = [...inputList];
    if (Id !== null && Id !== "" && Id !== undefined) {
      list.splice(Id, 1);
    }

    setInputList(list);
  }
  async function MeetingRoomTable_AddNewCol() {
    let lst = [...MeetingRoomTable];
    let NextColNo = Object.keys(lst[0]).length;
    NextColNo = ColumnExistORNot(lst[0], NextColNo);
    for (let i = 0; i < lst.length; i++) {
      if (i === 0) {
        lst[i]["Col" + NextColNo] = "";
      } else {
        lst[i]["Col" + NextColNo] = 0;
      }
    }
    setMeetingRoomTable(lst);
  }

  function MeetingRoomTable_AddNewRow() {

    let lst = [...MeetingRoomTable];
    let obj = {};
    Object.keys(lst[0]).map((item) => {
      if (item === "Col0" || item === "Col1") {
        obj[item] = ""
      }

      else {
        obj[item] = 0;
      }
    });
    lst.push(obj);
    setMeetingRoomTable(lst);
  }

  function MeetingRoomTable_RemoveRow(Row, RowIndex) {
    let lst = [...MeetingRoomTable];
    lst.splice(RowIndex, 1);
    setMeetingRoomTable(lst);
  }
  function MeetingRoomTable_RemoveColumn(Row) {
    let lst = [...MeetingRoomTable];


    for (let index = 0; index < lst.length; index++) {

      delete lst[index][Row];
    }
    setMeetingRoomTable(lst);
  }
  function SelectedAssociationRoom(event: any, RowIndex: any, Col: any) {

    let listAssociationPattern: any = [];
    for (let index = 0; index < event.length; index++) {
      listAssociationPattern.push(event[index].value);
    }

    let lst: any = [...MeetingRoomTable];
    lst[RowIndex][Col] = listAssociationPattern.toString();
    setMeetingRoomTable(lst);
  }
  //tAX sETUP
  function handleInputRoomChangeTab(event, index) {
    const list: any = [...inputTaxHotelList];
    const list1: any = [...inputMeetingRoomTaxList111];
    const list2: any = [...inputGuestRoomTaxList];
    const list3: any = [...inputAVEquipmentTaxList];
    const list4: any = [...inputFoodTaxList];
    const list5: any = [...inputBeverageTaxList];


    debugger
    for (let i = 0; i < list.length; i++) {
      if (event !== null) {
        if (list[i].TaxTitle === event.label) {
          toast.error("Selected Value already exist...! ", {
            theme: "colored",
          });
          return;
        }
      }
    }

    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }

    if (event === null) {
      list[index]["TaxTitle"] = "";
      list1[index]["TaxTitle"] = "";
      list2[index]["TaxTitle"] = "";
      list3[index]["TaxTitle"] = "";
      list4[index]["TaxTitle"] = "";
      list5[index]["TaxTitle"] = "";
    } else if (event.node === "Creatable") {
      let value = event.value.trim();
      list[index]["TaxTitle"] = value;
      list1[index]["TaxTitle"] = value;
      list2[index]["TaxTitle"] = value;
      list3[index]["TaxTitle"] = value;
      list4[index]["TaxTitle"] = value;
      list5[index]["TaxTitle"] = value;
    } else if (event.__isNew__) {
      let value = event.value.trim();
      list[index]["TaxTitle"] = value;
      list1[index]["TaxTitle"] = value;
      list2[index]["TaxTitle"] = value;
      list3[index]["TaxTitle"] = value;
      list4[index]["TaxTitle"] = value;
      list5[index]["TaxTitle"] = value;
    } else if (
      event.target &&
      event.target.nodeName !== undefined &&
      event.target.nodeName === "SELECT"
    ) {
      if (event.target.value === "Yes") {
        list[index]["IsPercentage"] = true;
        list1[index]["IsPercentage"] = true;
        list2[index]["IsPercentage"] = true;
        list3[index]["IsPercentage"] = true;
        list4[index]["IsPercentage"] = true;
        list5[index]["IsPercentage"] = true;
      } else {
        list[index]["IsPercentage"] = false;
        list1[index]["IsPercentage"] = false;
        list2[index]["IsPercentage"] = false;
        list3[index]["IsPercentage"] = false;
        list4[index]["IsPercentage"] = false;
        list5[index]["IsPercentage"] = false;
      }
    } else {
      if (event.value !== null) {
        let { name, value }: any = event.target;
        value = value.trim();
        list[index][name] = value;
        list1[index][name] = value;
        list2[index][name] = value;
        list3[index][name] = value;
        list4[index][name] = value;
        list5[index][name] = value;
        if (value) {
          let pattern: any = /^\d+(\.)?\d*%?$/;
          if (pattern.test(value)) {
            if (value !== null && value !== "" && value !== undefined) {
              if (value.includes("%")) {
                list[index]["IsPercentage"] = true;
                list[index]["RateOrPercentage"] = value ? value : "";

                list1[index]["IsPercentage"] = true;
                list1[index]["RateOrPercentage"] = value ? value : "";

                list2[index]["IsPercentage"] = true;
                list2[index]["RateOrPercentage"] = value ? value : "";

                list3[index]["IsPercentage"] = true;
                list3[index]["RateOrPercentage"] = value ? value : "";

                list4[index]["IsPercentage"] = true;
                list4[index]["RateOrPercentage"] = value ? value : "";

                list5[index]["IsPercentage"] = true;
                list5[index]["RateOrPercentage"] = value ? value : "";
              }
            }
            list[index]["taxRowNo"] = props.TaxRowNo;
            list[index]["OrderNo"] = index;
            list1[index]["taxRowNo"] = props.TaxRowNo;
            list1[index]["OrderNo"] = index;
            list2[index]["taxRowNo"] = props.TaxRowNo;
            list2[index]["OrderNo"] = index;
            list3[index]["taxRowNo"] = props.TaxRowNo;
            list3[index]["OrderNo"] = index;
            list4[index]["taxRowNo"] = props.TaxRowNo;
            list4[index]["OrderNo"] = index;
            list5[index]["taxRowNo"] = props.TaxRowNo;
            list5[index]["OrderNo"] = index;
            setIsvalidState({ status: false, Value: "" });
          } else {
            setIsvalidState({ status: true, Value: value });
            list[index][name] = "";
            list1[index][name] = "";
            list2[index][name] = "";
            list3[index][name] = "";
            list4[index][name] = "";
            list5[index][name] = "";
          }
        } else {
          list[index][name] = value;
          list1[index][name] = value;
          list2[index][name] = value;
          list3[index][name] = value;
          list4[index][name] = value;
          list5[index][name] = value;
        }
      }
    }

    setInputTaxHotelList(list);
    setInputMeetingRoomTaxList111(list1);
    setInputGuestRoomTaxList(list2);
    setInputAVEquipmentTaxList(list3);
    setInputFoodTaxList(list4);
    setInputBeverageTaxList(list5);
  }
  const handleAddROOMRowClickTab = () => {
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }
    setInputTaxHotelList([
      ...inputTaxHotelList,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputTaxHotelList.length, IsPercentage: true, taxRowNo: 0 },
    ]);
    setInputMeetingRoomTaxList111([
      ...inputMeetingRoomTaxList111,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputMeetingRoomTaxList111.length, IsPercentage: true, taxRowNo: 0 },
    ]);

    setInputGuestRoomTaxList([
      ...inputGuestRoomTaxList,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputGuestRoomTaxList.length, IsPercentage: true, taxRowNo: 0 },
    ]);
    setInputAVEquipmentTaxList([
      ...inputAVEquipmentTaxList,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputAVEquipmentTaxList.length, IsPercentage: true, taxRowNo: 0 },
    ]);
    setInputFoodTaxList([
      ...inputFoodTaxList,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputFoodTaxList.length, IsPercentage: true, taxRowNo: 0 },
    ]);
    setInputBeverageTaxList([
      ...inputBeverageTaxList,
      { TaxTitle: "", RateOrPercentage: "", OrderNo: inputBeverageTaxList.length, IsPercentage: true, taxRowNo: 0 },
    ]);

    setInputTaxHotelListEr([...inputTaxHotelListEr, { TaxTitle: "", RateOrPercentage: "" },]);
  };
  function CheckKeyEvent(event) {
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46 ||
      event.charCode === 37
    ) {
      if (event.charCode === 46) {
        if (event.target.value.indexOf(".") >= 0) {
          event.preventDefault();
        }
      }

      if (event.charCode === 37) {
        if (event.target.value.indexOf("%") >= 0) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }
  const validateForm = () => {
    let is_validate = true;
    let newInputEr: any = [...inputTaxHotelListEr];
    inputTaxHotelList.forEach((itm, i) => {
      Object.keys(validationJson).forEach((key) => {
        if ((!itm[key] && parseInt(itm[key]) !== 0) || itm[key] === "") {
          is_validate = false;
          newInputEr[i][key] = "This field is required.";
          setValidTaxRoomTab(true);
        } else {
          newInputEr[i][key] = "";
          setValidTaxRoomTab(false);
        }
      });
    });
    setInputTaxHotelListEr(newInputEr);
    return is_validate;
  };

  function RemoveTaxRow(id: any) {

    const list = [...inputTaxHotelList];
    const list1 = [...inputMeetingRoomTaxList111];
    const list2 = [...inputGuestRoomTaxList];
    const list3 = [...inputAVEquipmentTaxList];
    const list4 = [...inputFoodTaxList];
    const list5 = [...inputBeverageTaxList];

    const listER = [...inputTaxHotelListEr];
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      setPropertySave({
        ...PropertySave,
        ["IsTaxChange"]: true,
      });
    }

    if (id !== null && id !== "" && id !== undefined) {
      list.splice(id, 1);
      list1.splice(id, 1);
      list2.splice(id, 1);
      list3.splice(id, 1);
      list4.splice(id, 1);
      list5.splice(id, 1);
      listER.splice(id, 1);
    }


    setInputTaxHotelList(list);
    setInputMeetingRoomTaxList111(list1);
    setInputGuestRoomTaxList(list2);
    setInputAVEquipmentTaxList(list3);
    setInputFoodTaxList(list4);
    setInputBeverageTaxList(list5);
    setInputTaxHotelListEr(listER);
  }
  function MeetingRoomTaxCheckboxOnchange(event, item, index) {

    if (event.target.checked === true) {
      const listMeeting: any = [...inputMeetingRoomTaxList111];
      listMeeting[index].IsApply = true;
      setInputMeetingRoomTaxList111(listMeeting);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const Meetinglist: any = [...inputMeetingRoomTaxList111];
      Meetinglist[index].IsApply = false;
      setInputMeetingRoomTaxList111(Meetinglist);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }

  function GuestRoomTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list1: any = [...inputGuestRoomTaxList];
      list1[index].IsApply = true;
      setInputGuestRoomTaxList(list1);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list: any = [...inputGuestRoomTaxList];
      list[index].IsApply = false;
      setInputGuestRoomTaxList(list);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function AVEquipmentTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list2: any = [...inputAVEquipmentTaxList];
      list2[index].IsApply = true;
      setInputAVEquipmentTaxList(list2);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const lis21: any = [...inputAVEquipmentTaxList];
      lis21[index].IsApply = false;
      setInputAVEquipmentTaxList(lis21);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function FoodTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list11: any = [...inputFoodTaxList];
      list11[index].IsApply = true;
      setInputFoodTaxList(list11);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list12: any = [...inputFoodTaxList];
      list12[index].IsApply = false;
      setInputFoodTaxList(list12);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }
  function BeverageRoomTaxCheckboxOnchange(e, item, index) {
    if (e.target.checked === true) {
      const list31: any = [...inputBeverageTaxList];
      list31[index].IsApply = true;
      setInputBeverageTaxList(list31);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    } else {
      const list32: any = [...inputBeverageTaxList];
      list32[index].IsApply = false;
      setInputBeverageTaxList(list32);
      if (
        props.EditPropertiesID !== undefined &&
        props.EditPropertiesID !== null &&
        props.EditPropertiesID !== ""
      ) {
        setPropertySave({
          ...PropertySave,
          ["IsTaxChange"]: true,
        });
      }
    }
  }

  function GuestRoomTaxOnchange(e, item) {
    const NEwlist: any = [...inputGuestRoomTaxList];
    NEwlist[item.OrderNo].RateOrPercentage = e.target.value;
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist[item.OrderNo].IsTaxChange = true;
    }
    setInputGuestRoomTaxList(NEwlist);
  }
  function AVEquipmentRoomTaxOnchange(e, item) {
    const NEwlist1: any = [...inputAVEquipmentTaxList];
    NEwlist1[item.OrderNo].RateOrPercentage = e.target.value;
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist1[item.OrderNo].IsTaxChange = true;
    }
    setInputAVEquipmentTaxList(NEwlist1);
  }
  function FoodRoomTaxOnchange(e, item) {
    const NEwlist2: any = [...inputFoodTaxList];
    NEwlist2[item.OrderNo].RateOrPercentage = e.target.value;
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist2[item.OrderNo].IsTaxChange = true;
    }
    setInputFoodTaxList(NEwlist2);
  }
  function BeverageRoomTaxOnchange(e, item) {
    const NEwlist3: any = [...inputBeverageTaxList];
    NEwlist3[item.OrderNo].RateOrPercentage = e.target.value;
    if (
      props.EditPropertiesID !== undefined &&
      props.EditPropertiesID !== null &&
      props.EditPropertiesID !== ""
    ) {
      NEwlist3[item.OrderNo].IsTaxChange = true;
    }
    setInputBeverageTaxList(NEwlist3);
  }

  //#region  Av Equipment

  const [avCount, setAvCount] = useState(1);
  const [avDataRes, setAvDataRes] = useState({
    // 1: {
    //   avEquipName: "",
    //   avEquipType_Term: "",//"AvEquipment",
    //   avEquipCost: 0,
    //   taxRatePerc: 0,
    //   orderNO: 0,
    // },
  });
  const [avDataResEr, setAvDataResEr]: any = useState({
    // 1: {
    //   avEquipName: "",
    // },
  }); // Av Equipment Error

  const fetchAvData = async (prop_Id) => {
    let data = {
      clientID: ClientID,
      hotelID: prop_Id,
      isNew: true,
    };
    let res: any = await GetAvEquipmentsAndMISCList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSet: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {
            jsonToSet = {
              ...jsonToSet,
              [i]: {
                avEquipName: res_data_lst.AVEquipName ? res_data_lst.AVEquipName : "",
                avEquipType_Term: res_data_lst.AVEquipType_Term
                  ? res_data_lst.AVEquipType_Term
                  : "",
                avEquipCost: res_data_lst.AVEquipCost ? res_data_lst.AVEquipCost : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNO: res_data_lst.OrderNO ? res_data_lst.OrderNO : 0,
              },
            };
          });

          setAvCount(res.data.data.dataList1.length - 1);
          setAvDataRes(jsonToSet);
          // setAvLoading(false);
        } else {
          // setAvLoading(false);
        }
      } else {
        // setAvLoading(false);
      }
    } else {
      // setAvLoading(false);
    }
  };

  //#endregion


  //#region Food And Beverage :

  const [countFood, setCountFood] = useState(1);
  const [dataResFood, setDataResFood]: any = useState({
    // 1: {
    //   itemName: "",
    //   itemType_Term: "Food",
    //   itemCostPerUnit: 0,
    //   taxRatePerc: 0,
    //   orderNo: 0,
    //   description: "",
    // },
  });
  const [dataResFoodEr, setDataResFoodEr]: any = useState({
    // 1: {
    //   itemName: "",
    // },
  }); // Food Error

  const [countBeverage, setCountBeverage] = useState(1);
  const [dataResBeverage, setDataResBeverage]: any = useState({
    // 1: {
    //   itemName: "",
    //   itemType_Term: "Beverage",
    //   itemCostPerUnit: 0,
    //   taxRatePerc: 0,
    //   orderNo: 0,
    //   description: "",
    // },
  });
  const [dataResBeverageEr, setDataResBeverageEr]: any = useState({
    // 1: {
    //   itemName: "",
    // },
  }); // Beverage Error

  // const fetchFoodData = async (prop_Id) => {
  //   let data = {
  //     hotelID: prop_Id,
  //     isNew: true,
  //   };
  //   let res: any = await GetFoodAndBeveragesList(data, Token);
  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       if (res.data.data) {
  //         let jsonToSetBeverage: any = {};
  //         let jsonToSetFood: any = {};

  //         res.data.data.dataList1.forEach((res_data_lst, i) => {
  // if(res_data_lst.ItemType_Term === "Beverage") {
  //   jsonToSetBeverage = {
  //     ...jsonToSetBeverage,
  //     [res_data_lst.OrderNo]: {
  //       itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
  //       itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "Beverage",
  //       itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
  //       taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
  //       orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
  //       description: res_data_lst.Description ? res_data_lst.Description : "",
  //     },
  //   };
  // } else if(res_data_lst.ItemType_Term === "Food") {
  //   jsonToSetFood = {
  //     ...jsonToSetFood,
  //     [res_data_lst.OrderNo]: {
  //       itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
  //       itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "Food",
  //       itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
  //       taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
  //       orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
  //       description: res_data_lst.Description ? res_data_lst.Description : "",
  //     },
  //   };
  // }

  //         });

  //         setCountFood(Object.keys(jsonToSetFood).length)
  //          setDataResFood(jsonToSetFood)

  //          setCountBeverage(Object.keys(jsonToSetBeverage).length)
  //          setDataResBeverage(jsonToSetBeverage)



  //         // setDataRes(jsonToSet);
  //         // setCount(res.data.data.dataList1.length - 1);
  //         // setLoading(false);
  //       } else {
  //         // setLoading(false);
  //       }
  //     } else {
  //       // setLoading(false);
  //     }
  //   } else {
  //     // setLoading(false);
  //   }
  // };

  const fetchFoodData = async (property_id) => {
    let data = {
      clientID: ClientID,
      hotelID: property_id,
      isNew: true,
      itemType_Term: "Food"
    };
    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {

          let jsonToSet: any = {};

          res.data.data.dataList1.forEach((res_data_lst, i) => {
            // if(res_data_lst.ItemType_Term === "Food") {
            jsonToSet = {
              ...jsonToSet,
              [i]: {
                itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                description: res_data_lst.Description ? res_data_lst.Description : "",
              },
            };
            // } 
          });

          setCountFood(Object.keys(jsonToSet).length - 1)
          setDataResFood(jsonToSet)
          //  setDataRes(jsonToSet);
          // setCount(Object.keys(jsonToSet).length - 1);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchFoodBeverage = async (property_id) => {
    let data = {
      clientID: ClientID,
      hotelID: property_id,
      isNew: true,
      itemType_Term: "Beverage"
    };
    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {


          let jsonToSetBeverage: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {

            // if(res_data_lst.ItemType_Term === "Beverage") {
            jsonToSetBeverage = {
              ...jsonToSetBeverage,
              [i]: {
                itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                description: res_data_lst.Description ? res_data_lst.Description : "",
              },
            };
            // }
          });

          setDataResBeverage(jsonToSetBeverage)
          setCountBeverage(Object.keys(jsonToSetBeverage).length - 1)

          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  //#endregion


  //#region Validation Functions : Food, Av, Beverage ;


  const [first1, setfirst1] = useState(true)
  const [first2, setfirst2] = useState(true)
  const [first3, setfirst3] = useState(true)

  const validateFoodData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(dataResFoodEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataResFood).forEach((key_gr) => {
      if (dataResFood[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }


    });
    setDataResFoodEr(errosJson);
    setfirst1(isValidate)
    return isValidate;
  };

  const validateBeverageData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(dataResBeverageEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataResBeverage).forEach((key_gr) => {
      if (dataResBeverage[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }


    });
    setDataResBeverageEr(errosJson);
    setfirst2(isValidate)
    return isValidate;
  };

  const validAvEquipmentData = () => {
    let isValidate = true;
    let errosJson = {};

    Object.keys(avDataResEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          avEquipName: "",
          avEquipType_Term: "",
        },
      };
    });

    Object.keys(avDataRes).forEach((key_gr) => {
      if (avDataRes[key_gr].avEquipName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipName: "This field is required.",
          },
        };
        isValidate = false;
      }
      if (avDataRes[key_gr].avEquipType_Term === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipType_Term: "This field is required.",
          },
        };
        isValidate = false;
      }


    });
    setAvDataResEr(errosJson);
    setfirst3(isValidate);

    return isValidate;
  };

  //#endregion End Validation Function Scope ;

  //#region Competitor Tab Code
  // let page_rights_Hotel = CommonService.getPageRight("Hotels");
  let pageRights = GetPageRight('Hotels')

  let [CompetitorList, setCompetitorList]: any = React.useState({ IsData: true, data: [] });
  // let [CompetitorData, setCompetitorData]: any = React.useState([]);
  const [showModel, setShowModel] = React.useState(false);
  const [competitorID, setCompetitorID] = React.useState("");
  const [CompetitorEditData, setCompetitorEditData] = React.useState({});
  const [CompetitorLoader, setCompetitorLoader] = React.useState(false);

  // function DeleteCompetitor1(CompHotelID: any) {
  //   Swal.fire({
  //     title: "Are you sure want to delete ?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: "No",
  //     confirmButtonText: "Yes",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let input: any = {
  //         keyID: CompHotelID,
  //       };
  //       let res: any = await CompSetHotelsDelete(input, Token);
  //       if (res.data.success) {
  //         if (res.data !== undefined && res.data !== null && res.data !== "") {
  //           if (res.data.statusCode === 200) {
  //             toast.success("Competitor Hotel Successfully deleted .", {
  //               theme: "colored",
  //             });
  //             fetchCompetitorListData();
  //           }
  //         } else {
  //           toast.error(res.data.message, {
  //             theme: "colored",
  //           });
  //         }
  //       }
  //     }
  //   });
  // }

  const fetchCompetitorListData = async () => {
    let data = {
      clientID: ClientID,
      propertyID: props.EditPropertiesID,
    }

    setCompetitorList({ IsData: false, data: [] });
    let res: any = await CompSetHotelGetListByProperty(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {

            let tempData = [...res.data.data.dataList1];
            for (let index = 0; index < tempData.length; index++) {
              tempData[index]["id"] = Math.floor(Math.random() * 999999 + 1);
            }
            setTempMap(tempData);

            setCompetitorList({ IsData: true, data: res.data.data.dataList1 });
          } else {
            setCompetitorList({ IsData: true, data: [] });
          }
        } else {
          setCompetitorList({ IsData: true, data: [] });
        }
      } else {
        setCompetitorList({ IsData: true, data: [] });
      }
    } else {
      setCompetitorList({ IsData: true, data: [] });
    }
  };
  //#endregion Competitor End :

  const [tempMap, setTempMap]: any = useState([]);

  function DeleteCompetitorNew(itmIndex) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        debugger
        let tempCompList: any = [...tempMap];
        let index = tempCompList.findIndex((item) => item.id === itmIndex);
        if (index !== null && index >= 0) {
          tempCompList.splice(index, 1);
        }
        setTempMap(tempCompList);
        toast.success("Competitor Hotel Successfully deleted .", {
          theme: "colored",
        });
      }
    });
    // let tempCompList: any = [...tempMap];
    // let index = tempCompList.findIndex((item) => item.id === itmIndex);
    // if (index !== null && index >= 0) {
    //   tempCompList.splice(index, 1);
    // }
    // setTempMap(tempCompList);
  }

  function DeleteCompetitorExist(itm: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          compHotelID: itm.CompHotelID,
          clientID: ClientID,
        }
        debugger
        let res: any = await CompSetHotelsDeleteByProperty(input, Token);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
              debugger
              let tempCompList: any = [...tempMap];
              let index = tempCompList.findIndex((item) => item.id === itm.id);
              if (index !== null && index >= 0) {
                tempCompList.splice(index, 1);
              }
              setTempMap(tempCompList);

              toast.success("Competitor Hotel Successfully deleted .", {
                theme: "colored",
              });
              // fetchCompetitorListData();
            }
          } else {
            toast.error(res.data.message, {
              theme: "colored",
            });
          }
        }
      }
    });
  }

  function fnReturnData(data) {
    let lstData = [...tempMap];
    if (data.id === 0) {
      data.id = Math.floor(Math.random() * 999999 + 1);
      lstData.push(data);
    } else {
      let index = lstData.findIndex((item) => item.id === data.id);
      if (index !== null && index >= 0) {
        lstData[index] = data;
      }
    }
    setTempMap(lstData);
  }

  return (
    <>
      <Modal
        {...props}
        className="clientpoupadd fixpopupheight"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {PropertySave.HPropertyID !== "00000000-0000-0000-0000-000000000000" && PropertySave.HPropertyID !== null && PropertySave.HPropertyID !== undefined && PropertySave.HPropertyID !== "" ?
            <Modal.Title id="contained-modal-title-vcenter">Edit Hotel</Modal.Title> :
            <Modal.Title id="contained-modal-title-vcenter">Add Hotel</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <Form action="javascript;">
            <div className="row customtabwala">
              <div className="col-md-12">
                <Tabs
                  //defaultActiveKey={"tab1" + AddPropertyActiveKey}
                  activeKey={"tab" + AddPropertyActiveKey}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(key) => setAddPropertyActiveKey(key === null ? 1 : parseInt(key.replace("tab", "")))}
                >
                  <Tab eventKey="tab1" title="Hotel"
                    tabClassName={`${PropertySave.errors.HChain
                      ? "validate-tab"
                      : PropertySave.errors.HPropertyName
                        ? "validate-tab"
                        : PropertySave.errors.HTotalRoom
                          ? "validate-tab"
                          : PropertySave.errors.HSecondaryName
                            ? "validate-tab"
                            : PropertySave.errors.HPropertyCode
                              ? "validate-tab"
                              : PropertySave.errors.HPortFolio
                                ? "validate-tab"
                                : PropertySave.errors.HRate ? "validate-tab" : ""
                      }`}
                  >
                    {!loading ? (<>

                      <div className="row mb-3">
                        <div className="col-md-12">
                          <h2 className="paymenttitle">Hotel Information</h2>
                        </div>
                        <div className="col-md-12">
                          <Form.Group>
                            <Form.Label>Hotel Name* </Form.Label>
                            <Form.Control type="text" placeholder='Hotel Name' maxLength={256} value={PropertySave.HPropertyName} onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HPropertyName"]: e.target.value,
                              })
                            }
                              isInvalid={PropertySave.errors.HPropertyName}
                            />
                            {PropertySave.errors.HPropertyName && (
                              <Form.Control.Feedback type="invalid">
                                {PropertySave.errors.HPropertyName}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>   <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Select Client</Form.Label>
                            <Form.Control type="text" maxLength={256} name={ClientLabel} value={ClientLabel} disabled />
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <div className="creatableSelect-properties">
                            <>
                              <Form.Label>Chain* </Form.Label>
                              <div className={`${PropertySave.errors.HChain && "red-border-masking w-100"}`}>
                                {PropertySave.HChain !== "" ? (
                                  <CreatableSelect
                                    isClearable
                                    className="creatableSelect-properties w-100"
                                    options={items}
                                    onChange={(event: any) => {
                                      handleOnSelect(event);
                                    }}
                                    placeholder="Chain*"
                                    value={{ label: PropertySave.HChain, value: PropertySave.HChain }}
                                  />
                                ) : (
                                  <CreatableSelect
                                    isClearable
                                    options={items}
                                    onChange={(event: any) => {
                                      handleOnSelect(event);
                                    }}
                                    placeholder="Chain*"
                                    className="creatableSelect-properties w-100"
                                  />
                                )}
                              </div>
                            </>
                            <small style={{ color: "#dc3545" }}>
                              {PropertySave.errors.HChain && PropertySave.errors.HChain}{" "}
                            </small>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Total Rooms</Form.Label>
                            <Form.Control type="number" placeholder="Total Rooms"
                              onWheel={(event: any) => { event.target.blur() }}
                              onKeyDown={(e: any) => {
                                if (e.keyCode == '38' || e.keyCode == '40') {
                                  e.stopPropagation()
                                  e.preventDefault()
                                }
                              }}
                              value={PropertySave.HTotalRoom}
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HTotalRoom"]: e.target.value,
                                })
                              }
                              isInvalid={PropertySave.errors.HTotalRoom}
                            />
                            {PropertySave.errors.HTotalRoom && (
                              <Form.Control.Feedback type="invalid">
                                {PropertySave.errors.HTotalRoom}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Secondary Name*</Form.Label>
                            <Form.Control type="text" maxLength={360}
                              value={PropertySave.HSecondaryName}
                              //defaultValue={PropertySave.HSecondaryName} 
                              placeholder="Secondary Name" onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HSecondaryName"]: e.target.value,
                                })
                              }
                              isInvalid={PropertySave.errors.HSecondaryName}
                            />
                            {PropertySave.errors.HSecondaryName && (
                              <Form.Control.Feedback type="invalid">
                                {PropertySave.errors.HSecondaryName}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Property Code*</Form.Label>
                            <Form.Control type="text" maxLength={15} defaultValue={PropertySave.HPropertyCode}
                              value={PropertySave.HPropertyCode} placeholder="Property Code" onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HPropertyCode"]: e.target.value,
                                })
                              }
                              isInvalid={PropertySave.errors.HPropertyCode}
                            />
                            {PropertySave.errors.HPropertyCode && (
                              <Form.Control.Feedback type="invalid">
                                {PropertySave.errors.HPropertyCode}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Hotel Code</Form.Label>
                            {/* <Form.Control
                            type="text" maxLength={15}

                            placeholder="Hotel Code"
                            value={PropertySave.HHotelCode}
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HHotelCode"]: e.target.value,
                              })
                            }
                          /> */}
                            <Form.Control
                              disabled
                              id="HotelCode_Value"
                              type="text"
                              maxLength={15}
                              placeholder="Hotel Code"
                              value={
                                PropertySave.HChain.split("-")[0].trim() + PropertySave.HPropertyCode
                              }
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HHotelCode"]: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 customselectmy">
                          <Form.Group>

                            <Form.Label>Portfolio* </Form.Label>
                            <div
                              className={`${PropertySave.errors.HPortFolio && "red-border-masking w-100"}`}
                            >
                              <Select
                                isInvalid={PropertySave.errors.HPortFolio}
                                placeholder="Select Portfolio"
                                // defaultValue={PropertySave.HPortFolio}
                                value={{ label: PropertySave.HPortFolioName || "Select Portfolio", value: PropertySave.HPortFolio }}
                                // className={`multiselect ${PropertySave.errors.HPortFolio && "is-invalid"}`}
                                className={`${PropertySave.errors.HPortFolio && "is-invalid"}`}
                                options={optionsPortFolio}
                                onChange={(e: any) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HPortFolio"]: e.value,
                                    ["HPortFolioName"]: e.label,
                                  })}
                              />
                            </div>
                            {PropertySave.errors.HPortFolio && (
                              <Form.Control.Feedback type="invalid">
                                {PropertySave.errors.HPortFolio}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <div key={`inline-radio`} className="mb-3">
                              <Form.Check
                                inline
                                label="Active"
                                value="Active"
                                name="group2"
                                type="radio"
                                id="inline-radio-1"
                                onChange={(e: any) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HStatus"]: e.target.value,
                                  })}
                                checked={PropertySave.HStatus !== "" && PropertySave.HStatus === "Active" ? true : false}
                                defaultChecked
                              />

                              <Form.Check
                                inline
                                label="Inactive"
                                name="group2"
                                value="Inactive"
                                type="radio"
                                id="inline-radio-2"
                                onChange={(e: any) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HStatus"]: e.target.value,
                                  })}
                                // defaultChecked={PropertySave.HStatus === "Inactive" ? true : false}
                                checked={PropertySave.HStatus !== "" && PropertySave.HStatus === "Inactive" ? true : false}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 customselectmy">
                          <Form.Group>
                            <Form.Label>RFP System</Form.Label>
                            <Select
                              placeholder="Select RFP"
                              className="multiselect" options={optionsRFP}
                              value={{ label: PropertySave.HRFPSystem || "Select RFP", value: PropertySave.HRFPSystem || "Select RFP" }}
                              onChange={(e: any) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HRFPSystem"]: e.value,
                                })}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Brand Portal Access Website Link</Form.Label>
                            <Form.Control type="text" maxLength={250} placeholder="Website Link" onChange={(e: any) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HBrandWebsite"]: e.target.value,
                              })}
                              value={PropertySave.HBrandWebsite} />
                          </Form.Group>
                        </div>

                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Brand Portal Access</Form.Label>
                            <div className="row">
                              <div className="col-6">
                                <Form.Control type="text" maxLength={160} placeholder="Username" onChange={(e: any) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HBrandLogin"]: e.target.value,
                                  })}
                                  value={PropertySave.HBrandLogin} />
                              </div>
                              <div className="col-6">
                                <Form.Control type="text" maxLength={160} placeholder="Password" onChange={(e: any) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HBrandPassword"]: e.target.value,
                                  })}
                                  value={PropertySave.HBrandPassword} />
                              </div>
                            </div>
                          </Form.Group>
                        </div>


                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>DUNS Number</Form.Label>
                            <Form.Control type="text" maxLength={60} placeholder="DUNS Number" onChange={(e: any) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HDUNSNumber"]: e.target.value,
                              })}
                              value={PropertySave.HDUNSNumber}

                            />
                          </Form.Group>
                        </div>

                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>FEMA Number</Form.Label>
                            <Form.Control type="text" maxLength={60} placeholder="FEMA Number" onChange={(e: any) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HFEMANumber"]: e.target.value,
                              })}
                              value={PropertySave.HFEMANumber} />
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>TARGET BAR Weekday Rate</Form.Label>
                            <Form.Control type="number" placeholder="TARGET BAR Weekday Rate"
                              onWheel={(event: any) => { event.target.blur() }}
                              onKeyDown={(e: any) => {
                                if (e.keyCode == '38' || e.keyCode == '40') {
                                  e.stopPropagation()
                                  e.preventDefault()
                                }
                              }}
                              value={PropertySave.HTARGETBAR}
                              step="any"
                              onChange={(e) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HTARGETBAR"]: e.target.value,
                                })
                              }
                            // isInvalid={PropertySave.errors.HTARGETBAR}
                            />
                            {/* {PropertySave.errors.HTARGETBAR && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.HTARGETBAR}
                            </Form.Control.Feedback>
                          )} */}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>TARGET LNR & RFP RATE</Form.Label>
                            <Form.Control type="number"
                              onWheel={(event: any) => { event.target.blur() }}
                              onKeyDown={(e: any) => {
                                if (e.keyCode == '38' || e.keyCode == '40') {
                                  e.stopPropagation()
                                  e.preventDefault()
                                }
                              }}
                              placeholder="TARGET LNR & RFP RATE" step="any"
                              value={PropertySave.HTARGETLNR}
                              onChange={(e: any) =>
                                setPropertySave({
                                  ...PropertySave,
                                  ["HTARGETLNR"]: e.target.value,
                                })}
                            // isInvalid={PropertySave.errors.HTARGETLNR}
                            />
                            {/* {PropertySave.errors.HTARGETLNR && (
                            <Form.Control.Feedback type="invalid">
                              {PropertySave.errors.HTARGETLNR}
                            </Form.Control.Feedback>
                          )} */}
                          </Form.Group>
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12 alingncenter">
                          <span className="toggle-switch blockswitch">
                            <input type="checkbox" id="toggle-input-switch" className="toggle-input" onChange={(e: any) =>
                              setPropertySave({
                                ...PropertySave,
                                ["HAssignUser"]: e.target.checked,
                              })}
                              checked={PropertySave.HAssignUser}
                              value={PropertySave.HAssignUser} />
                            <label className="toggle-label" htmlFor="toggle-input-switch">
                              <span className="circle"></span>
                            </label>
                            <span className="lablestatus">Assign user on auto activities</span>
                          </span>
                        </div>
                      </div >
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <h2 className="paymenttitle">Payment Information</h2>
                        </div>

                        <div className="col-xl-6 col-lg-12 col-md-12">
                          <Form.Group>
                            <Form.Label>Payment Info</Form.Label>
                            <div className="d-flex">

                              <Form.Select aria-label="Default select example"
                                onChange={(e) => SelectPaymentInfo(e)}
                                value={PropertySave.HpaymentInfoID}
                              >
                                <option value="" selected={PropertySave.HpaymentInfoID === "" || !PropertySave.HpaymentInfoID ? true : false}>Select Payment Info</option>
                                {props.PaymentInfo &&
                                  props.PaymentInfo.map((item: any, index) => (
                                    <option
                                      selected={PropertySave.HpaymentInfoID === item.paymentInfoID ? true : false}
                                      key={index}
                                      value={item.paymentInfoID}
                                    >
                                      {item.invoiceContactName}({item.invoiceContactPhone})
                                    </option>
                                  ))
                                }
                              </Form.Select>
                              <button type='button'
                                className="outlineborder"
                                onClick={() => setModalShowProperties(true)}
                              >
                                <i className="fa-regular fa-circle-plus"></i>
                              </button>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="row">
                          <div className="col-xl-3 col-lg-12 col-md-12">
                            <Form.Group>
                              <Form.Label>Payment Option</Form.Label>
                              <Form.Control
                                type="text" maxLength={256}
                                value={PaymentInfo && PaymentInfo.paymentOption}
                                disabled
                              // placeholder={DisplayText.InvoiceContactName}
                              // value={ClientSave.invoiceContactName}
                              // onChange={(e) =>
                              //   setClientSave({
                              //     ...ClientSave,
                              //     ["invoiceContactName"]: e.target.value,
                              //   })
                              // }
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 col-lg-12 col-md-12">
                            <Form.Group>
                              <Form.Label>Invoice Contact Name</Form.Label>
                              <Form.Control
                                type="text" maxLength={256}
                                value={PaymentInfo && PaymentInfo.invoiceContactName}
                                disabled

                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 col-lg-12 col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                value={PaymentInfo && PaymentInfo.invoiceContactEmail}
                                disabled
                              // placeholder={DisplayText.Email}
                              // value={ClientSave.invoiceContactEmail}
                              // onChange={(e) =>
                              //   setClientSave({
                              //     ...ClientSave,
                              //     ["invoiceContactEmail"]: e.target.value,
                              //   })
                              // }
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xl-3 col-lg-12 col-md-12">
                            <Form.Group>
                              <Form.Label>Phone No.</Form.Label>

                              <Form.Control
                                type="text" maxLength={256}
                                // placeholder={DisplayText.InvoiceContactName}
                                value={PaymentInfo && PaymentInfo.invoiceContactPhone}
                                disabled

                              />

                            </Form.Group>
                          </div>
                          <div className="col-xl-3 col-lg-12 col-md-12">
                            <Form.Group>
                              <Form.Label>Rate ($)</Form.Label>

                              <Form.Control
                                type="number"
                                onWheel={(event: any) => {
                                  event.target.blur();
                                }}
                                onKeyDown={(e: any) => {
                                  if (e.keyCode == "38" || e.keyCode == "40") {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={256}
                                // placeholder={DisplayText.InvoiceContactName}
                                // value={PaymentInfo && PaymentInfo.Rate}
                                value={PropertySave.HRate}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["HRate"]: e.target.value,
                                  })}
                                isInvalid={PropertySave.errors.HRate}
                              />
                              {PropertySave.errors.HRate && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.HRate}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-4 mb-2">
                          <h2 className="paymenttitle">Logo</h2>
                          {/* <div className="custom-file comaninputfile thumnailimg"> */}

                          <div className="col-xl-3 col-lg-12 col-md-12">
                            {/* <h2 className="paymenttitle">Banner Image</h2> */}

                            <div className="custom-file comaninputfile">
                              {
                                LogoUpload &&
                                <img src={LogoUpload} style={{
                                  display: 'flex',
                                  border: '2px solid tomato',
                                  maxWidth: '300px',
                                  maxHeight: '300px',
                                }}
                                  alt="" />}
                            </div>
                            <div className="comninputbtn">
                              <span>Select Logo</span>
                              <input type="file" accept='image/*' id="inputGroupFile01" className=""
                                onChange={SelectLogo}
                              />
                            </div>

                          </div>

                        </div>
                      </div>
                    </>
                    ) : (
                      <PageLoader />
                    )}
                  </Tab>

                  <Tab eventKey="tab2" title="Competitor Hotels">
                    <Container fluid="true" className="p-0">
                      <Row className="">
                        <div className="col-md-12">
                          <h2 className="paymenttitle">Competitor Hotel Details</h2>
                          <div className="btn-icon-grp-table button.btn-icon.btn.btn-primary addFormIcon d-flex justify-content-end">
                            <div className="client">
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Competitor</Tooltip>}>
                                <button
                                  className="modalbutn"
                                  onClick={(e) => {
                                    if (pageRights.is_create) {
                                      e.preventDefault();
                                      setShowModel(true);
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                >
                                  <i className="fa-regular fa-circle-plus"></i>
                                  {/* <span>Add Competitor</span> */}
                                </button>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </Row>


                      {!loading ? (
                        <div className="col-md-12 customtablemetting mt-1">
                          <Table responsive bordered className="table-without-space">
                            <thead>
                              <tr>
                                <th style={{ textAlign: "left", width: "20%" }} className="settitle">
                                  Brand Name
                                </th>
                                <th style={{ textAlign: "left", width: "20%" }} className="settitle">
                                  Hotel Name
                                </th>
                                <th style={{ width: "25%", textAlign: "left" }} className="settitle">
                                  Website URL
                                </th>
                                <th style={{ width: "25%", textAlign: "left" }} className="settitle">
                                  RateShop URL
                                </th>
                                <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>


                              {tempMap !== undefined && tempMap && tempMap.length > 0 ? (
                                tempMap.map((itm, idx) => (
                                  <tr key={idx}>
                                    <td style={{ textAlign: "left", width: "20%" }}>{itm.BrandName}</td>
                                    <td style={{ textAlign: "left", width: "20%" }}>
                                      {itm.HotelCode + " " + itm.HotelName}
                                    </td>
                                    <td style={{ width: "25%" }}>

                                      <a href={itm.WebsiteURL} target="_blank">

                                        {itm.WebsiteURL}
                                      </a>
                                    </td>
                                    <td style={{ width: "25%" }}>
                                      <a href={itm.RateShopURL} target="_blank">
                                        {itm.RateShopURL}
                                      </a>
                                    </td>
                                    <td style={{ textAlign: "center", width: "10%" }}>
                                      <>
                                        <span className="btn-icon-grp-table justify-content-end  align-items-center">
                                          <Button
                                            className="btn-icon button btn btn-primary editIcon acteditbtn"
                                            onClick={() => {
                                              if (pageRights.is_update) {
                                                setCompetitorID(itm.id);
                                                setCompetitorEditData(itm);
                                                setShowModel(true);
                                              } else {
                                                CommonService.unauthorizedAction();
                                              }
                                            }}
                                          ></Button>
                                          <Button
                                            className="btn-icon cancelIcon"
                                            onClick={(e) => {
                                              e.preventDefault()
                                              if (pageRights.is_delete) {
                                                if (itm.CompHotelID !== "00000000-0000-0000-0000-000000000000") {
                                                  DeleteCompetitorExist(itm);
                                                } else {
                                                  DeleteCompetitorNew(itm.id);
                                                }
                                              } else {
                                                CommonService.unauthorizedAction();
                                              }
                                            }}
                                          ></Button>
                                        </span>
                                      </>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td style={{ textAlign: "center" }} colSpan={5}>
                                    No Data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>

                      ) : (
                        <PageLoader />
                      )}
                      {/* {showModel && (
                        <AddEditCompetitorModal
                          propertyID={props.EditPropertiesID}
                          clientID={ClientID}
                          competitorID={competitorID}
                          show={showModel}
                          fetchCompetitorListData={fetchCompetitorListData}
                          onHide={() => {
                            setShowModel(false);
                            setCompetitorID("");
                          }}
                        />
                      )} */}

                      {showModel && (
                        <AddEditCompetitorModal
                          // tempMap={tempMap}
                          // CompetitorData={CompetitorData}
                          setCompetitorData={fnReturnData}
                          client_id={ClientID}
                          property_id={props.EditPropertiesID}
                          competitorID={competitorID}
                          CompetitorEditData={CompetitorEditData}
                          show={showModel}
                          // fetchCompetitorListData={fetchCompetitorListData}
                          onHide={() => {
                            setShowModel(false);
                            setCompetitorID("");
                          }}
                        />
                      )}



                    </Container>
                  </Tab>

                  <Tab
                    tabClassName={`${PropertySave.errors.OOWPhone
                      ? "validate-tab"
                      : PropertySave.errors.OOWEmail
                        ? "validate-tab"
                        : PropertySave.errors.OGMPhone
                          ? "validate-tab"
                          : PropertySave.errors.OGMEmail
                            ? "validate-tab"
                            : PropertySave.errors.ODSPhone
                              ? "validate-tab"
                              : PropertySave.errors.ODSEmail
                                ? "validate-tab"
                                : PropertySave.errors.OCMPhone
                                  ? "validate-tab"
                                  : PropertySave.errors.OCMEmail
                                    ? "validate-tab"
                                    : PropertySave.errors.OSMPhone
                                      ? "validate-tab"
                                      : PropertySave.errors.OSMEmail
                                        ? "validate-tab"
                                        : PropertySave.errors.ORMPhone
                                          ? "validate-tab"
                                          : PropertySave.errors.ORMEmail
                                            ? "validate-tab"
                                            : PropertySave.errors.OREPhone
                                              ? "validate-tab"
                                              : PropertySave.errors.OREEmail
                                                ? "validate-tab"
                                                : PropertySave.errors.ORSPhone
                                                  ? "validate-tab"
                                                  : PropertySave.errors.ORSEmail
                                                    ? "validate-tab"
                                                    : ""
                      }`}
                    eventKey="tab3" title="Organization" >
                    <div className="row mt-3">
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Owner</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name" value={PropertySave.OOWName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OOWName"]: e.target.value,
                                  })
                                }

                              />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.OOWCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OOWCountryCode"]: e.target.value,
                                    })
                                  }

                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.OOWPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.OOWPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OOWPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.OOWPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.OOWPhone}</span>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="text" maxLength={349}
                                value={PropertySave.OOWEmail}
                                placeholder="Email"
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OOWEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.OOWEmail}
                              />
                              {PropertySave.errors.OOWEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.OOWEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.OOWExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OOWExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">GM</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name"
                                defaultValue={PropertySave.OGMName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OGMName"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.OGMCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OGMCountryCode"]: e.target.value,
                                    })
                                  }

                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.OGMPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.OGMPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OGMPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.OGMPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.OGMPhone}</span>
                                  <br />
                                </>
                              )}

                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="text" maxLength={349}
                                placeholder="Email"
                                value={PropertySave.OGMEmail}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OGMEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.OGMEmail}
                              />
                              {PropertySave.errors.OGMEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.OGMEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.OGMExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OGMExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Director of Sales</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name"
                                value={PropertySave.ODSName} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ODSName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.ODSCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ODSCountryCode"]: e.target.value,
                                    })
                                  }

                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.ODSPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.ODSPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ODSPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.ODSPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.ODSPhone}</span>
                                  <br />
                                </>
                              )}

                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.ODSEmail} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ODSEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.ODSEmail}
                              />
                              {PropertySave.errors.ODSEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.ODSEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.ODSExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ODSExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Catering Manager</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name" value={PropertySave.OCMName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OCMName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.OCMCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OCMCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.OCMPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.OCMPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OCMPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.OCMPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.OCMPhone}</span>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.OCMEmail} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OCMEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.OCMEmail}
                              />
                              {PropertySave.errors.OCMEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.OCMEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.OCMExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OCMExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Sales Manager</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name" value={PropertySave.OSMName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OSMName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.OSMCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OSMCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.OSMPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.OSMPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OSMPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.OSMPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.OSMPhone}</span>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.OSMEmail}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OSMEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.OSMEmail}
                              />
                              {PropertySave.errors.OSMEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.OSMEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.OSMExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OSMExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Revenue Manager</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name"
                                value={PropertySave.ORMName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORMName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.ORMCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ORMCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.ORMPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.ORMPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ORMPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.ORMPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.ORMPhone}</span>
                                  <br />
                                </>
                              )}

                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.ORMEmail} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORMEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.ORMEmail}
                              />
                              {PropertySave.errors.ORMEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.ORMEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.ORMExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORMExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Regional</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name"
                                value={PropertySave.OREName}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OREName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.ORECountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ORECountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.OREPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.OREPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["OREPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.OREPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.OREPhone}</span>
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.OREEmail}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OREEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.OREEmail}
                              />
                              {PropertySave.errors.OREEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.OREEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.OREExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["OREExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="subtitlepoup">Regional Sales</h2>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Name"
                                value={PropertySave.ORSName} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORSName"]: e.target.value,
                                  })
                                } />
                            </Form.Group>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>C_Code</Form.Label>
                                <Form.Control type="text" maxLength={6} placeholder="C_Code" value={PropertySave.ORSCountryCode}
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ORSCountryCode"]: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-8">
                              <Form.Label>Phone no.</Form.Label>
                              <div className={`${PropertySave.errors.ORSPhone && "red-border-masking-box w-100"}`}>
                                <PatternFormat
                                  className="form-control"
                                  placeholder="Enter contact no."
                                  displayType="input"
                                  value={PropertySave.ORSPhone}
                                  format="###-###-####"
                                  onChange={(e) =>
                                    setPropertySave({
                                      ...PropertySave,
                                      ["ORSPhone"]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              {PropertySave.errors.ORSPhone && (
                                <>
                                  <span className="feedbackss">{PropertySave.errors.ORSPhone}</span>
                                  <br />
                                </>
                              )}

                            </div>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Email"
                                value={PropertySave.ORSEmail} onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORSEmail"]: e.target.value,
                                  })
                                }
                                isInvalid={PropertySave.errors.ORSEmail}
                              />
                              {PropertySave.errors.ORSEmail && (
                                <Form.Control.Feedback type="invalid">
                                  {PropertySave.errors.ORSEmail}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </div>
                          <div className="col-md-12">
                            <Form.Group>
                              <Form.Label>Ext</Form.Label>
                              <Form.Control type="text" maxLength={364} placeholder="Ext" value={PropertySave.ORSExt}
                                onChange={(e) =>
                                  setPropertySave({
                                    ...PropertySave,
                                    ["ORSExt"]: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="tab4" title="Address" >
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Address Information</h2>
                      </div>

                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>Street</Form.Label>
                          <Form.Control type="text" placeholder="Street"
                            value={PropertySave.AStreet} onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["AStreet"]: e.target.value,
                              })
                            } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>State</Form.Label>
                          <Form.Control type="text" maxLength={64} placeholder="State"
                            value={PropertySave.AState} onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["AState"]: e.target.value,
                              })
                            } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text" maxLength={364} placeholder="City"
                            value={PropertySave.ACity} onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["ACity"]: e.target.value,
                              })
                            } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>Country</Form.Label>
                          <Form.Control type="text" maxLength={64} placeholder="Country" value={PropertySave.ACountry} onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["ACountry"]: e.target.value,
                            })
                          } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control type="text" maxLength={12} placeholder="Zip Code" value={PropertySave.AZipCode} onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["AZipCode"]: e.target.value,
                            })
                          } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>Country Code</Form.Label>
                          <Form.Control type="text" maxLength={6} placeholder="Country Code" value={PropertySave.ACountryCode} onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["ACountryCode"]: e.target.value,
                            })
                          } />
                        </Form.Group>
                      </div>
                      <div className="col-xl-3 col-lg-12 col-md-12">

                        <Form.Label>Phone no.</Form.Label>
                        {/* <InputMasking
                          onChangeInput={value => {
                            setPropertySave({
                              ...PropertySave,
                              ["APhoneno"]: value,
                            })
                          }}
                          className={`form-control ${PropertySave.errors.APhoneno && "is-invalid"}`}
                          currentValue={PropertySave.APhoneno || ""}
                        />
                        {PropertySave.errors.APhoneno && (
                          <Form.Control.Feedback type="invalid">
                            {PropertySave.errors.APhoneno}
                          </Form.Control.Feedback>
                        )} */}
                        <div className={`${PropertySave.errors.APhoneno && "red-border-masking-box"}`}>
                          <PatternFormat
                            className="form-control"
                            placeholder="Enter contact no."
                            displayType="input"
                            value={PropertySave.APhoneno}
                            format="###-###-####"
                            onChange={(e) =>
                              setPropertySave({
                                ...PropertySave,
                                ["APhoneno"]: e.target.value,
                              })
                            }
                          />
                        </div>
                        {PropertySave.errors.APhoneno && (
                          <>
                            <span className="feedbackss">{PropertySave.errors.APhoneno}</span>
                            <br />
                          </>
                        )}
                        {/* <Form.Control type="text" maxLength={256} placeholder="Phone no." value={PropertySave.APhoneno}
                          onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["APhoneno"]: e.target.value,
                            })
                          } />*/}

                      </div>
                    </div>
                    <div className="row mt-3 report-email">

                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <Form.Group>
                          <Form.Label>Report Email to</Form.Label>
                          <TagsInput
                            inputProps={{
                              placeholder: "",
                            }}
                            className="tag-box react-tagsinput"
                            // maxTags={10}
                            value={tags}
                            onChange={handleChange}
                          />
                          {/* <Form.Control type="text" maxLength={360} placeholder="Report Email to" value={PropertySave.AReportEmail} onChange={(e) =>
                            setPropertySave({
                              ...PropertySave,
                              ["AReportEmail"]: e.target.value,
                            })
                          } /> */}
                        </Form.Group>
                      </div>
                    </div>

                  </Tab>
                  <Tab eventKey="tab5" title="Tax/Fees">
                    <div className="col-md-12">
                      <h2 className="paymenttitle">Tax Information</h2>
                    </div>
                    {!loading ? (
                      <div className="col-md-12 customtablemetting newtable-design mt-3">
                        <Table className="table-without-space mb-0" responsive bordered>
                          <thead>
                            <tr>
                              <td className="highlighttext w-40">
                                Tax Title
                              </td>
                              <td className="highlighttext w-25">
                                Type ($/%)
                              </td>
                              <td className="highlighttext w-25">
                                Tax Value
                              </td>
                              <td className="highlighttext w-20">
                                Action
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {inputTaxHotelList.map((x, i) => {

                              return (
                                <>
                                  <tr>
                                    <td className="fild-input">
                                      <CreatableSelect
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 99999999,
                                          }),
                                          menu: (base) => ({
                                            ...base,
                                            zIndex: 2,
                                          }),
                                        }}
                                        placeholder={"Select Tax"}
                                        isClearable
                                        options={optionsTax}
                                        onChange={(event: any) => handleInputRoomChangeTab(event, i)}

                                        className={`ClassSelectCreatable is_bordr_class ${(inputTaxHotelListEr && inputTaxHotelListEr[i] && inputTaxHotelListEr[i].TaxTitle !== "")
                                          ? "red-border-masking ClassSelectCreatable"
                                          : x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined && "ClassSelectCreatable ptb-2 disabledCreateSelect"
                                          }`
                                        }

                                        value={
                                          x.TaxTitle !== ""
                                            ? {
                                              label: x.TaxTitle,
                                              value: x.TaxTitle,
                                            }
                                            : {
                                              label: "Select Tax",
                                              value: "Select Tax",
                                            }
                                        }

                                        isDisabled={x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined ? true : false}

                                      />
                                    </td>
                                    <td >
                                      <Form.Select
                                        // disabled ={x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined ? true : false}

                                        aria-label="Default select example"
                                        className="is_bordr_class"
                                        style={{ minWidth: "0", padding: "2" }}
                                        name="IsPercentage"
                                        onChange={(e) => handleInputRoomChangeTab(e, i)}
                                      >
                                        <option selected={x.IsPercentage === true && "Yes"} value="Yes">
                                          %
                                        </option>
                                        <option selected={x.IsPercentage === false && "No"} value="No">
                                          $
                                        </option>
                                      </Form.Select>
                                    </td>
                                    <td className="bordernone">
                                      <Form.Group>
                                        <Form.Control
                                          type="text"
                                          value={x.RateOrPercentage}
                                          // maxLength={2}
                                          name="RateOrPercentage"
                                          onChange={(e) => handleInputRoomChangeTab(e, i)}
                                          onKeyPress={(e) => CheckKeyEvent(e)}

                                          className={`${inputTaxHotelListEr && inputTaxHotelListEr[i] && inputTaxHotelListEr[i].RateOrPercentage !== ""
                                            ? "red-border-masking "
                                            : ""
                                            }`}
                                        // disabled ={x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined ? true : false }
                                        />
                                      </Form.Group>
                                      {/* {IsvalidState.status === true && (
                                  <Form.Control.Feedback type="invalid">
                                    {"Invalid Input!! (" + IsvalidState.Value + ")"}
                                  </Form.Control.Feedback>
                                )} */}
                                    </td>
                                    <td className="bordernone">
                                      <span className={`btn-icon-grp-table justify-content-center ${x.TaxId !== null && x.TaxId !== "" && x.TaxId !== undefined && ""}`}>
                                        <Button
                                          className="btn-icon cancelIcon"
                                          onClick={() => RemoveTaxRow(i)}
                                        ></Button>
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                            {/* {inputList.length - 1 === i && ( */}
                            <tr>
                              <td colSpan={4} className="bluetext">
                                <div
                                  role="button"
                                  tabIndex={-1}
                                  onClick={handleAddROOMRowClickTab}
                                  onKeyDown={handleAddROOMRowClickTab}
                                >
                                  <img src={SquarePlus} alt="" width={15} height={15} className="me-1" />
                                  Add More
                                </div>
                              </td>
                            </tr>
                            {/* )} */}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <PageLoader />
                    )}
                  </Tab>
                  <Tab eventKey="tab6" title="Meeting Rooms" tabClassName={`${ValidMeetingRoom === true && "validate-tab"}`}>

                    {!loading ? (<div className="row mt-3">
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Meeting Rooms</h2>
                      </div>
                      <div className="col-md-12 customtablemetting newtable-design mt-3">
                        {MeetingRoomTable.length > 0 &&
                          <Table responsive bordered>
                            <thead>
                              <tr>
                                <th colSpan={3} className="bluetext" >
                                  <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => MeetingRoomTable_AddNewCol()}
                                    onKeyDown={() => MeetingRoomTable_AddNewCol()}
                                  >
                                    <img
                                      src={SquarePlus}
                                      alt=""
                                      width={15}
                                      height={15}
                                      className="me-1"
                                    />
                                    Add RoomSetup Column
                                  </div>
                                </th>
                                <th className="settitle" colSpan={MeetingRoomTable && MeetingRoomTable[0] && Object.keys(MeetingRoomTable[0]) !== null && Object.keys(MeetingRoomTable[0]).length - 2 + 1}>Setup</th>
                              </tr>
                              <tr className="h-35">
                                <th className='text-center fixed-width'>{MeetingRoomTable && MeetingRoomTable[0] && MeetingRoomTable[0]["Col0"]}</th>
                                {/* <th className={`text-center fixed-width ${props.EditPropertiesID === "" && "classDisplay"}`}>
                                {MeetingRoomTable[0]["Col1"]}
                              </th> */}
                                <th className={`text-center fixed-width`}>
                                  {MeetingRoomTable && MeetingRoomTable[0] && MeetingRoomTable[0]["Col1"]}
                                </th>
                                <th className='text-center square-feet'>{MeetingRoomTable.length > 0
                                  && MeetingRoomTable[0]["Col2"]}</th>
                                {/* <th className='text-center tax'>{MeetingRoomTable[0]["Col3"]}</th> */}

                                {Object.keys(MeetingRoomTable[0]).map(
                                  (row, index) =>
                                    index > 3 && (
                                      MeetingRoomTable.length !== 0 &&
                                      MeetingRoomTable.length[0] !== 0 &&
                                      <th>
                                        <div className="d-flex align-items-center justify-content-between border-0
                                      " title={MeetingRoomTable[0][row] !== "" ? MeetingRoomTable[0][row] : "Select Rooms"}>
                                          <CreatableSelect
                                            placeholder={"Select Rooms"}
                                            isClearable
                                            className='ClassSelectCreatable is_bordr_class'
                                            options={itemsNew}
                                            // menuIsOpen={true}
                                            id={row + "_" + index}
                                            onChange={(e: any) => {
                                              if (e !== null) {
                                                let lst = [...MeetingRoomTable];
                                                lst[0][row] = e.value;
                                                setMeetingRoomTable(lst);
                                              } else {
                                                let lst = [...MeetingRoomTable];
                                                lst[0][row] = "Select Arrangement Type";
                                                setMeetingRoomTable(lst);
                                              }
                                            }}
                                            value={
                                              MeetingRoomTable[0][row] !== ""
                                                ? {
                                                  label: MeetingRoomTable[0][row],
                                                  value: MeetingRoomTable[0][row],
                                                }
                                                : {
                                                  label: "Select Arrangement Type",
                                                  value: "Select Arrangement Type",
                                                }
                                            }
                                            menuPortalTarget={document.body}
                                            styles={{
                                              menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                          <span className="btn-icon-grp-table justify-content-end border-0">
                                            <Button
                                              className="btn-icon cancelIcon"
                                              onClick={() => MeetingRoomTable_RemoveColumn(row)}
                                            ></Button>
                                          </span>
                                        </div>
                                      </th>
                                    )
                                )}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {MeetingRoomTable.map(
                                (Row, RowIndex) =>
                                  RowIndex > 0 && (
                                    <tr key={RowIndex}>
                                      {MeetingRoomTable &&
                                        MeetingRoomTable.length !== 0 &&
                                        MeetingRoomTable.length[0] !== 0 &&
                                        <>
                                          {Object.keys(Row).map((Col, ColIndex) => (
                                            Col !== "Col3" &&
                                            <td
                                              key={ColIndex}
                                            // className={`${props.EditPropertiesID === "" &&
                                            //   Col === "Col1" &&
                                            //   "classDisplay"
                                            //   }`}
                                            >
                                              {Col === "Col0" && (
                                                <Form.Control
                                                  type="text"
                                                  className='border-0'
                                                  name={"MeetingRoom_" + RowIndex + "_" + Col}
                                                  id={"MeetingRoom_" + RowIndex + "_" + Col}
                                                  value={Row[Col]}
                                                  // onChange={(e) => {
                                                  //   let lst = [...MeetingRoomTable];
                                                  //   lst[RowIndex][Col] = e.target.value;
                                                  //   setMeetingRoomTable(lst);
                                                  // }}
                                                  onChange={(e) => {
                                                    UpdatedoptionsAssociation.push(e.target.value);
                                                    RemoveOrCheckAssociation(
                                                      e.target.value,
                                                      e,
                                                      RowIndex,
                                                      Col
                                                    );
                                                  }}
                                                />
                                              )}

                                              {Col === "Col1" && (
                                                <Select
                                                  className='ClassSelectCreatable'
                                                  placeholder="Select Association"
                                                  name={"Association_" + RowIndex + "_" + Col}
                                                  id={"Association_" + RowIndex + "_" + Col}
                                                  options={optionsAssociation}
                                                  onChange={(e: any) =>
                                                    SelectedAssociationRoom(e, RowIndex, Col)
                                                  }
                                                  defaultValue={optionsAssociation.filter(
                                                    (obj) =>
                                                      MeetingRoomTable[RowIndex][Col] &&
                                                      MeetingRoomTable[RowIndex][Col].includes(obj.label)
                                                  )}
                                                  isMulti
                                                />
                                              )}

                                              {Col === "Col2" && (
                                                <Form.Control
                                                  type="text"
                                                  className='border-0'
                                                  name={"SquareFeet_" + RowIndex + "_" + Col}
                                                  value={Row[Col]}
                                                  onChange={(e) => {
                                                    let lst = [...MeetingRoomTable];
                                                    lst[RowIndex][Col] = e.target.value;
                                                    setMeetingRoomTable(lst);
                                                  }}
                                                />
                                              )}
                                              {/* {Col === "Col3" && (
                                              <div
                                                role="button"
                                                className="bluetext bg-white border-0"
                                                tabIndex={-1}
                                                data-lstroommeetingtaxes={
                                                  RowIndex !== 0 && MeetingRoomTable[RowIndex]["Col3"]
                                                }
                                                id={"lstRoomMeetingTaxes_" + RowIndex}
                                                onClick={() =>
                                                  getData(RowIndex, MeetingRoomTable[RowIndex].Col0)
                                                }
                                                onKeyDown={() =>
                                                  getData(RowIndex, MeetingRoomTable[RowIndex].Col0)
                                                }
                                              // id={"TaxDetails_" + RowIndex + "_" + Col}
                                              >
                                                Tax Details
                                              </div>
                                            )} */}

                                              {Col !== "Col0" &&
                                                Col !== "Col1" &&
                                                Col !== "Col2" &&
                                                Col !== "Col3" && (
                                                  <Form.Control
                                                    className='border-0'
                                                    type="text"
                                                    value={Row[Col]}
                                                    name={"DataCount_" + RowIndex + "_" + Col}
                                                    onChange={(e) => {
                                                      let lst = [...MeetingRoomTable];
                                                      lst[RowIndex][Col] = e.target.value;
                                                      setMeetingRoomTable(lst);
                                                    }}
                                                  />
                                                )}
                                            </td>
                                          ))}
                                          <td>
                                            <span className="btn-icon-grp-table justify-content-end border-0">
                                              <Button
                                                className="btn-icon cancelIcon"
                                                onClick={() => MeetingRoomTable_RemoveRow(Row, RowIndex)}
                                              ></Button>
                                            </span>
                                          </td>
                                        </>
                                      }
                                    </tr>
                                  )
                              )}
                            </tbody>
                            <tfoot>
                              <th colSpan={3} className="bluetext bg-white" >
                                <div
                                  role="button"
                                  tabIndex={-1}
                                  onClick={() => MeetingRoomTable_AddNewRow()}
                                  onKeyDown={() => MeetingRoomTable_AddNewRow()}
                                >
                                  <img
                                    src={SquarePlus}
                                    alt=""
                                    width={15}
                                    height={15}
                                    className="me-1"
                                  />
                                  Add New Row
                                </div>
                              </th>
                              {(MeetingRoomTable.length !== 0 && MeetingRoomTable[0]).length !== 0 &&
                                <th className="settitle bg-white" colSpan={Object.keys(MeetingRoomTable[0]).length - 4}></th>}
                              {/* <th></th> */}
                            </tfoot>
                          </Table>}
                      </div>
                      <br />
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Tax Information</h2>
                      </div>
                      <div className="tax-information customtablemetting">
                        <Table className="table-without-space mb-0" responsive bordered>
                          <thead>
                            <tr>
                              <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                                Apply?
                              </th>
                              <th style={{ width: "70%" }} className="settitle">
                                Tax Title
                              </th>
                              <th style={{ width: "10%" }} className="settitle">
                                Type ($/%)
                              </th>
                              <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputMeetingRoomTaxList111 &&
                              inputMeetingRoomTaxList111.map((x1, i) => (
                                // return (
                                <>
                                  <tr key={i}>
                                    <td className="" style={{ textAlign: "center" }}>
                                      <input
                                        style={{ textAlign: "center" }}
                                        type="checkbox"
                                        id={i}
                                        onChange={(e) => MeetingRoomTaxCheckboxOnchange(e, x1, i)}
                                        checked={x1.IsApply}
                                      ></input>
                                    </td>
                                    <td>{x1.TaxTitle}</td>
                                    <td className="bordernone" style={{ textAlign: "center" }}>
                                      {x1.IsPercentage === false ? "$" : "%"}
                                    </td>
                                    <td className="bordernone" style={{ textAlign: "center" }}>
                                      <Form.Control
                                        type="number"
                                        onWheel={(event: any) => {
                                          event.target.blur();
                                        }}
                                        onKeyDown={(e: any) => {
                                          if (e.keyCode == "38" || e.keyCode == "40") {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }
                                        }}
                                        value={x1.RateOrPercentage}
                                        onChange={(e) => {
                                          // inputMeetingRoomTaxList[x.orderNo].rateOrPercentage = e.target.value

                                          // setInputMeetingRoomTaxList(inputMeetingRoomTaxList)

                                          let Mlist: any = [...inputMeetingRoomTaxList111];
                                          Mlist[x1.OrderNo].RateOrPercentage = e.target.value;
                                          setInputMeetingRoomTaxList111(Mlist);
                                          if (
                                            props.EditPropertiesID !== undefined &&
                                            props.EditPropertiesID !== null &&
                                            props.EditPropertiesID !== ""
                                          ) {
                                            setPropertySave({
                                              ...PropertySave,
                                              ["IsTaxChange"]: true,
                                            });
                                          }
                                          // MeetingRoomTaxOnchange(e, x)
                                        }}
                                      />
                                      {/* <input
                                    type="text"
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => MeetingRoomTaxOnchange(e, x)}
                                    value={x.rateOrPercentage}
                                  /> */}
                                    </td>
                                  </tr>
                                </>
                                // );
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>) : (
                      <PageLoader />
                    )}
                  </Tab>

                  <Tab eventKey="tab7" title="Guest Rooms"
                    tabClassName={`${ValidGuestRoom === true && "validate-tab"}`}
                  >
                    <div className="col-md-12 customtablemetting newtable-design mt-3">
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Room Types</h2>
                      </div>
                      <Table className="w-50" responsive bordered>
                        <thead>
                          <tr>
                            <td className="highlighttext w-50">Room Type</td>
                            <td className="highlighttext w-50">Quantity</td>
                            {/* <td className="highlighttext w-50">Tax</td> */}
                            <td className="highlighttext w-50">Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {inputList.map((x: any, i) =>
                          (
                            <>
                              <tr>
                                <td className="fild-input">

                                  <CreatableSelect
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    placeholder={"Select Rooms"}
                                    isClearable
                                    options={RoomTypesDropdown}
                                    // onCreateOption={(event) => handleInputRoomSelect(event, i)}
                                    onChange={(event: any) => handleInputRoomSelect(event, i)}
                                    id={"RoomType_" + i}
                                    className='ClassSelectCreatable'
                                    defaultValue={
                                      x.roomType !== ""
                                        ? {
                                          label: x.roomType,
                                          value: x.roomType,
                                        }
                                        : {
                                          label: "Select Rooms",
                                          value: "Select Rooms",
                                        }
                                    }
                                  />


                                </td>
                                <td className="bordernone">
                                  <Form.Group>
                                    <Form.Control type="text" className="is_bordr_class_focus" maxLength={256} name="quantity" value={x.quantity}
                                      onChange={e => handleInputRoomChange(e, i)} />
                                  </Form.Group>
                                </td>
                                <td>
                                  <span className="btn-icon-grp-table border-0 justify-content-end">
                                    <Button
                                      className="btn-icon cancelIcon "
                                      onClick={
                                        () =>
                                          RemoveRoomTypesRow(i)
                                        // alert("Row" + i)
                                        // MeetingRoomTable_RemoveRow(Row)
                                      }
                                    ></Button>
                                  </span>
                                </td>
                              </tr>

                            </>
                          )
                          )}
                          <tr>
                            <td colSpan={3} className="bluetext bg-white"><div role="button"
                              tabIndex={-1} onClick={handleAddROOMRowClick} onKeyDown={handleAddROOMRowClick}
                            >
                              <img
                                src={SquarePlus}
                                alt=""
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              Add More</div></td>
                          </tr>


                        </tbody>

                      </Table>

                    </div>
                    {/* Tax Table Start */}
                    <br />
                    <div className="col-md-12">
                      <h2 className="paymenttitle">Tax Details</h2>
                    </div>
                    <div className="tax-information customtablemetting">
                      <Table className="table-without-space mb-0" responsive bordered>
                        <thead>
                          <tr>
                            <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                              Apply?
                            </th>
                            <th style={{ width: "70%" }} className="settitle">
                              Tax Title
                            </th>
                            <th style={{ width: "10%" }} className="settitle">
                              Type ($/%)
                            </th>
                            <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                              Value
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {inputGuestRoomTaxList.map((y, i) => (
                            // 
                            // return (
                            <>
                              <tr key={i}>
                                <td className="" style={{ textAlign: "center" }}>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="checkbox"
                                    checked={y.IsApply}
                                    onChange={(e) => GuestRoomTaxCheckboxOnchange(e, y, i)}
                                  ></input>
                                </td>
                                <td>{y.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {y.IsPercentage === false ? "$" : "%"}
                                </td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  <input
                                    type="number"
                                    onWheel={(event: any) => {
                                      event.target.blur();
                                    }}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                    // type="text"
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => GuestRoomTaxOnchange(e, y)}
                                    value={y.RateOrPercentage}
                                  />{" "}
                                </td>
                              </tr>
                            </>
                            // );
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* tax table end */}

                  </Tab>
                  <Tab eventKey="tab8" title="AV Equipment"
                    tabClassName={`${first3 === false ? "validate-tab" : ""}`}>
                    <div className="col-md-12">
                      <h2 className="paymenttitle">AV Equipment Details</h2>
                    </div>
                    <div className="col-md-12 customtablemetting newtable-design mt-3">

                      <Row className="mb-2">
                        <div className="col-md-12 customtablemetting mt-1">

                          <Table responsive bordered className="table-without-space">
                            <thead>
                              <tr className="h-40">
                                <th className="">Equipment Name</th>
                                <th>Type</th>
                                <th>Cost Per Unit($)</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(avDataRes).map((data_key) => {
                                return (
                                  <>
                                    <tr>
                                      <td className="bordernone">
                                        <input
                                          // className="input-tabal ps-0 pe-0"
                                          className={`input-tabal ps-0 pe-0 creatableSpace ${(avDataResEr[data_key] && avDataResEr[data_key].avEquipName !== "")
                                            ? "red-border-masking ClassSelectCreatable"
                                            : ""
                                            }`}
                                          type="text"
                                          onChange={(e) => {
                                            setAvDataRes({
                                              ...avDataRes,
                                              [data_key]: {
                                                ...avDataRes[data_key],
                                                avEquipName: e.target.value ? e.target.value : "",
                                              },
                                            });
                                          }}
                                          value={avDataRes[data_key].avEquipName}
                                        />
                                      </td>

                                      <td
                                        className="w-300"
                                      >
                                        <div className={`${(avDataResEr[data_key] && avDataResEr[data_key].avEquipType_Term !== "")
                                          ? "red-border-masking"
                                          : ""
                                          }`}
                                        >
                                          <CreatableSelect
                                            styles={{
                                              menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            isClearable
                                            options={aveTypesList}
                                            onChange={(event: any) => {
                                              setAvDataRes({
                                                ...avDataRes,
                                                [data_key]: {
                                                  ...avDataRes[data_key],
                                                  avEquipType_Term:
                                                    event !== null ? event.label : "",
                                                },
                                              });
                                            }}
                                            placeholder="Select Item"
                                            value={
                                              avDataRes[data_key] &&
                                                avDataRes[data_key].avEquipType_Term !== undefined &&
                                                avDataRes[data_key].avEquipType_Term !== ""
                                                ? {
                                                  label: avDataRes[data_key].avEquipType_Term,
                                                  value: avDataRes[data_key].avEquipType_Term,
                                                }
                                                : {
                                                  label: "Select Item",
                                                  value: "Select Item",
                                                }
                                            }
                                            className='ClassSelectCreatable'
                                          />
                                        </div>
                                      </td>
                                      <td className="bg-white bordernone">
                                        <input
                                          className="input-tabal ps-0 pe-0"
                                          type="number"
                                          onWheel={(event: any) => {
                                            event.target.blur();
                                          }}
                                          onKeyDown={(e: any) => {
                                            if (e.keyCode == "38" || e.keyCode == "40") {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => {
                                            setAvDataRes({
                                              ...avDataRes,
                                              [data_key]: {
                                                ...avDataRes[data_key],
                                                avEquipCost: e.target.value ? e.target.value : 0,
                                              },
                                            });
                                          }}
                                          value={
                                            avDataRes[data_key].avEquipCost
                                              ? avDataRes[data_key].avEquipCost
                                              : ""
                                          }
                                        />
                                      </td>
                                      <td className="bg-white">
                                        <div className="btn-icon-grp-table justify-content-center">
                                          <Button
                                            onClick={() => {
                                              let newJson: any = {};
                                              Object.keys(avDataRes).forEach((res_key) => {
                                                if (res_key != data_key) {
                                                  newJson = {
                                                    ...newJson,
                                                    [res_key]: avDataRes[res_key],
                                                  };
                                                }
                                              });
                                              if (Object.keys(newJson).length) {
                                                setAvDataRes(newJson);
                                              } else {
                                                setAvDataRes({});
                                                setAvCount(0);
                                                // setAvDataRes({
                                                //   [1]: {
                                                //     avEquipName: "",
                                                //     avEquipType_Term: "",
                                                //     avEquipCost: 0,
                                                //     taxRatePerc: 0,
                                                //     orderNO: 0,
                                                //   },
                                                // });
                                                // setAvCount(1);
                                              }
                                            }}
                                            type="button"
                                            className="btn-icon cancelIcon"
                                          ></Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr>

                                <td colSpan={5} className="bluetext">
                                  <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => {
                                      setAvDataRes({
                                        ...avDataRes,
                                        [avCount + 1]: {
                                          avEquipName: "",
                                          avEquipType_Term: "",
                                          avEquipCost: 0,
                                          taxRatePerc: 0,
                                          orderNO: 0,
                                        },
                                      });
                                      setAvDataResEr({
                                        ...avDataResEr,
                                        [avCount + 1]: {
                                          avEquipName: "",
                                          avEquipType_Term: "",
                                        },
                                      })
                                      setAvCount(avCount + 1);
                                    }}
                                    onKeyDown={() => {
                                      setAvDataRes({
                                        ...avDataRes,
                                        [avCount + 1]: {
                                          avEquipName: "",
                                          avEquipType_Term: "",
                                          avEquipCost: 0,
                                          taxRatePerc: 0,
                                          orderNO: 0,
                                        },
                                      });


                                      setAvDataResEr({
                                        ...avDataResEr,
                                        [avCount + 1]: {
                                          avEquipName: "",
                                          avEquipType_Term: "",
                                        },
                                      })

                                      setAvCount(avCount + 1);
                                    }}
                                  >
                                    <img
                                      src={SquarePlus}
                                      alt=""
                                      width={15}
                                      height={15}
                                      className="me-1"
                                    />
                                    Add More
                                  </div>
                                </td>


                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      {/* Tax Table Start */}
                      <br />
                      <div className="col-md-12">
                        <h2 className="paymenttitle">TAX Details</h2>
                      </div>
                      <div className="tax-information customtablemetting">
                        <Table className="table-without-space mb-0" responsive bordered>
                          <thead>
                            <tr>
                              <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                                Apply?
                              </th>
                              <th style={{ width: "70%" }} className="settitle">
                                Tax Title
                              </th>
                              <th style={{ width: "10%" }} className="settitle">
                                Type ($/%)
                              </th>
                              <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputAVEquipmentTaxList.map((y, i) => (
                              // 
                              // return (
                              <>
                                <tr key={i}>
                                  <td className="" style={{ textAlign: "center" }}>
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="checkbox"
                                      checked={y.IsApply}
                                      onChange={(e) => AVEquipmentTaxCheckboxOnchange(e, y, i)}
                                    ></input>
                                  </td>
                                  <td>{y.TaxTitle}</td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    {y.IsPercentage === false ? "$" : "%"}
                                  </td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    <input
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      // type="text"
                                      style={{ textAlign: "center" }}
                                      onChange={(e) => AVEquipmentRoomTaxOnchange(e, y)}
                                      value={y.RateOrPercentage}
                                    />{" "}
                                  </td>
                                </tr>
                              </>
                              // );
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {/* tax table end */}
                    </div>
                  </Tab>
                  <Tab eventKey="tab9" title="Food"
                    tabClassName={`${first1 === false ? "validate-tab" : ""}`}>
                    <Container fluid="true" className="full-height-tab">

                      <Row className="">
                        <div className="col-md-12">
                          <h2 className="paymenttitle">Food Details</h2>
                        </div>

                      </Row>
                      <Row className="mb-2">
                        <div className="col-md-12 customtablemetting mt-1">
                          <Table responsive bordered className="table-without-space">
                            <thead>
                              <tr className="h-40">
                                <th>Item Name</th>
                                <th>Type</th>
                                <th>Cost Per Unit($)</th>
                                {/* <th>Tax Rate Perc($)</th> */}
                                {/* <th>Order No.</th> */}
                                <th>Description</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(dataResFood).map((data_key) => {
                                return (
                                  <>
                                    <tr>
                                      <td className='bordernone'>
                                        <input
                                          // className="input-tabal ps-0 pe-0"
                                          className={`input-tabal ps-0 pe-0 creatableSpace ${(dataResFoodEr[data_key] && dataResFoodEr[data_key].itemName !== "")
                                            ? "red-border-masking ClassSelectCreatable"
                                            : ""
                                            }`}
                                          type="text"
                                          onChange={(e) => {
                                            setDataResFood({
                                              ...dataResFood,
                                              [data_key]: {
                                                ...dataResFood[data_key],
                                                itemName: e.target.value ? e.target.value : "",
                                              },
                                            });
                                          }}
                                          value={dataResFood[data_key].itemName}
                                        />
                                      </td>
                                      <td className='bordernone'>
                                        <input
                                          className="input-tabal ps-0 pe-0"
                                          type="text"
                                          value={dataResFood[data_key].itemType_Term}
                                        // onChange={(e) => {
                                        //   setDataResFood({
                                        //     ...dataRes,
                                        //     [data_key]: {
                                        //       ...dataRes[data_key],
                                        //       itemName: e.target.value ? e.target.value : "",
                                        //     },
                                        //   });
                                        // }}
                                        />
                                      </td>
                                      {/* <td
                                  style={{ padding: 0, width: "16.6%" }}
                                  className="bg-white  fild-input data-sheet-search-cust"
                                >
                                  <Col className="notallowfield fild-input">
                                    <div>
                                      <FloatingLabel controlId="floatingInput" label="">
                                       
                                        <CreatableSelect
                                          isClearable
                                          options={fabTypesList}
                                          onChange={(event: any) => {
                                            setDataResFood({
                                              ...dataRes,
                                              [data_key]: {
                                                ...dataRes[data_key],
                                                itemType_Term: event !== null ? event.label : "",
                                              },
                                            });
                                          }}
                                          placeholder="Select Item"
                                          value={
                                            dataRes[data_key] &&
                                            dataRes[data_key].itemType_Term !== ""
                                              ? {
                                                  label: dataRes[data_key].itemType_Term,
                                                  value: dataRes[data_key].itemType_Term,
                                                }
                                              : {
                                                  label: "Select Item",
                                                  value: "Select Item",
                                                }
                                          }
                                        />
                                      </FloatingLabel>
                                    </div>
                                  </Col>
                             
                                </td> */}
                                      <td className="bg-white bordernone">
                                        <input
                                          className="input-tabal ps-0 pe-0 mb-0"
                                          type="number"
                                          onWheel={(event: any) => {
                                            event.target.blur();
                                          }}
                                          onKeyDown={(e: any) => {
                                            if (e.keyCode == "38" || e.keyCode == "40") {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => {
                                            setDataResFood({
                                              ...dataResFood,
                                              [data_key]: {
                                                ...dataResFood[data_key],
                                                itemCostPerUnit: e.target.value ? e.target.value : 0,
                                              },
                                            });
                                          }}
                                          value={
                                            dataResFood[data_key].itemCostPerUnit
                                              ? dataResFood[data_key].itemCostPerUnit
                                              : ""
                                          }
                                        />
                                      </td>

                                      {/* <td>
                                  <input
                                    className="input-tabal ps-0 pe-0"
                                    type="number"
                                    onWheel={(event: any) => {
                                      event.target.blur();
                                    }}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setDataResFood({
                                        ...dataResFood,
                                        [data_key]: {
                                          ...dataResFood[data_key],
                                          orderNo: e.target.value ? e.target.value : 0,
                                        },
                                      });
                                    }}
                                    value={
                                      dataResFood[data_key].orderNo ? dataResFood[data_key].orderNo : ""
                                    }
                                  />
                                </td> */}
                                      <td className='bordernone'>
                                        <input
                                          className="input-tabal ps-0 pe-0"
                                          type="text"
                                          onChange={(e) => {
                                            setDataResFood({
                                              ...dataResFood,
                                              [data_key]: {
                                                ...dataResFood[data_key],
                                                description: e.target.value ? e.target.value : "",
                                              },
                                            });
                                          }}
                                          value={dataResFood[data_key].description}
                                        />
                                      </td>
                                      <td className="bg-white">
                                        <div className="btn-icon-grp-table justify-content-center">
                                          <Button
                                            onClick={() => {

                                              let newJson: any = {};
                                              Object.keys(dataResFood).forEach((res_key) => {
                                                if (res_key != data_key) {
                                                  newJson = {
                                                    ...newJson,
                                                    [res_key]: dataResFood[res_key],
                                                  };
                                                }
                                              });
                                              if (Object.keys(newJson).length) {
                                                setDataResFood(newJson);
                                              } else {
                                                setDataResFood({});
                                                setCountFood(0);
                                                // setDataResFood({
                                                //   [1]: {
                                                //     itemName: "",
                                                //     itemType_Term: "Food",
                                                //     itemCostPerUnit: 0,
                                                //     taxRatePerc: 0,
                                                //     orderNo: 0,
                                                //     description: "",
                                                //   },
                                                // });
                                                // setCountFood(1);
                                              }
                                            }}
                                            type="button"
                                            className="btn-icon cancelIcon"
                                          ></Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr>
                                <td colSpan={5} className="bluetext">
                                  <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => {
                                      setDataResFood({
                                        ...dataResFood,
                                        [countFood + 1]: {
                                          itemName: "",
                                          itemType_Term: "Food",
                                          itemCostPerUnit: 0,
                                          taxRatePerc: 0,
                                          orderNo: 0,
                                          description: "",
                                        },
                                      });
                                      setDataResFoodEr({
                                        ...dataResFoodEr,
                                        [countFood + 1]: {
                                          itemName: "",
                                        },
                                      })
                                      setCountFood(countFood + 1);
                                    }}
                                    onKeyDown={() => {
                                      setDataResFood({
                                        ...dataResFood,
                                        [countFood + 1]: {
                                          itemName: "",
                                          itemType_Term: "Food",
                                          itemCostPerUnit: 0,
                                          taxRatePerc: 0,
                                          orderNo: 0,
                                          description: "",
                                        },
                                      });
                                      setDataResFoodEr({
                                        ...dataResFoodEr,
                                        [countFood + 1]: {
                                          itemName: "",
                                        },
                                      })
                                      setCountFood(countFood + 1);
                                    }}
                                  >
                                    <img
                                      src={SquarePlus}
                                      alt=""
                                      width={15}
                                      height={15}
                                      className="me-1"
                                    />
                                    Add More
                                  </div>
                                </td>
                                {/* <td className="bluetext bg-white">
                            <div
                              onClick={() => {
                                setDataResFood({
                                  ...dataResFood,
                                  [countFood + 1]: {
                                    itemName: "",
                                    itemType_Term: "Food",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setCountFood(countFood + 1);
                              }}
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {
                                setDataResFood({
                                  ...dataResFood,
                                  [countFood + 1]: {
                                    itemName: "",
                                    itemType_Term: "Food",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setCountFood(countFood + 1);
                              }}
                            >
                              Add More
                            </div>
                          </td>
                          <td colSpan={6} className="settitle bg-white">
                            <Form.Control disabled type="text" maxLength={15} />
                          </td> */}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      {/* Tax Table Start */}
                      <br />
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Tax Details</h2>
                      </div>

                      <div className="tax-information customtablemetting">
                        <Table className="table-without-space mb-0" responsive bordered>
                          <thead>
                            <tr>
                              <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                                Apply?
                              </th>
                              <th style={{ width: "70%" }} className="settitle">
                                Tax Title
                              </th>
                              <th style={{ width: "10%" }} className="settitle">
                                Type ($/%)
                              </th>
                              <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputFoodTaxList.map((y, i) => (
                              // 
                              // return (
                              <>
                                <tr key={i}>
                                  <td className="" style={{ textAlign: "center" }}>
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="checkbox"
                                      checked={y.IsApply}
                                      onChange={(e) => FoodTaxCheckboxOnchange(e, y, i)}
                                    ></input>
                                  </td>
                                  <td>{y.TaxTitle}</td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    {y.IsPercentage === false ? "$" : "%"}
                                  </td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    <input
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      // type="text"
                                      style={{ textAlign: "center" }}
                                      onChange={(e) => FoodRoomTaxOnchange(e, y)}
                                      value={y.RateOrPercentage}
                                    />{" "}
                                  </td>
                                </tr>
                              </>
                              // );
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {/* tax table end */}
                    </Container>
                  </Tab>
                  <Tab eventKey="tab10" title="Beverage"
                    tabClassName={`${first2 === false ? "validate-tab" : ""}`}>
                    <Container fluid="true" className="full-height-tab">
                      <Row className="">
                        <div className="col-md-12">
                          <h2 className="paymenttitle">Beverage Details</h2>
                        </div>

                      </Row>
                      <Row className="mb-2">
                        <div className="col-md-12 customtablemetting mt-1">
                          <Table responsive bordered className="table-without-space">
                            <thead>
                              <tr className="h-40">
                                <th>Item Name</th>
                                <th>Type</th>
                                <th>Cost Per Unit($)</th>
                                {/* <th>Tax Rate Perc($)</th> */}
                                {/* <th>Order No.</th> */}
                                <th>Description</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(dataResBeverage).map((data_key) => {
                                return (
                                  <>
                                    <tr>
                                      <td className='bordernone'>
                                        <input
                                          // className="input-tabal ps-0 pe-0"
                                          className={`input-tabal ps-0 pe-0 creatableSpace ${(dataResBeverageEr[data_key] && dataResBeverageEr[data_key].itemName !== "")
                                            ? "red-border-masking ClassSelectCreatable"
                                            : ""
                                            }`}
                                          type="text"
                                          onChange={(e) => {
                                            setDataResBeverage({
                                              ...dataResBeverage,
                                              [data_key]: {
                                                ...dataResBeverage[data_key],
                                                itemName: e.target.value ? e.target.value : "",
                                              },
                                            });
                                          }}
                                          value={dataResBeverage[data_key].itemName}
                                        />
                                      </td>
                                      <td className='bordernone'>
                                        <input
                                          className="input-tabal ps-0 pe-0"
                                          type="text"
                                          value={dataResBeverage[data_key].itemType_Term}
                                        // onChange={(e) => {
                                        //   setDataResBeverage({
                                        //     ...dataRes,
                                        //     [data_key]: {
                                        //       ...dataRes[data_key],
                                        //       itemName: e.target.value ? e.target.value : "",
                                        //     },
                                        //   });
                                        // }}
                                        />
                                      </td>
                                      {/* <td
                                  style={{ padding: 0, width: "16.6%" }}
                                  className="bg-white  fild-input data-sheet-search-cust"
                                >
                                  <Col className="notallowfield fild-input">
                                    <div>
                                      <FloatingLabel controlId="floatingInput" label="">
                                       
                                        <CreatableSelect
                                          isClearable
                                          options={fabTypesList}
                                          onChange={(event: any) => {
                                            setDataResBeverage({
                                              ...dataRes,
                                              [data_key]: {
                                                ...dataRes[data_key],
                                                itemType_Term: event !== null ? event.label : "",
                                              },
                                            });
                                          }}
                                          placeholder="Select Item"
                                          value={
                                            dataRes[data_key] &&
                                            dataRes[data_key].itemType_Term !== ""
                                              ? {
                                                  label: dataRes[data_key].itemType_Term,
                                                  value: dataRes[data_key].itemType_Term,
                                                }
                                              : {
                                                  label: "Select Item",
                                                  value: "Select Item",
                                                }
                                          }
                                        />
                                      </FloatingLabel>
                                    </div>
                                  </Col>
                             
                                </td> */}
                                      <td className="bordernone bg-white" >
                                        <input
                                          className="input-tabal ps-0 pe-0 mb-0"
                                          type="number"
                                          onWheel={(event: any) => {
                                            event.target.blur();
                                          }}
                                          onKeyDown={(e: any) => {
                                            if (e.keyCode == "38" || e.keyCode == "40") {
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => {
                                            setDataResBeverage({
                                              ...dataResBeverage,
                                              [data_key]: {
                                                ...dataResBeverage[data_key],
                                                itemCostPerUnit: e.target.value ? e.target.value : 0,
                                              },
                                            });
                                          }}
                                          value={
                                            dataResBeverage[data_key].itemCostPerUnit
                                              ? dataResBeverage[data_key].itemCostPerUnit
                                              : ""
                                          }
                                        />
                                      </td>

                                      {/* <td>
                                  <input
                                    className="input-tabal ps-0 pe-0"
                                    type="number"
                                    onWheel={(event: any) => {
                                      event.target.blur();
                                    }}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode == "38" || e.keyCode == "40") {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setDataResBeverage({
                                        ...dataResBeverage,
                                        [data_key]: {
                                          ...dataResBeverage[data_key],
                                          orderNo: e.target.value ? e.target.value : 0,
                                        },
                                      });
                                    }}
                                    value={
                                      dataResBeverage[data_key].orderNo ? dataResBeverage[data_key].orderNo : ""
                                    }
                                  />
                                </td> */}
                                      <td className='bordernone'>
                                        <input
                                          className="input-tabal ps-0 pe-0"
                                          type="text"
                                          onChange={(e) => {
                                            setDataResBeverage({
                                              ...dataResBeverage,
                                              [data_key]: {
                                                ...dataResBeverage[data_key],
                                                description: e.target.value ? e.target.value : "",
                                              },
                                            });
                                          }}
                                          value={dataResBeverage[data_key].description}
                                        />
                                      </td>
                                      <td className="bg-white">
                                        <div className="btn-icon-grp-table justify-content-center">
                                          <Button
                                            onClick={() => {
                                              let newJson: any = {};
                                              Object.keys(dataResBeverage).forEach((res_key) => {
                                                if (res_key != data_key) {
                                                  newJson = {
                                                    ...newJson,
                                                    [res_key]: dataResBeverage[res_key],
                                                  };
                                                }
                                              });
                                              if (Object.keys(newJson).length) {
                                                setDataResBeverage(newJson);
                                              } else {
                                                setDataResBeverage({});
                                                setCountBeverage(0);
                                                // setDataResBeverage({
                                                //   [1]: {
                                                //     itemName: "",
                                                //     itemType_Term: "Beverage",
                                                //     itemCostPerUnit: 0,
                                                //     taxRatePerc: 0,
                                                //     orderNo: 0,
                                                //     description: "",
                                                //   },
                                                // });
                                                // setCountBeverage(1);
                                              }
                                            }}
                                            type="button"
                                            className="btn-icon cancelIcon"
                                          ></Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr>
                                {/* <td colSpan={6} className="settitle bg-white"
                           >
                            <div
                              onClick={() => {
                                setDataResBeverage({
                                  ...dataResBeverage,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                    itemType_Term: "Beverage",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setCountBeverage(countBeverage + 1);
                              }}
                              onKeyDown={() => {
                                setDataResBeverage({
                                  ...dataResBeverage,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                    itemType_Term: "Beverage",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },  
                                });
                                setCountBeverage(countBeverage + 1);
                              }}
                              role="button"
                              tabIndex={0}
                           
                            >
                              Add More
                            </div>
                          </td> */}
                                <td colSpan={5} className="bluetext">
                                  <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => {
                                      setDataResBeverage({
                                        ...dataResBeverage,
                                        [countBeverage + 1]: {
                                          itemName: "",
                                          itemType_Term: "Beverage",
                                          itemCostPerUnit: 0,
                                          taxRatePerc: 0,
                                          orderNo: 0,
                                          description: "",
                                        },
                                      });
                                      setDataResBeverageEr({
                                        ...dataResBeverageEr,
                                        [countBeverage + 1]: {
                                          itemName: "",
                                        },
                                      })
                                      setCountBeverage(countBeverage + 1);
                                    }}
                                    onKeyDown={() => {
                                      setDataResBeverage({
                                        ...dataResBeverage,
                                        [countBeverage + 1]: {
                                          itemName: "",
                                          itemType_Term: "Beverage",
                                          itemCostPerUnit: 0,
                                          taxRatePerc: 0,
                                          orderNo: 0,
                                          description: "",
                                        },
                                      });
                                      setDataResBeverageEr({
                                        ...dataResBeverageEr,
                                        [countBeverage + 1]: {
                                          itemName: "",
                                        },
                                      })
                                      setCountBeverage(countBeverage + 1);
                                    }}
                                  >
                                    <img
                                      src={SquarePlus}
                                      alt=""
                                      width={15}
                                      height={15}
                                      className="me-1"
                                    />
                                    Add More
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>

                      {/* Tax Table Start */}
                      <br />
                      <div className="col-md-12">
                        <h2 className="paymenttitle">Tax Details</h2>
                      </div>
                      <div className="tax-information customtablemetting">
                        <Table className="table-without-space mb-0" responsive bordered>
                          <thead>
                            <tr>
                              <th style={{ width: "10%", textAlign: "center" }} className="settitle">
                                Apply?
                              </th>
                              <th style={{ width: "70%" }} className="settitle">
                                Tax Title
                              </th>
                              <th style={{ width: "10%" }} className="settitle">
                                Type ($/%)
                              </th>
                              <th style={{ textAlign: "center", width: "10%" }} className="settitle">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputBeverageTaxList.map((y, i) => (
                              // 
                              // return (
                              <>
                                <tr key={i}>
                                  <td className="" style={{ textAlign: "center" }}>
                                    <input
                                      style={{ textAlign: "center" }}
                                      type="checkbox"
                                      checked={y.IsApply}
                                      onChange={(e) => BeverageRoomTaxCheckboxOnchange(e, y, i)}
                                    ></input>
                                  </td>
                                  <td>{y.TaxTitle}</td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    {y.IsPercentage === false ? "$" : "%"}
                                  </td>
                                  <td className="bordernone" style={{ textAlign: "center" }}>
                                    <input
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      // type="text"
                                      style={{ textAlign: "center" }}
                                      onChange={(e) => BeverageRoomTaxOnchange(e, y)}
                                      value={y.RateOrPercentage}
                                    />{" "}
                                  </td>
                                </tr>
                              </>
                              // );
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {/* tax table end */}
                    </Container>
                  </Tab>

                </Tabs >

              </div >

            </div >
          </Form >
        </Modal.Body >
        <Modal.Footer className="myfotercode">
          <div> {
            AddPropertyActiveKey > 1 &&
            <Button className="savebtnclient" onClick={() => TabNextPrevious("Previous")}>
              <i className="fa-solid fa-arrow-left-long me-2"></i>Previous
            </Button>
          }</div>
          <div className='d-flex'>
            {
              AddPropertyActiveKey === 10 ?
                <Button className="savebtnclient  me-2" disabled={OnProcessHide === true ? true : false} onClick={() => SaveData()}>
                  <i className="fa-solid fa-floppy-disk me-2"></i>Save
                </Button>
                :
                <>

                  <Button className="savebtnclient  me-2" onClick={() => TabNextPrevious("Next")}>
                    <i className="fa-solid fa-arrow-right-long me-2"></i>Next
                  </Button>
                  <Button className="savebtnclient  me-2 align-right" disabled={OnProcessHide === true ? true : false} onClick={() => SaveData()}>
                    <i className="fa-solid fa-floppy-disk me-2"></i>Save
                  </Button>
                </>
            }
          </div>




        </Modal.Footer>
      </Modal >

      {
        modalShowProperties === true &&
        <AddPaymentInfoModal show={modalShowProperties}
          onHide={() => setModalShowProperties(false)}
          getPaymentInfoListDropdown={props && props.getPaymentInfoListDropdown} />
      }
      {
        modalShowTaxInformation && (
          <TaxInformationModal
            show={modalShowTaxInformation}
            TaxRowNo={TaxRowNo}
            RoomNamePass={RoomNamePass}
            StoreEditRoomMeetingTaxdata={StoreEditRoomMeetingTaxdata}
            storeRoomMeetingTax={storeRoomMeetingTax}
            onHide={() => setModalShowTaxInformation(false)}
          />
        )
      }

      {
        modalShowRoomTypeTaxInformation && (
          <RoomTypeTaxDetailModal
            TaxRoomTypeRowNo={TaxRoomTypeRowNo}
            RoomNamePass={RoomNamePass}
            storeRoomTypeTax={storeRoomTypeTax}
            StoreEditRoomTypeTaxdata={StoreEditRoomTypeTaxdata}
            show={modalShowRoomTypeTaxInformation}
            onHide={() => {
              setmodalShowRoomTypeTaxInformation(false);
            }}
          />
        )
      }
    </>
  )
};
export default AddProperties;